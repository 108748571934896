import React, { useEffect, useState } from "react";
import { ReactComponent as ClosePrimary } from "../../../assets/icons/close-primary.svg";
import { FormInput } from "../../ui/form-input/FormInput";
import { UncutModal } from "../../ui/modal/modal";

export const EditPhoneModal = ({ id, setUser, user, updatePhone }) => {
  const [phoneNumbers, setPhoneNumbers] = useState({
    primary: "",
    secondary: "",
  });

  const [error, setError] = useState({
    primary: false,
    secondary: false,
    message: "",
  });

  const handlePhoneChange = (e) => {
    let value = e.target.value;

    switch (value) {
      case value === "":
        setError({
          primary: true,
          message: "Phone number is required",
        });
        break;
      case value.length < 10:
        setError({
          primary: true,
          message: "Phone number is invalid",
        });
        break;
      default:
        setError({
          primary: false,
          message: "",
        });
        break;
    }

    setPhoneNumbers((prev) => ({
      ...prev,
      [e.target.name]: value,
    }));
  };

  useEffect(() => {
    setPhoneNumbers((prev) => ({
      ...prev,
      primary: user?.phone_number || "",
    }));
  }, [user?.phone_number]);

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Phone Number</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <p className="mb-0 mt-2 mb-4" style={{ width: "25em" }}>
          Contact and account verification numbers
        </p>
        <div className="my-4">
          <FormInput
            name="primary"
            placeholder="Primary phone number"
            value={phoneNumbers.primary}
            required
            type="tel"
            onChange={(e) => handlePhoneChange(e)}
          />
          {error.primary && <p className="text-danger">{error.message}</p>}
        </div>
        <div className="my-4">
          <FormInput
            name="secondary"
            placeholder="Secondary phone number"
            value={phoneNumbers.secondary}
            required
            type="tel"
            onChange={(e) => handlePhoneChange(e)}
          />
          {error.secondary && <p className="text-danger">{error.message}</p>}
        </div>
      </div>
      <div className="modal-footer d-flex align-items-center px-4">
        <button
          type="button"
          className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary fw-600 flex-grow-1 fs-20"
          onClick={async () => {
            setUser({
              ...user,
              phone_number: phoneNumbers.primary,
            });
            await updatePhone();
          }}
        >
          Save
        </button>
      </div>
    </UncutModal>
  );
};
