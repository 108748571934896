import React from "react";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";
import { ReactComponent as ClosePrimary } from "../../../../assets/icons/close-primary.svg";
import { FormInput } from "../../../ui/form-input/FormInput";
import { UncutModal } from "../../../ui/modal/modal";

export const FeesAndCharges = ({ id, listingsId, listing, setListing }) => {
  // const [fee, setFee] = useState("");

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Edit Listing</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <div className="d-flex gap-4 align-items-center">
          <Chevron
            style={{ transform: "rotate(90deg)", cursor: "pointer" }}
            onClick={() => {
              document.getElementById(`close-${id}`).click();
            }}
            data-bs-toggle="modal"
            data-bs-target={`#${listingsId}`}
          />
          <h5 className="mb-0 fw-600">Fees and Charges</h5>
        </div>
        <p className="mb-0 mt-2">
          Fees and charges are added to the total price for guests. You may want
          to adjust the nightly price to reflect your changes.
        </p>
        <div className="my-4">
          <FormInput
            name="fee"
            placeholder="Enter cleaning fee"
            value={listing.fee_and_charges}
            required
            type="number"
            onChange={(e) => {
              setListing({
                ...listing,
                fee_and_charges: e.target.value,
              });
            }}
          />
          <small className="text-muted">
            This fee will apply to every reservation.
          </small>
        </div>
      </div>
      <div className="modal-footer d-flex align-items-center px-4">
        <button
          type="button"
          className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Back
        </button>
        <button
          type="button"
          className="btn btn-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Save
        </button>
      </div>
    </UncutModal>
  );
};
