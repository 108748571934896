import { Country, State } from "country-state-city";
import React, { useMemo, useState } from "react";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron.svg";
import { ReactComponent as ClosePrimary } from "../../../assets/icons/close-primary.svg";
import { FormInput } from "../../ui/form-input/FormInput";
import { UncutModal } from "../../ui/modal/modal";

export const TaxInfoModal = ({ id }) => {
  const [taxinfo, setTaxinfo] = useState({
    country: "",
    taxesType: "w9",
  });

  const [error, setError] = useState({
    country: "",
    businessName: "",
    entityName: "",
    taxClassification: "",
    taxId: "",
    street: "",
    apt: "",
    city: "",
    state: "",
    zip: "",
    isoCode: "",
  });

  const [w9Form, setW9Form] = useState({
    businessName: "",
    entityName: "",
    taxClassification: "",
    taxId: "",
    street: "",
    apt: "",
    city: "",
    state: "",
    zip: "",
    isoCode: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const [dropdown, setDropdown] = useState(false);

  const [dropdown2, setDropdown2] = useState(false);

  const options = useMemo(() => Country.getAllCountries(), []);

  const stateOptions = useMemo(() => {
    if (w9Form.isoCode) {
      return State.getStatesOfCountry(w9Form.isoCode);
    } else {
      return [];
    }
  }, [w9Form.isoCode]);

  const taxClassificationOptions = [
    {
      id: 1,
      label: "Individual/sole proprietor or single-member LLC",
      value: "individual",
    },
    {
      id: 2,
      label: "C Corporation",
      value: "c_corporation",
    },
    {
      id: 3,
      label: "S Corporation",
      value: "s_corporation",
    },
    {
      id: 4,
      label: "Partnership",
      value: "partnership",
    },
    {
      id: 5,
      label: "Trust/estate",
      value: "trust_estate",
    },
    {
      id: 6,
      label: "Limited liability company",
      value: "limited_liability_company",
    },
  ];

  const taxes = [
    {
      id: 1,
      label: "Form W-9",
      description: "For US taxpayers filing a tax return.",
      value: "w9",
    },
    {
      id: 2,
      label: "Form W-8ECI",
      description: "For foreign taxpayers filing a tax return.",
      value: "w8eci",
    },
    {
      id: 3,
      label: "Form W-8BEN",
      description: "For foreign citizens who don’t file a US tax return.",
      value: "w8ben",
    },
  ];

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    // Country
    if (!taxinfo.country) {
      formIsValid = false;
      errors["country"] = "Country cannot be empty";
    }

    setError(errors);
    return formIsValid;
  };

  const handleW9FormValidation = () => {
    let errors = {};
    let formIsValid = true;

    // Business Name
    if (!w9Form.businessName) {
      formIsValid = false;
      errors["businessName"] = "Business name cannot be empty";
    }

    // Tax Classification
    if (!w9Form.taxClassification) {
      formIsValid = false;
      errors["taxClassification"] = "Tax classification cannot be empty";
    }

    // Street
    if (!w9Form.street) {
      formIsValid = false;
      errors["street"] = "Street cannot be empty";
    }

    // City
    if (!w9Form.city) {
      formIsValid = false;
      errors["city"] = "City cannot be empty";
    }

    // State
    if (!w9Form.state) {
      formIsValid = false;
      errors["state"] = "State cannot be empty";
    }

    // Zip
    if (!w9Form.zip) {
      formIsValid = false;
      errors["zip"] = "Zip cannot be empty";
    }

    setError(errors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      {submitted === false ? (
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="modal-header">
            <h5 className="modal-title fs-22 fw-600">Tax Info</h5>
            <button
              type="button"
              className="close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              id={`close-${id}`}
            >
              <ClosePrimary />
            </button>
          </div>
          <div className="modal-body px-4">
            <h5 className="fw-600 fs-16 mb-4">
              To start, select a country or region
            </h5>
            <div className="custom-form-select my-3">
              <div
                className="form-select-btn"
                onClick={() => {
                  setDropdown(true);
                  window.addEventListener("mousedown", (e) => {
                    if (!e.target.classList.contains("form-select-item")) {
                      setDropdown(false);
                    }
                  });
                }}
              >
                <span className="fw-500 me-2" style={{ whiteSpace: "nowrap" }}>
                  {taxinfo.country ? taxinfo.country : "Country"}
                </span>
                <Chevron />
              </div>
              <div className={`form-select-content ${dropdown ? "show" : ""}`}>
                {options.map((country, index) => {
                  return (
                    <div
                      className="form-select-item"
                      key={index}
                      onClick={() => {
                        setTaxinfo({
                          ...taxinfo,
                          country: country.name,
                        });
                        handleValidation();
                        setDropdown(false);
                      }}
                    >
                      <label>{country.name}</label>
                    </div>
                  );
                })}
              </div>
              {error.country && (
                <p className="text-danger fs-14 fw-500">{error.country}</p>
              )}
            </div>
            <div className="my-4">
              <h5 className="fw-600 fs-16 mb-2">Tax requirements for the US</h5>
              <p className="text-muted fs-14">
                Tax info is required from every individual or entity with one or
                more of the following:
              </p>
              <ul
                className="text-muted fs-14 ms-3 mt-0"
                style={{ listStyle: "disc" }}
              >
                <li>Active US listings(s)</li>
                <li>A US payout method on file</li>
                <li>Us citizen or resident status</li>
              </ul>
            </div>
            <div className="my-4">
              <h5 className="fw-600 fs-16 mb-2">
                Select one of the following:
              </h5>
              <div className="row g-0">
                {taxes.map((item, index) => {
                  return (
                    <div className="col-12" key={index}>
                      <label
                        className="my-2 rounded-4 d-flex align-items-center p-4 gap-2 border"
                        htmlFor={item.value}
                      >
                        <input
                          type="radio"
                          id={item.value}
                          name="taxes"
                          value={item.value}
                          className="d-none"
                          onChange={(e) =>
                            setTaxinfo({
                              ...taxinfo,
                              taxesType: e.target.value,
                            })
                          }
                          checked={item.value === taxinfo.taxesType}
                        />
                        <label
                          htmlFor={item.value}
                          className="form-checkbox-rounded"
                        ></label>
                        <div className="form-label fw-500 mb-0 pointer w-75">
                          <h5 className="mb-0 fw-500 fs-16">{item.label}</h5>
                          <p className="mb-0 fs-14 text-muted">
                            {item.description}
                          </p>
                        </div>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="modal-footer d-flex align-items-center px-4 ">
            <button
              type="button"
              className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary fw-600 flex-grow-1 fs-20"
            >
              Continue
            </button>
          </div>
        </form>
      ) : submitted === true && taxinfo.taxesType === "w9" ? (
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="modal-header">
            <h5 className="modal-title fs-22 fw-600">Tax Info</h5>
            <button
              type="button"
              className="close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              id={`close-${id}`}
            >
              <ClosePrimary />
            </button>
          </div>
          <div className="modal-body px-4">
            <h5 className="fw-600 fs-16">Fill out tax info</h5>
            <p className="text-muted fs-14 mb-4">
              Make sure all details are entered accurately before you sign and
              submit.
            </p>
            <h5 className="fw-600 fs-16">Substitute Form W-9</h5>
            <div className="my-3 mb-2">
              <FormInput
                name="businessName"
                placeholder="Taxpayer or business name"
                value={w9Form.businessName}
                type="text"
                onChange={(e) => {
                  handleW9FormValidation(e, "businessName");
                  setW9Form({ ...w9Form, businessName: e.target.value });
                }}
              />
              {error.businessName && (
                <p className="text-danger fs-14 fw-500">{error.businessName}</p>
              )}
            </div>
            <small className="text-muted">
              Enter the taxpayer’s full legal name, or business name for
              registered businesses, as it appears on income tax returns. Name
              must correspond with tax ID number on this form.
            </small>
            <div className="my-3 mb-2">
              <FormInput
                name="entityName"
                placeholder="Disregarded entity name(optional)"
                value={w9Form.entityName}
                type="text"
                onChange={(e) => {
                  handleW9FormValidation(e, "entityName");
                  setW9Form({ ...w9Form, entityName: e.target.value });
                }}
              />
              {error.businessName && (
                <p className="text-danger fs-14 fw-500">{error.entityName}</p>
              )}
            </div>
            <small className="text-muted mb-3">
              You only need to fill this out if you have a DBA name, trade name
              or disregarded entity name.
            </small>

            <div className="custom-form-select my-3">
              <div
                className="form-select-btn"
                onClick={() => {
                  setDropdown(true);
                  window.addEventListener("mousedown", (e) => {
                    if (!e.target.classList.contains("form-select-item")) {
                      setDropdown(false);
                    }
                  });
                }}
              >
                <span className="fw-500 me-2" style={{ whiteSpace: "nowrap" }}>
                  {w9Form.taxClassification
                    ? w9Form.taxClassification
                    : "Tax classification"}
                </span>
                <Chevron />
              </div>
              <div className={`form-select-content ${dropdown ? "show" : ""}`}>
                {taxClassificationOptions.map((tax, index) => {
                  return (
                    <div
                      className="form-select-item"
                      key={index}
                      onClick={(e) => {
                        setW9Form({ ...w9Form, taxClassification: tax.label });
                        handleW9FormValidation(e, "taxClassification");
                        setDropdown(false);
                      }}
                    >
                      <label>{tax.label}</label>
                    </div>
                  );
                })}
              </div>
              {error.taxClassification && (
                <p className="text-danger fs-14 fw-500">
                  {error.taxClassification}
                </p>
              )}
            </div>

            <h5 className="fw-600 fs-18 mb-0 mt-4">Address</h5>
            <div className="my-4">
              <FormInput
                name="street"
                placeholder="Street address"
                value={w9Form.street}
                required
                type="text"
                onChange={(e) => {
                  handleW9FormValidation(e, "street");
                  setW9Form({ ...w9Form, street: e.target.value });
                }}
              />
              {error.street && (
                <p className="text-danger fs-14 fw-500">{error.street}</p>
              )}
            </div>
            <div className="my-4">
              <FormInput
                name="apt"
                placeholder="Apt, suite, (optional)"
                value={w9Form.apt}
                type="text"
                onChange={(e) => setW9Form({ ...w9Form, apt: e.target.value })}
              />
            </div>
            <div className="my-4">
              <FormInput
                name="city"
                placeholder="City"
                value={w9Form.city}
                required
                type="text"
                onChange={(e) => {
                  handleW9FormValidation(e, "city");
                  setW9Form({ ...w9Form, city: e.target.value });
                }}
              />
              {error.city && (
                <p className="text-danger fs-14 fw-500">{error.city}</p>
              )}
            </div>
            <div className="custom-form-select my-3">
              <div
                className="form-select-btn"
                onClick={() => {
                  setDropdown2(!dropdown2);
                }}
              >
                <span className="fw-500 me-2" style={{ whiteSpace: "nowrap" }}>
                  {w9Form.state ? w9Form.state : "Province or region"}
                </span>
                <Chevron />
              </div>
              <div className={`form-select-content ${dropdown2 ? "show" : ""}`}>
                {stateOptions.map((state, index) => {
                  return (
                    <div
                      className="form-select-item"
                      key={index}
                      onClick={() => {
                        setW9Form({ ...w9Form, state: state.name });
                        setDropdown2(false);
                      }}
                    >
                      <label>{state.name}</label>
                    </div>
                  );
                })}
              </div>
              {error.state && (
                <p className="text-danger fs-14 fw-500">{error.state}</p>
              )}
            </div>
            <div className="my-4">
              <FormInput
                name="zip"
                placeholder="ZIP code"
                value={w9Form.zip}
                required
                type="text"
                onChange={(e) => {
                  handleW9FormValidation(e, "zip");
                  setW9Form({ ...w9Form, zip: e.target.value });
                }}
              />
              {error.zip && (
                <p className="text-danger fs-14 fw-500">{error.zip}</p>
              )}
            </div>
          </div>
          <div className="modal-footer d-flex align-items-center px-4 ">
            <button
              type="button"
              className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
              onClick={() => setSubmitted(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary fw-600 flex-grow-1 fs-20"
              data-bs-dismiss="modal"
            >
              Submit
            </button>
          </div>
        </form>
      ) : null}
    </UncutModal>
  );
};
