import { createContext, useContext, useEffect, useState } from "react";
import { storageService } from "../lib/storage.service";

export const ReservationFormContext = createContext({
  reservationForm: {
    checkin_datetime: new Date(),
    checkout_datetime: new Date(),
    payment_method_id: "",
    space_id: "",
  },
  setReservationForm: () => {},
});

export const ReservationFormProvider = ({ children }) => {
  const [reservationForm, setReservationForm] = useState({
    checkin_datetime: new Date(),
    checkout_datetime: new Date(),
    payment_method_id: "",
    space_id: "",
  });

  useEffect(() => {
    const reservationForm = storageService.getReservationForm();
    console.log(reservationForm);
    if (!reservationForm) {
      return;
    }
    reservationForm.checkin_datetime = new Date(
      reservationForm.checkin_datetime
    );
    reservationForm.checkout_datetime = new Date(
      reservationForm.checkout_datetime
    );
    console.log(reservationForm);
    setReservationForm(reservationForm);
  }, []);

  return (
    <ReservationFormContext.Provider
      value={{ reservationForm, setReservationForm }}
    >
      {children}
    </ReservationFormContext.Provider>
  );
};

export const useReservationFormContext = () => {
  const context = useContext(ReservationFormContext);
  if (context === null) {
    throw new Error(
      "useReservationFormContext must be used within a ReservationFormProvider"
    );
  }
  return context;
};
