import React, { useEffect, useState } from "react";
import { ReactComponent as ClosePrimary } from "../../../assets/icons/close-primary.svg";
import { FormInput } from "../../ui/form-input/FormInput";
import { UncutModal } from "../../ui/modal/modal";

export const EditNameModal = ({ id, setUser, user, updateName }) => {
  const [accept, setAccept] = useState(false);
  const [name, setName] = useState({
    first_name: "",
    last_name: "",
  });

  useEffect(() => {
    setName({
      first_name: user?.first_name,
      last_name: user?.last_name,
    });
  }, [user]);

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">
          {accept ? "Legal Name" : "Edit Name"}
        </h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        {accept ? (
          <>
            <p className="mb-0 mt-2 mb-4 text-muted">
              This is the name on your travel document, which could be a license
              or a passport.
            </p>
            <div className="my-4">
              <FormInput
                name="first_name"
                placeholder="First name"
                value={name.first_name}
                required
                type="text"
                onChange={(e) =>
                  setName({ ...name, first_name: e.target.value })
                }
              />
            </div>
            <div className="my-4">
              <FormInput
                name="last_name"
                placeholder="Last name"
                value={name.last_name}
                required
                type="text"
                onChange={(e) =>
                  setName({ ...name, last_name: e.target.value })
                }
              />
            </div>
          </>
        ) : (
          <>
            <h5 className="mb-0 fw-600 mt-3">
              What to know about updating your name
            </h5>
            <p className="mb-0 mt-2 mb-4 text-muted">
              If your legal name was used to verify your identity, you’ll need
              to get verified again the next time you book -- or to continue
              hosting.
              <br></br>
              <br></br>
              To get verified again, visit your profile.
            </p>
          </>
        )}
      </div>
      <div
        className={`modal-footer text-center px-4 ${
          accept ? "d-flex align-items-center" : "text-center"
        }`}
      >
        {accept ? (
          <>
            <button
              type="button"
              className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary fw-600 flex-grow-1 fs-20"
              onClick={async () => {
                setUser({ ...user, ...name });
                await updateName(name);
              }}
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </>
        ) : (
          <button
            type="button"
            className="btn btn-primary fw-600 fs-20 my-3"
            style={{ width: "11rem", margin: "auto" }}
            onClick={() => setAccept(true)}
          >
            Got it
          </button>
        )}
      </div>
    </UncutModal>
  );
};
