import React, { useState } from "react";
import { ReactComponent as ClosePrimary } from "../../../assets/icons/close-primary.svg";
import { FormInput } from "../../ui/form-input/FormInput";
import { UncutModal } from "../../ui/modal/modal";

export const EditBioModal = ({ id, user, setUser, updateBioInfo }) => {
  const [bio, setBio] = useState(user?.bio || "");

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Bio</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <p className="mb-0 mt-2 mb-4 text-muted" style={{ width: "25em" }}>
          Add a bio to be displayed on your profile.
        </p>
        <div className="my-4">
          <FormInput
            type="textarea"
            name={`bio-${id}`}
            rows={10}
            placeholder="Enter Bio"
            value={bio}
            required
            onChange={(e) => setBio(e.target.value)}
          />
        </div>
      </div>
      <div className="modal-footer d-flex align-items-center px-4">
        <button
          type="button"
          className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary fw-600 flex-grow-1 fs-20"
          onClick={async () => {
            setUser({ ...user, bio });
            await updateBioInfo(bio);
          }}
          data-bs-dismiss="modal"
        >
          Save
        </button>
      </div>
    </UncutModal>
  );
};
