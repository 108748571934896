import React from "react";

export const SpaceSizeForm = ({
  searchWizard,
  setSearchWizard,
  getSearchResults,
}) => {
  const sizes = [
    {
      id: 1,
      name: "Small (Less than 1500 square feet)",
      value: "Less Than 1500",
    },
    {
      id: 2,
      name: "Medium (1500 - 2500 square feet)",
      value: "1500 - 2500",
    },
    {
      id: 3,
      name: "Large (More than 2500 square feet)",
      value: "More Then 2500",
    },
  ];

  return (
    <>
      <h5 className="fs-24 fw-600">How big should the space be?</h5>
      <p className="mb-0 text-muted">
        Tell us how long do you need the space for.
      </p>
      {sizes.map((dur, index) => {
        return (
          <div className="col-12" key={index}>
            <div
              className={`my-3 rounded-2 d-flex align-items-center p-4 py-3 gap-2 border rounded-3 ${
                dur.value === searchWizard?.space_size ? "border-primary" : ""
              }`}
            >
              <input
                type="radio"
                id={dur.value}
                name="photoType"
                value={dur.value}
                className="d-none"
                onChange={(e) => {
                  setSearchWizard({
                    ...searchWizard,
                    space_size: e.target.value,
                  });
                  getSearchResults();
                }}
                checked={searchWizard?.space_size === dur.value}
              />
              <label
                htmlFor={dur.value}
                className="form-checkbox-rounded"
              ></label>{" "}
              <label
                className="form-label fw-500 mb-0 pointer w-75 fs-16 pointer"
                htmlFor={dur.value}
              >
                {dur.name}
              </label>
            </div>
          </div>
        );
      })}
    </>
  );
};
