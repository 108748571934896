import React from "react";
import { Outlet } from "react-router-dom";
import { Navbar } from "../../../components/ui/navbar/Navbar";
import { ListingProvider } from "../../../context/ListingContext";
import { ReservationFormProvider } from "../../../context/ReservationFormContext";
import { SearchWizardProvider } from "../../../context/SearchWizardContext";
import "./FindSpace.css";

export const FindSpace = () => {
  return (
    <div className="container-fluid row g-0">
      <Navbar type="website" />
      <div className="find-space main-section col">
        <SearchWizardProvider>
          <ReservationFormProvider>
            <ListingProvider>
              <Outlet />
            </ListingProvider>
          </ReservationFormProvider>
        </SearchWizardProvider>
      </div>
    </div>
  );
};
