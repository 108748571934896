import React from "react";

export const OffersAndUpdates = () => {
  const offersAndUpdates = [
    {
      id: 1,
      name: "Hosting insights and rewards",
      description:
        "Learn about best hosting practices, and get access to exclusive hosting perks.",
      types: [
        {
          id: 1,
          name: "Email",
          checked: true,
        },
        {
          id: 2,
          name: "Sms",
          checked: false,
        },
      ],
    },
    {
      id: 2,
      name: "Hosting updates",
      description: "Get updates about programs, features, and regulations.",
      types: [
        {
          id: 1,
          name: "Email",
          checked: true,
        },
        {
          id: 2,
          name: "Sms",
          checked: false,
        },
      ],
    },
    {
      id: 3,
      name: "Travel tips and offers",
      description:
        "Get the latest news about new features, product improvements, and other announcements.",
      types: [
        {
          id: 1,
          name: "Email",
          checked: true,
        },
        {
          id: 2,
          name: "Sms",
          checked: false,
        },
      ],
    },
    {
      id: 4,
      name: "Airbnb updates",
      description:
        "Stay up to date on the latest news from Airbnb, and let us know how we can improve.",
      types: [
        {
          id: 1,
          name: "Email",
          checked: true,
        },
        {
          id: 2,
          name: "Sms",
          checked: false,
        },
      ],
    },
  ];

  return (
    <div className="row g-0">
      {offersAndUpdates.map((item) => (
        <div className="col-12 border-bottom pt-2 p-4" key={item.id}>
          <h5 className="fw-bold text-dark fs-20 mb-0 fw-600 mt-3">
            {item.name}
          </h5>
          <p className="text-muted fs-14 mb-0 fw-500">{item.description}</p>
          {item.types.map((type) => (
            <div className="d-flex align-items-center mt-2" key={type.id}>
              <input
                type="checkbox"
                id={`${item.name}-${type.name}`}
                name={`${item.name}`}
                className="d-none"
                defaultChecked={type.checked}
              />
              <label
                htmlFor={`${item.name}-${type.name}`}
                className="form-checkbox"
              ></label>
              <label
                htmlFor={`${item.name}-${type.name}`}
                className="form-label mb-0 fw-500"
              >
                {type.name}
              </label>
            </div>
          ))}
        </div>
      ))}
      <p className="text-muted fs-14 mb-0 py-4 px-4">
        By opting in to text messages, you agree to receive automated marketing
        messages from Spacegeni at +233 ** *** 0797.
      </p>
    </div>
  );
};
