import React, { useState } from "react";
import { ReactComponent as ClosePrimary } from "../../../assets/icons/close-primary.svg";
import { FormInput } from "../../ui/form-input/FormInput";
import { UncutModal } from "../../ui/modal/modal";

export const EditEmailModal = ({ id, setUser, user, updateEmail }) => {
  const [email, setEmail] = useState(user?.email || "");
  const [error, setError] = useState({
    email: false,
    message: "",
  });

  const handleEmailChange = (e) => {
    let value = e.target.value;

    switch (true) {
      case value === "":
        setError({
          email: true,
          message: "Email is required",
        });
        break;
      case !value.includes("@"):
        setError({
          email: true,
          message: "Email is invalid",
        });
        break;
      default:
        setError({
          email: false,
          message: "",
        });
        break;
    }

    setEmail(value);
  };

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Email address</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <p className="mb-0 mt-2 mb-4 text-muted" style={{ width: "25em" }}>
          Use an address you’ll always have access to.
        </p>
        <div className="my-4">
          <FormInput
            name="email"
            placeholder="Email address"
            value={email}
            required
            type="email"
            onChange={(e) => handleEmailChange(e)}
          />
          {error.email && (
            <p className="text-danger fs-14 fw-500">{error.message}</p>
          )}
        </div>
      </div>
      <div className="modal-footer d-flex align-items-center px-4">
        <button
          type="button"
          className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary fw-600 flex-grow-1 fs-20"
          onClick={async () => {
            setUser({ ...user, email });
            await updateEmail(email);
          }}
          data-bs-dismiss="modal"
        >
          Save
        </button>
      </div>
    </UncutModal>
  );
};
