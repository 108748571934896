// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  height: calc(100vh - 7rem);
}

.headers {
  height: 4rem;
}

.chat-container {
  height: calc(100vh - 15rem);
  overflow-y: auto;
  scroll-behavior: smooth;
}

.conversations-container {
  height: calc(100vh - 10.5rem);
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.content {
  height: -moz-fit-content;
  height: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Main/Inbox/Inbox.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,wBAAmB;EAAnB,mBAAmB;AACrB","sourcesContent":[".main-container {\n  height: calc(100vh - 7rem);\n}\n\n.headers {\n  height: 4rem;\n}\n\n.chat-container {\n  height: calc(100vh - 15rem);\n  overflow-y: auto;\n  scroll-behavior: smooth;\n}\n\n.conversations-container {\n  height: calc(100vh - 10.5rem);\n  overflow-y: auto;\n  overflow-x: hidden;\n  scroll-behavior: smooth;\n}\n\n.content {\n  height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
