import React, { useState } from "react";
import { ReactComponent as ImagesOutlineIcon } from "../../../../assets/icons/images-outline.svg";
import { ReactComponent as PlusPrimaryIcon } from "../../../../assets/icons/plus-primary.svg";
import "./SpacePhotos.css";

export const SpacePhotos = ({ listingForm, setListingForm }) => {
  const [dragging, setDragging] = useState(false);

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setListingForm((prev) => {
          return {
            ...prev,
            images: [...(prev?.images || []), reader.result],
          };
        });
      };
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleOnDrag = (e, photo) => {
    e.dataTransfer.setData("SpaceImage", photo);
  };

  const handleOnImageDrop = (e, photo) => {
    e.preventDefault();
    // Swap location of the images in the array based on the index
    const droppedPhoto = e.dataTransfer.getData("SpaceImage");
    const droppedPhotoIndex = listingForm?.images.indexOf(droppedPhoto);
    const photoIndex = listingForm?.images.indexOf(photo);
    const newPhotos = [...listingForm?.images];
    newPhotos[droppedPhotoIndex] = photo;
    newPhotos[photoIndex] = droppedPhoto;
    setListingForm((prev) => {
      return {
        ...prev,
        images: newPhotos,
      };
    });
  };

  return (
    <div className="row g-0 justify-content-center mt-4">
      <div className="col-12 col-md-5">
        {listingForm?.images?.length > 0 ? (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h5 className="mb-2 text-center fs-30 fw-600">
                  Choose at least 5 photos
                </h5>
                <p className="mb-0 fw-500 fs-14 text-muted">Drag to reorder</p>
              </div>
              <label className="btn btn-primary-outline fw-600" htmlFor="file">
                <PlusPrimaryIcon className="me-2" />
                Add more
              </label>
            </div>
            <div className="d-flex align-items-center flex-wrap gap-4 mt-4">
              <div className="col-12">
                <img
                  src={listingForm?.images[0]}
                  alt="space"
                  onDragOver={handleDragOver}
                  onDragStart={(e) => handleOnDrag(e, listingForm?.images[0])}
                  onDrop={(e) => handleOnImageDrop(e, listingForm?.images[0])}
                  draggable
                  className="first-image draggable"
                />
              </div>
              {listingForm?.images?.length > 1 &&
                listingForm?.images.map((photo, index) => {
                  if (index > 0) {
                    return (
                      <img
                        src={photo}
                        alt="space"
                        draggable
                        className="draggable"
                        onDragOver={handleDragOver}
                        onDragStart={(e) => handleOnDrag(e, photo)}
                        onDrop={(e) => handleOnImageDrop(e, photo)}
                        key={index}
                      />
                    );
                  }
                  return null;
                })}
            </div>
          </>
        ) : (
          <>
            <h5 className="mb-2 text-center fs-36 fw-600">
              Add some photos of your space
            </h5>
            <p className="mb-0 fw-500 fs-16 text-center text-muted">
              You’ll need 5 photos to get started. You can add more or make
              updates later.
            </p>
            <label
              className={`drop-area mt-4 ${dragging ? "dragging" : ""}`}
              onDragOver={(e) => {
                e.preventDefault();
                setDragging(true);
              }}
              onDragEnter={(e) => {
                e.preventDefault();
                setDragging(true);
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                setDragging(false);
              }}
              onDrop={handleDrop}
            >
              <div className="text-center">
                <ImagesOutlineIcon />
                <h5 className="mb-0 fw-600 my-3">Drag your photos here</h5>
                <p className="mb-0">Add at least 5 photos</p>
              </div>
              <label
                htmlFor="file"
                className="text-dark mt-4 fw-600 text-decoration-underline pointer"
              >
                Upload from your device
              </label>
            </label>
          </>
        )}
        <input
          type="file"
          className="d-none"
          id="file"
          multiple
          accept=".jpg,.png,.jpeg"
          onChange={(e) => {
            handleFiles(e.target.files);
          }}
        />
      </div>
    </div>
  );
};
