import React, { useState } from "react";
import "./FilterForm.css";
import { UncutCalendar } from "../../ui/calendar/calendar";

export const FilterForm = ({
  filterReservations,
  filter,
  setFilterFormActive,
}) => {
  const [form, setForm] = useState({
    checkin_datetime: "",
    checkout_datetime: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // const checkin_datetime = new Date(form.checkin_datetime);
    // const checkout_datetime = new Date(form.checkout_datetime);
    // const checkin = `${checkin_datetime.getDate()}-${checkin_datetime.getMonth()}-${checkin_datetime.getFullYear()}:${checkin_datetime.getHours()}`;
    // const checkout = `${checkout_datetime.getDate()}-${checkout_datetime.getMonth()}-${checkout_datetime.getFullYear()}:${checkout_datetime.getHours()}`;
    // form.checkin_datetime = checkin;
    // form.checkout_datetime = checkout;
    filterReservations({ ...form, ...filter });
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="mb-4">
        <label className="form-label fw-600" htmlFor="startDate">
          Start Date
        </label>
        <div
          className="date-dropdown-select cta-date pointer"
          onClick={(e) => {
            let allDropdowns = document.querySelectorAll(
              ".date-dropdown-select .date-select-content"
            );
            allDropdowns.forEach((dropdown) => {
              dropdown.classList.remove("show");
            });

            let dateSelectContent = e.target
              .closest(".date-dropdown-select")
              .querySelector(".date-select-content");
            dateSelectContent.classList.add("show");

            window.addEventListener("mousedown", (e) => {
              if (!e.target.closest(".date-dropdown-select")) {
                dateSelectContent.classList.remove("show");
              }
            });
          }}
        >
          <div className="form-input">
            <span
              className="fw-500 me-2 fs-16"
              style={{
                whiteSpace: "nowrap",
                pointerEvents: "none",
              }}
            >
              {form?.checkin_datetime
                ? new Intl.DateTimeFormat("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  }).format(form?.checkin_datetime)
                : "mm/dd/yyyy"}
            </span>
          </div>
          <div className={`date-select-content`}>
            <UncutCalendar
              setDate={(date) => {
                setForm({
                  ...form,
                  checkin_datetime: date,
                });
              }}
              checkIn={form?.checkin_datetime || new Date()}
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <label className="form-label fw-600" htmlFor="endDate">
          End Date
        </label>
        <div
          className="date-dropdown-select cta-date pointer"
          onClick={(e) => {
            let allDropdowns = document.querySelectorAll(
              ".date-dropdown-select .date-select-content"
            );
            allDropdowns.forEach((dropdown) => {
              dropdown.classList.remove("show");
            });

            let dateSelectContent = e.target
              .closest(".date-dropdown-select")
              .querySelector(".date-select-content");
            dateSelectContent.classList.add("show");

            window.addEventListener("mousedown", (e) => {
              if (!e.target.closest(".date-dropdown-select")) {
                dateSelectContent.classList.remove("show");
              }
            });
          }}
        >
          <div className="form-input">
            <span
              className="fw-500 me-2 fs-16"
              style={{
                whiteSpace: "nowrap",
                pointerEvents: "none",
              }}
            >
              {form?.checkout_datetime
                ? new Intl.DateTimeFormat("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  }).format(form?.checkout_datetime)
                : "mm/dd/yyyy"}
            </span>
          </div>
          <div className={`date-select-content`}>
            <UncutCalendar
              setDate={(date) => {
                setForm({
                  ...form,
                  checkout_datetime: date,
                });
              }}
              checkOut={form?.checkout_datetime || new Date()}
            />
          </div>
        </div>
      </div>
      <div className="mb-4 text-end">
        <button
          className="btn btn-primary fw-600 applyBtn"
          type="submit"
          onClick={() => setFilterFormActive(false)}
        >
          Apply
        </button>
      </div>
    </form>
  );
};
