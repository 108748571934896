// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-purple {
  color: #756cf8 !important;
}

@media screen and (max-width: 48em) {
  .fs-36 {
    font-size: 1.5rem !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/CreateListingsModal/GetStarted/GetStarted.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".color-purple {\n  color: #756cf8 !important;\n}\n\n@media screen and (max-width: 48em) {\n  .fs-36 {\n    font-size: 1.5rem !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
