import React from "react";

export const SpaceDiscounts = ({ listingForm, setListingForm }) => {
  const discounts = [
    {
      id: 1,
      percentage: 20,
      name: "New listing promotion",
      description: "Offer 20% off your first 3 bookings.",
      value: "new_listing_promotion",
    },
    {
      id: 2,
      percentage: 10,
      name: "Weekly discount",
      description: "For stays of 7 days or more.",
      value: "weekly_discount",
    },
    {
      id: 3,
      percentage: 10,
      name: "Monthly discount",
      description: "For stays of 28 days or more.",
      value: "monthly_discount",
    },
  ];

  return (
    <div className="row g-0 justify-content-center mt-4">
      <div className="col-12 col-md-5">
        <h5 className="mb-2 text-center fs-36 fw-600">
          Get bookings with discounts
        </h5>
        <p className="mb-0 fw-500 fs-16 text-center">
          Help your space stand out to get booked faster and earn your first
          reviews.
        </p>

        <div className="d-flex flex-column mt-4">
          {discounts.map((item) => (
            <div className="col-12" key={item.id}>
              <div
                className={`my-3 rounded-2 d-flex align-items-center justify-content-between p-4 py-3 gap-2 border border-2 rounded-3`}
              >
                <div className="d-flex gap-4 align-items-center w-100">
                  <h3 className="mb-0 text-center fs-30 fw-600">
                    {item.percentage}%
                  </h3>
                  <div className="d-flex flex-column w-100">
                    <label
                      className="form-label fw-500 mb-0 pointer w-75 fs-16 pointer"
                      htmlFor={item.value}
                    >
                      {item.name}
                    </label>
                    <label
                      className="mb-0 fs-14 text-muted pointer"
                      htmlFor={item.value}
                    >
                      {item.description}
                    </label>
                  </div>
                </div>
                <input
                  type="checkbox"
                  id={item.value}
                  name="first_reservation"
                  value={item.value}
                  className="d-none"
                  onChange={(e) => {
                    setListingForm((prev) => ({
                      ...prev,
                      [item.value]: e.target.checked,
                    }));
                  }}
                  checked={listingForm?.[item.value] || false}
                />
                <label htmlFor={item.value} className="form-checkbox"></label>{" "}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
