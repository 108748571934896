import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Footer } from "../../components/ui/footer/Footer.js";
import { Navbar } from "../../components/ui/navbar/Navbar";
import { Sidebar } from "../../components/ui/sidebar/Sidebar";
import { SidebarContext } from "../../context/SidebarContext.js";
import "./Main.css";

export const Main = () => {
  const [sidebar, setSidebar] = useState(false);
  const [enableSidebar, setEnableSidebar] = useState(true);
  const footerExceptions = ["inbox"];
  const sidebarExceptions = useMemo(() => ["settings"], []);

  const location = useLocation();

  useEffect(() => {
    if (sidebarExceptions.includes(location.pathname.split("/")[2])) {
      setEnableSidebar(false);
    } else {
      setEnableSidebar(true);
    }
  }, [location, sidebarExceptions]);

  return (
    <div className="container-fluid row g-0 min-vh-100">
      <SidebarContext.Provider value={{ sidebar, setSidebar }}>
        <Navbar type="dashboard" sidebar={sidebar} setSidebar={setSidebar} />
        {enableSidebar && <Sidebar sidebar={sidebar} setSidebar={setSidebar} />}
      </SidebarContext.Provider>
      <div
        className="main col"
        style={{
          marginLeft:
            enableSidebar && sidebar
              ? "8rem"
              : enableSidebar && !sidebar
              ? "15.5rem"
              : "0rem",
        }}
      >
        <Outlet />
        {!footerExceptions.includes(
          window.location.pathname.split("/")[
            window.location.pathname.split("/").length - 1
          ]
        ) && <Footer />}
      </div>
    </div>
  );
};
