// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-area {
  border-radius: 0.4375rem;
  border: 2px solid #d5d6d9;
  height: 31rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  transition: 0.3s;
}

.drop-area.dragging {
  border: 2px dashed #756cf8;
  background: #f5f6fa;
}

img[alt="space"] {
  height: 18.16669rem;
  border-radius: 0.4375rem;
  object-fit: cover;
  width: calc(100% - 24.8rem);
}

img[alt="space"].first-image {
  height: 32.625rem;
  width: 100%;
}

.draggable{
  cursor: grab;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/CreateListingsModal/SpacePhotos/SpacePhotos.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,yBAAyB;EACzB,aAAa;EACb,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,wBAAwB;EACxB,iBAAiB;EACjB,2BAA2B;AAC7B;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,YAAY;AACd","sourcesContent":[".drop-area {\n  border-radius: 0.4375rem;\n  border: 2px solid #d5d6d9;\n  height: 31rem;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 3rem;\n  transition: 0.3s;\n}\n\n.drop-area.dragging {\n  border: 2px dashed #756cf8;\n  background: #f5f6fa;\n}\n\nimg[alt=\"space\"] {\n  height: 18.16669rem;\n  border-radius: 0.4375rem;\n  object-fit: cover;\n  width: calc(100% - 24.8rem);\n}\n\nimg[alt=\"space\"].first-image {\n  height: 32.625rem;\n  width: 100%;\n}\n\n.draggable{\n  cursor: grab;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
