import React from "react";

export const Message = ({ message, image, name, time }) => {
  return (
    <div className="d-flex gap-2 px-3 mb-3 position-relative bottom-0">
      <img
        src={image}
        alt="user"
        className="rounded-circle me-3"
        style={{ width: "3rem", height: "3rem", objectFit: "cover" }}
      />
      <div>
        <h6 className="fw-bold text-dark fs-18 mb-0">{name}</h6>
        <p className="mb-0">{message}</p>
        <small className="text-muted fs-10">Response time: {time}</small>
      </div>
    </div>
  );
};
