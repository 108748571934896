import React from "react";

export const SpaceWhoToWelcome = ({ listingForm, setListingForm }) => {
  const whoToWelcome = [
    {
      id: 1,
      name: "Any Spacegeni guest",
      description:
        "Get reservations faster when you welcome anyone from the Spacegeni community.",
      value: "ANY GUEST",
    },
    {
      id: 2,
      name: "An experience guest",
      description:
        "For your first guest, welcome someone with a good track record on Spacegeni who can offer tips for how to be a great Host.",
      value: "EXPERIENCED GUEST",
    },
  ];

  return (
    <div className="row g-0 justify-content-center mt-4">
      <div className="col-12 col-md-5">
        <h5 className="mb-2 text-center fs-36 fw-600">
          Choose who to welcome for your first reservation.
        </h5>
        <p className="mb-0 fw-500 fs-16 text-center text-muted">
          After your first guest, anyone can book your place.
        </p>

        <div className="d-flex flex-column mt-4">
          {whoToWelcome.map((item) => (
            <div className="col-12" key={item.id}>
              <div
                className={`my-3 rounded-2 d-flex align-items-center p-4 py-3 gap-2 border border-2 rounded-3 ${
                  item.value === listingForm?.first_reservation
                    ? "border-primary"
                    : ""
                }`}
              >
                <input
                  type="radio"
                  id={item.value}
                  name="first_reservation"
                  value={item.value}
                  className="d-none"
                  onChange={(e) => {
                    setListingForm({
                      ...listingForm,
                      first_reservation: e.target.value,
                    });
                  }}
                  checked={item.value === listingForm?.first_reservation}
                />
                <label
                  htmlFor={item.value}
                  className="form-checkbox-rounded"
                ></label>{" "}
                <div className="d-flex flex-column">
                  <label
                    className="form-label fw-600 mb-0 pointer w-75 fs-16 pointer"
                    htmlFor={item.value}
                  >
                    {item.name}
                  </label>
                  <label
                    className="mb-0 fs-14 text-muted pointer"
                    htmlFor={item.value}
                  >
                    {item.description}
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
