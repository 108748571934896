import React from "react";
import "./ExportForm.css";

export const ExportForm = () => {
  return (
    <form>
      <div className="row g-0 gy-5 px-3">
        <div className="col-12">
          <h5 className="fw-600 text-dark mb-4">Export</h5>
          <div className="d-flex gap-3 mb-3">
            <input type="radio" name="export" id="export1" />
            <label className="radio-label" htmlFor="export1"></label>
            <label htmlFor="export1" className="text-dark fw-500">
              Current Tab
            </label>
          </div>
          <div className="d-flex gap-3">
            <input type="radio" name="export" id="export2" />
            <label className="radio-label" htmlFor="export2"></label>
            <label htmlFor="export2" className="text-dark fw-500">
              All Reservations
            </label>
          </div>
        </div>
        <div className="col-12">
          <h5 className="fw-600 text-dark mb-4">Export as</h5>
          <div className="d-flex gap-3 mb-3">
            <input type="radio" name="export" id="export3" />
            <label className="radio-label" htmlFor="export3"></label>
            <label htmlFor="export3" className="text-dark fw-500">
              CSV for Excel, Numbers, or other spreadsheet programs
            </label>
          </div>
          <div className="d-flex gap-3 mb-3">
            <input type="radio" name="export" id="export4" />
            <label className="radio-label" htmlFor="export4"></label>
            <label htmlFor="export4" className="text-dark fw-500">
              Plain CSV file
            </label>
          </div>
        </div>
      </div>
      <div className="col-12 border-top border-3">
        <div className="d-flex align-items-center justify-content-end gap-3 px-3">
          <button
            className="btn btn-outline-primary fw-600 fs-20 py-2 mt-3"
            data-bs-dismiss="modal"
            aria-label="Close"
            type="button"
          >
            Cancel
          </button>
          <button className="btn btn-primary fw-600 fs-20 text-white py-2 mt-3">
            Export
          </button>
        </div>
      </div>
    </form>
  );
};
