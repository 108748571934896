import React from "react";
import "./TestimonialCard.css";

export const TestimonialCard = () => {
  return (
    <div className="testimonial-card">
      <h3 className="apostraphe mt-5">“</h3>
      <p className="testimonial-text mt-3">
        Exceptional customer service, the Airbnb customer support team were so
        helpful and supportive when I was forced to leave one of their host's
        premises in the middle of the night after being burgled. They fully
        refunded my emergency accommodation and have been in contact about their
        investigation which they are taking extremely seriously.
      </p>
      <div className="d-flex align-items-center testimonial-author gap-3">
        <img src="https://via.placeholder.com/150" alt="John Doe" />
        <div className="testimonial-author-details">
          <p className="mb-0 fw-600 fs-6">John Doe</p>
          <p className="mb-0 fw-500">London, UK</p>
        </div>
      </div>
    </div>
  );
};
