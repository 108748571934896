import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { ReactComponent as MapPinIcon } from "../../../../assets/icons/map-pin.svg";
import Mapstyle from "../../../../utils/mapstyle.json";
import "./SpaceLocation.css";

export const SpaceLocation = ({ listingForm, setListingForm }) => {
  const [address, setAddress] = useState("");

  const [coordinates, setCoordinates] = useState({
    lat: 42.361145,
    lng: -71.057083,
  });

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    const result = await geocodeByAddress(address);
    const latLng = await getLatLng(result[0]);
    setAddress(address);
    setListingForm({
      ...listingForm,
      ...convertAddress(address),
      latitude: latLng.lat.toString(),
      longitude: latLng.lng.toString(),
    });
    setCoordinates(latLng);
  };

  const convertAddress = (address) => {
    let street, city, state, zip_code, country;
    if (address) {
      const addressArray = address.split(",");
      country = addressArray[addressArray.length - 1]?.trim() || "";
      state =
        addressArray[addressArray.length - 2]?.split(" ")[1]?.trim() || "";
      street = addressArray[addressArray.length - 3]?.trim() || "";
      city = addressArray[addressArray.length - 4] || "";
      zip_code = "";
    }
    return { street, city, state, zip_code, country };
  };

  const convertCoordinates = async (coordinates) => {
    let street, city, state, zip_code, country;
    if (coordinates) {
      let address = `${coordinates.lat},${coordinates.lng}`;
      const addressArray = await geocodeByAddress(address);
      country =
        addressArray[addressArray.length - 1]?.formatted_address
          ?.split(",")
          [
            addressArray[addressArray.length - 1]?.formatted_address?.split(",")
              .length - 1
          ]?.trim() || "";
      state =
        addressArray[addressArray.length - 2]?.formatted_address
          ?.split(",")
          [
            addressArray[addressArray.length - 2]?.formatted_address?.split(",")
              .length - 2
          ]?.trim() || "";
      street =
        addressArray[addressArray.length - 3]?.formatted_address
          ?.split(",")
          [
            addressArray[addressArray.length - 3]?.formatted_address?.split(",")
              .length - 3
          ]?.trim() || "";
      city =
        addressArray[addressArray.length - 4]?.formatted_address
          ?.split(",")[0]
          ?.trim() || "";
      zip_code =
        addressArray[addressArray.length - 1]?.formatted_address
          ?.split(",")
          [
            addressArray[addressArray.length - 1]?.formatted_address?.split(",")
              .length - 2
          ]?.trim() || "";
    }
    return { street, city, state, zip_code, country };
  };

  useEffect(() => {
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: coordinates.lat, lng: coordinates.lng },
      zoom: 17,
      disableDefaultUI: true,
      zoomControl: true,
      styles: Mapstyle,
    });

    const marker = new window.google.maps.Marker({
      position: { lat: coordinates.lat, lng: coordinates.lng },
      map: map,
      draggable: true,
      icon: {
        url: "https://res.cloudinary.com/dfna92as5/image/upload/v1701435883/dh3avl4xhlwhecsa3wud.svg",
        scaledSize: new window.google.maps.Size(50, 50),
      },
    });

    marker.addListener("dragend", async () => {
      setCoordinates({
        lat: marker.getPosition().lat(),
        lng: marker.getPosition().lng(),
      });
      setListingForm({
        ...listingForm,
        ...(await convertCoordinates({
          lat: marker.getPosition().lat(),
          lng: marker.getPosition().lng(),
        })),
        latitude: marker.getPosition().lat().toString(),
        longitude: marker.getPosition().lng().toString(),
      });
      setAddress(`${marker.getPosition().lat()},${marker.getPosition().lng()}`);
    });

    return () => {
      marker.setMap(null);
    };
  }, [coordinates, listingForm, setListingForm]);

  return (
    <div className="row g-0 justify-content-center mt-4">
      <div className="col-12 col-md-5">
        <h5 className="mb-2 text-center fs-36 fw-600">
          Where’s your space located?
        </h5>
        <p className="mb-0 fw-500 fs-16 text-center text-muted">
          Your address is only shared with guests after they’ve made a
          reservation.
        </p>
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="position-relative">
              <div className="address-input my-4">
                <MapPinIcon />
                <input
                  type="text"
                  {...getInputProps({ placeholder: "Enter your address" })}
                />
              </div>
              {loading && (
                <div className="address-suggestions active">
                  <div className="address-suggestion">
                    <MapPinIcon />
                    <div className="address-suggestion-content">
                      <p className="mb-0 fw-500">Loading...</p>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`address-suggestions ${
                  suggestions.length > 0 ? "active" : ""
                }`}
              >
                {suggestions.map((suggestion) => {
                  return (
                    <div
                      className="address-suggestion"
                      {...getSuggestionItemProps(suggestion)}
                      key={suggestion.placeId}
                    >
                      <MapPinIcon />
                      <div className="address-suggestion-content">
                        <p className="mb-0 fw-500">
                          {suggestion.formattedSuggestion.mainText}
                        </p>
                        <p className="mb-0">
                          {suggestion.formattedSuggestion.secondaryText}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <div id="map" className="map-container"></div>
      </div>
    </div>
  );
};
