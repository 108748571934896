import React, { useState } from "react";
import { ReactComponent as CheckmarkCircle } from "../../../../assets/icons/checkmark-circle-outline.svg";
import { ReactComponent as Star } from "../../../../assets/icons/star.svg";
import { UploadVerificationDocument } from "../UploadVerificationDocument/UploadVerificationDocument";
import "./ReviewListingFinal.css";

export const ReviewListingFinal = ({ listingForm, setListingForm }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="row g-0 justify-content-center mt-4">
        <div className="col-12 col-md-7">
          <h5 className="mb-2 text-center fs-36 fw-600">Review your listing</h5>
          <p className="mb-4 fw-500 fs-16 text-center text-muted">
            Here’s what we’ll show to guests. Make sure everything looks great!
          </p>
          <div className="d-flex flex-nowrap gap-4">
            <div className="card overview-card">
              <div className="card-body">
                <img
                  src={
                    listingForm?.images?.[0] ||
                    "https://via.placeholder.com/150"
                  }
                  alt=""
                  style={{ objectFit: "cover" }}
                  className="rounded-3"
                />
                <h5 className="card-title mt-3 mb-2 fs-20 fw-600">
                  {listingForm?.title}
                </h5>
                <p className="card-text mb-0 fw-500 fs-14 text-muted">
                  {listingForm?.country && `${listingForm?.country},`}
                  {listingForm?.state && `${listingForm?.state},`}
                  {listingForm?.street && `${listingForm?.street},`}
                  {listingForm?.city}
                </p>
                <p className="card-text mb-0 fw-500 fs-14 text-muted">
                  New <Star className="ms-1" />
                </p>
              </div>
            </div>
            <div className="p-3">
              <h5 className="mb-5 fw-600">Key details to take care of</h5>
              <div className="d-flex align-items-center gap-3 mb-4">
                <div>
                  <h6 className="mb-0 fw-600">Create your listing</h6>
                  <p className="mb-0 fw-500 text-primary">
                    <CheckmarkCircle className="me-1" />
                    Completed
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-3 mb-4">
                <div>
                  <h6 className="mb-0 fw-600">Confirm your phone number</h6>
                  <p className="mb-0 fw-500 text-muted">
                    We’ll call or text to confirm your number. Standard
                    messaging <br></br>
                    rates apply.
                  </p>
                  <p className="mb-0 fw-500 text-primary">
                    <CheckmarkCircle className="me-1" />
                    Completed
                  </p>
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-between gap-3 mb-4 pointer"
                onClick={() => {
                  setShow(true);
                  window.addEventListener("mousedown", (e) => {
                    if (e.target.className === "upload-modal show") {
                      setShow(false);
                    }
                  });
                }}
              >
                <div>
                  <h6 className="mb-0 fw-600">Space Verification Document</h6>
                  {listingForm?.verification_documents && (
                    <p className="mb-0 fw-500 text-primary">
                      <CheckmarkCircle className="me-1" />
                      Completed
                    </p>
                  )}
                  {!listingForm?.verification_documents && (
                    <p className="mb-0 fw-500 text-primary">Required</p>
                  )}
                </div>
                {!listingForm?.verification_documents && (
                  <i className="bi bi-chevron-right fs-20 text-muted"></i>
                )}
              </div>
              <div className="d-flex align-items-center justify-content-between gap-3 mb-4 pointer">
                <div>
                  <h6 className="mb-0 fw-600">Register on MassTaxConnect</h6>
                  <p className="mb-0 fw-500 text-primary">Required</p>
                </div>
                <i className="bi bi-chevron-right fs-20 text-muted"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UploadVerificationDocument
        show={show}
        listingForm={listingForm}
        setListingForm={setListingForm}
        setShow={setShow}
      />
    </>
  );
};
