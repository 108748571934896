import React, { useState } from "react";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";
import { ReactComponent as ClosePrimary } from "../../../../assets/icons/close-primary.svg";
import { UncutModal } from "../../../ui/modal/modal";

export const ListingSyncSettings = ({ id, listingsId }) => {
  const [apiSync, setApiSync] = useState("sync_pricing");

  const settings = [
    {
      name: "Only sync pricing and availabilty",
      description:
        "Only sync pricing and availability. All other listing content is managed on Spacegeni.",
      value: "sync_pricing",
    },
    {
      name: "Sync Everything",
      description:
        "Sync all listing details, booking settings, pricing, availability and messages with API-connected software.",
      value: "sync_everything",
    },
    {
      name: "Disconnect",
      description:
        "Your listing will remain active on Spacegeni, but won’t update from your connected app anymore. This change cannot be reserved on Spacegeni. To sync your listing going forward, you must reactivate the API connection from your app.",
      value: "disconnect",
    },
  ];

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Edit Listing</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <div className="d-flex gap-4 align-items-center">
          <Chevron
            style={{ transform: "rotate(90deg)", cursor: "pointer" }}
            onClick={() => {
              document.getElementById(`close-${id}`).click();
            }}
            data-bs-toggle="modal"
            data-bs-target={`#${listingsId}`}
          />
          <h5 className="mb-0 fw-600">Listing Sync Settings</h5>
        </div>
        <p className="mb-0 mt-2 mb-4">Choose your API sync settings</p>
        <div className="form">
          {settings.map((method, index) => (
            <div className="my-4 border rounded-3 d-flex p-3 gap-2" key={index}>
              <input
                type="checkbox"
                id={method.value}
                name={method.value}
                className="d-none"
                onChange={() => setApiSync(method.value)}
                checked={apiSync === method.value}
              />
              <label
                htmlFor={method.value}
                className="form-checkbox-rounded"
              ></label>
              <label
                htmlFor={method.value}
                className="form-label fw-500 mb-0 pointer"
              >
                <h5 className="mb-0 fw-500 fs-18">{method.name}</h5>
                <small className="text-muted">{method.description}</small>
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="modal-footer d-flex align-items-center px-4">
        <button
          type="button"
          className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Back
        </button>
        <button
          type="button"
          className="btn btn-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Save
        </button>
      </div>
    </UncutModal>
  );
};
