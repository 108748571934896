import { del, get, post, put } from "../lib/http.service";

const API_URL = process.env.REACT_APP_API_URL;

export const updateLegalName = async (legalName) => {
  try {
    const resp = await put(`${API_URL}/user/update-legal-name/`, legalName);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const updateBio = async (bio) => {
  try {
    const resp = await put(`${API_URL}/user/update-bio/`, bio);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const updateEmailAddress = async (email) => {
  try {
    const resp = await put(`${API_URL}/user/update-email/`, email);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const updatePhoneNumber = async (phone_number) => {
  try {
    const resp = await put(`${API_URL}/user/phone-number/`, phone_number);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const updateAddress = async (address) => {
  try {
    const resp = await put(`${API_URL}/user/update-address/`, address);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const submitGovId = async (govId) => {
  try {
    const resp = await post(`${API_URL}/user/submit-gov-id/`, govId);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const changePassword = async (passwords) => {
  try {
    const resp = await post(`${API_URL}/user/change-password/`, passwords);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const deactivateAccount = async () => {
  try {
    const resp = await post(`${API_URL}/user/deactivate/`);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const getNotificationPermissions = async () => {
  try {
    const resp = await get(`${API_URL}/user/notification-permissions/`);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const updateNotificationPermissions = async (permissions) => {
  try {
    const resp = await put(
      `${API_URL}/user/notification-permissions/`,
      permissions
    );
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const addTaxInfo = async (taxInfo) => {
  try {
    const resp = await post(`${API_URL}/user/tax-payer-info/`, taxInfo);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const getTaxInfo = async () => {
  try {
    const resp = await get(`${API_URL}/user/tax-payer-info/`);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const addPaymentMethod = async (paymentMethod) => {
  try {
    const resp = await post(`${API_URL}/user/payment-method/`, paymentMethod);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const getPaymentMethods = async () => {
  try {
    const resp = await get(`${API_URL}/user/payment-method/`);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const deletePaymentMethod = async (id) => {
  try {
    const resp = await del(`${API_URL}/user/payment-method/${id}`);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};
