// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img[alt="open-email-icon"] {
  width: 6rem;
  height: 6rem;
}

a.text-decoration-none {
  font-size: 0.9375rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Auth/VerifyEmail/VerifyEmail.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":["img[alt=\"open-email-icon\"] {\n  width: 6rem;\n  height: 6rem;\n}\n\na.text-decoration-none {\n  font-size: 0.9375rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
