import { post } from "../lib/http.service";

const API_URL = process.env.REACT_APP_API_URL;

const uploadImage = async (file, file_type) => {
  try {
    const resp = await post(`${API_URL}/utils/upload-media/`, {
      file,
      file_type,
    });
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

const closeModal = (id) => {
  const modal = document.getElementById(id);
  modal.querySelector('[data-bs-dismiss="modal"]').click();
}

export const utilService = {
  uploadImage,
  closeModal,
};
