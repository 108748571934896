import React, { useEffect, useState } from "react";
import EmailOpen from "../../../assets/images/email-open.svg";
import "./VerifyEmail.css";
import { useLocation } from "react-router-dom";

export const VerifyEmail = () => {
  const location = useLocation();

  const [email, setEmail] = useState("");

  useEffect(() => {
    if (location?.search?.split("=")[1]) {
      setEmail(location?.search?.split("=")[1]);
    }
  }, [location?.search]);

  return (
    <div className="row g-0 align-items-start justify-content-center mt-5 pt-5">
      <div className="col-4 p-4 mt-5 card rounded-4 text-center">
        <img src={EmailOpen} alt="open-email-icon" className="mb-3 mx-auto" />
        <h3 className="mb-3 fw-600 fs-24">Verify your Email Address</h3>
        <p className="mb-3 fw-500">
          We just emailed you a verification link to <b>{email}</b>
          <br />
          <br />
          Make sure to check your spam and junk folders if you don’t see our
          email.
        </p>
        <a href="#!" className="text-decoration-none text-primary fw-600">
          Resend Email
        </a>
      </div>
    </div>
  );
};
