import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const success = (
  message,
  position = "top-right",
  autoClose = 3000,
  theme = "colored",
  hideProgressBar = false,
  closeOnClick = true,
  pauseOnHover = true,
  draggable = false,
  progress = undefined
) => {
  return toast.success(message, {
    position,
    autoClose,
    theme,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable,
    progress,
  });
};

const error = (
  message,
  position = "top-right",
  autoClose = 3000,
  theme = "colored",
  hideProgressBar = false,
  closeOnClick = true,
  pauseOnHover = true,
  draggable = false,
  progress = undefined
) => {
  return toast.error(message, {
    position,
    autoClose,
    theme,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable,
    progress,
  });
};

const info = (
  message,
  position = "top-right",
  autoClose = 3000,
  theme = "colored",
  hideProgressBar = false,
  closeOnClick = true,
  pauseOnHover = true,
  draggable = false,
  progress = undefined
) => {
  return toast.info(message, {
    position,
    autoClose,
    theme,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable,
    progress,
  });
};

const warn = (
  message,
  position = "top-right",
  autoClose = 3000,
  theme = "colored",
  hideProgressBar = false,
  closeOnClick = true,
  pauseOnHover = true,
  draggable = false,
  progress = undefined
) => {
  return toast.warn(message, {
    position,
    autoClose,
    theme,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable,
    progress,
  });
};

export const toastService = {
  success,
  error,
  info,
  warn,
};
