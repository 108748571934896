import React from "react";
import { useNavigate } from "react-router-dom";
import GoogleImg from "../../../assets/icons/google.svg";
import Spaces from "../../../assets/images/spaces.svg";
import { LoginForm } from "../../../components/forms/LoginForm/LoginForm";
import { Footer } from "../../../components/ui/footer/Footer";
import { useUserContext } from "../../../context/UserContext";
import "./Login.css";

export const Login = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  return (
    <>
      <div className="col-12 col-md-6 mt-0 mt-md-5 pt-5 d-flex gap-5 flex-column justify-content-between align-items-center">
        <div className="col-12 col-md-8 px-4 px-md-0">
          <h2 className="fw-600 text-center mt-5 text-dark">
            Welcome Back, {user?.first_name}
          </h2>
          <p className="text-muted mb-0 text-center mb-4">
            Please enter your details to sign in
          </p>
          <LoginForm />
          <div className="d-flex justify-content-between align-items-center my-5">
            <hr className="flex-grow-1" />
            <span className="mx-4 text-dark fw-600">OR</span>
            <hr className="flex-grow-1" />
          </div>
          <div className="d-grid">
            <button className="btn btn-secondary rounded fw-500 fs-18 text-dark py-2">
              <img
                className="me-3"
                src={GoogleImg}
                alt="Google"
                width={20}
                height={20}
              />
              Sign in with Google
            </button>
          </div>
          <br />
          <br />

          <p className="text-center mt-4 mb-0 fw-600">
            Don't have an account?{" "}
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                navigate("/auth/register");
              }}
              className="text-primary text-decoration-none"
            >
              Sign up
            </a>
          </p>
        </div>

        <Footer />
      </div>
      <div className="col-12 col-md-6 mt-5 bg-primary-linear position-relative row g-0 justify-content-center d-none d-md-flex">
        <div className="col-9 mt-5">
          <h2 className="text-white mt-5 fw-600 fs-36 mx-auto mt-5">
            Find production spaces for business needs like never before!
          </h2>
          <p className="mb-0 text-white fs-16 fw-500">
            We are making it simple to develop and manage grant programs. Create
            an account, fund it, accept grant applications, review them, and
            disburse funds to approved applicants.
          </p>
        </div>
        <img
          src={Spaces}
          alt="spaces"
          width="100%"
          height={350}
          className="position-absolute bottom-0 end-0"
        />
      </div>
    </>
  );
};
