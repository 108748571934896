import React from "react";
import { TaxInfoModal } from "../../../../../components/modals/TaxInfoModal/TaxInfoModal";
import { VatIdModal } from "../../../../../components/modals/VatIdModal/VatIdModal";

export const Taxpayers = () => {
  return (
    <div className=" p-4">
      <div className="col-12 border-bottom pb-4">
        <h5 className="fw-bold text-dark fs-18 mb-0 fw-600 mt-3">
          Taxpayer information
        </h5>
        <p className="text-muted fs-14 mb-0 fw-500">
          Tax info is required for most countries/regions.
        </p>
        <button
          className="btn btn-primary mt-4 fw-600 py-2"
          data-bs-toggle="modal"
          data-bs-target="#tax-info-modal"
        >
          Add tax info
        </button>
      </div>

      <div className="col-12">
        <h5 className="fw-bold text-dark fs-18 mb-0 fw-600 mt-3">
          Value Added Tax (VAT)
        </h5>
        <p className="text-muted fs-14 mb-0 fw-500">
          If you are VAT- registered, please add your VAT ID.
        </p>
        <button className="btn btn-primary mt-4 fw-600 py-2" data-bs-toggle="modal" data-bs-target="#vat-id-modal">
          Add VAT ID Number
        </button>
      </div>
      <TaxInfoModal id="tax-info-modal" />
      <VatIdModal id="vat-id-modal" />
    </div>
  );
};
