import React from "react";
import "./FormInput.css";

export const FormInput = ({
  name,
  placeholder,
  required,
  rows,
  type,
  onChange,
  value,
  icon,
}) => {
  return !icon ? (
    type !== "textarea" ? (
      <input
        name={name}
        placeholder={placeholder}
        id={name + Math.random() * 100}
        type={type}
        value={value}
        className="form-input w-100"
        required={required}
        onChange={onChange}
      />
    ) : (
      <textarea
        name={name}
        placeholder={placeholder}
        id={name}
        rows={rows}
        value={value}
        className="form-input w-100"
        required={required}
        onChange={onChange}
      ></textarea>
    )
  ) : (
    <div className="position-relative">
      <input
        name={name}
        placeholder={placeholder}
        id={name}
        type={type}
        value={value}
        className="form-input w-100 pe-5"
        required={required}
        onChange={onChange}
      />
      <span className="form-group-icon">{icon}</span>
    </div>
  );
};
