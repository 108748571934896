// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonial-card {
  padding: 2.3125rem 2.8125rem 2.25rem 2.875rem;
  border-radius: 1.25rem;
  background: #f7f7f7;
  width: 100%;
  min-width: 23rem;
  flex: 0 0 auto;
}

.testimonial-card .apostraphe {
  color: #4c35de;
  font-family: Metropolis;
  font-size: 4.6875rem;
  font-style: normal;
  font-weight: 800;
  line-height: 0.25rem; /* 112% */
}

.testimonial-card .testimonial-text {
  color: var(--Com-Gray-Color, #333);
  font-family: Metropolis;
  font-size: calc(1.125rem / 1.3);
  font-style: normal;
  font-weight: 500;
  line-height: 1.5625rem; /* 138.889% */
}

.testimonial-card .testimonial-author img {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/components/application_components/TestimonialCard/TestimonialCard.css"],"names":[],"mappings":"AAAA;EACE,6CAA6C;EAC7C,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,uBAAuB;EACvB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB,EAAE,SAAS;AACjC;;AAEA;EACE,kCAAkC;EAClC,uBAAuB;EACvB,+BAA+B;EAC/B,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB,EAAE,aAAa;AACvC;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".testimonial-card {\n  padding: 2.3125rem 2.8125rem 2.25rem 2.875rem;\n  border-radius: 1.25rem;\n  background: #f7f7f7;\n  width: 100%;\n  min-width: 23rem;\n  flex: 0 0 auto;\n}\n\n.testimonial-card .apostraphe {\n  color: #4c35de;\n  font-family: Metropolis;\n  font-size: 4.6875rem;\n  font-style: normal;\n  font-weight: 800;\n  line-height: 0.25rem; /* 112% */\n}\n\n.testimonial-card .testimonial-text {\n  color: var(--Com-Gray-Color, #333);\n  font-family: Metropolis;\n  font-size: calc(1.125rem / 1.3);\n  font-style: normal;\n  font-weight: 500;\n  line-height: 1.5625rem; /* 138.889% */\n}\n\n.testimonial-card .testimonial-author img {\n  width: 3.125rem;\n  height: 3.125rem;\n  border-radius: 50%;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
