import React, { useState } from "react";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";
import { ReactComponent as ClosePrimary } from "../../../../assets/icons/close-primary.svg";
import { UncutModal } from "../../../ui/modal/modal";
import "./PropertyType.css";

export const PropertyType = ({ id, listingsId }) => {
  const [dropdown1, setDropdown1] = useState(false);

  const [dropdown2, setDropdown2] = useState(false);

  const [propertyType, setPropertyType] = useState("");

  const [propertyType2, setPropertyType2] = useState("");

  const propertyTypes = ["Apartment", "House", "Kitchen"];

  const propertyTypes2 = ["Barn", "Hut", "Cave"];

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Edit Listing</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <div className="d-flex gap-4 align-items-center">
          <Chevron
            style={{ transform: "rotate(90deg)", cursor: "pointer" }}
            onClick={() => {
              document.getElementById(`close-${id}`).click();
            }}
            data-bs-toggle="modal"
            data-bs-target={`#${listingsId}`}
          />
          <h5 className="mb-0 fw-600">Property Type</h5>
        </div>
        <p className="mb-0 mt-2">
          Accurately represent these properties so guests know what to expect.
        </p>
        <div className="my-4">
          <div className="custom-form-select">
            <div
              className="form-select-btn"
              onClick={() => {
                setDropdown1(true);
                window.addEventListener("mousedown", (e) => {
                  if (!e.target.closest(".form-select-btn")) {
                    setDropdown1(false);
                  }
                });
              }}
            >
              <span className="fw-500">
                {propertyType ? propertyType : "Select one"}
              </span>
              <Chevron />
            </div>
            <div className={`form-select-content ${dropdown1 ? "show" : ""}`}>
              {propertyTypes.map((propertyType, index) => {
                return (
                  <div className="form-select-item" key={index}>
                    <input
                      type="radio"
                      name="property-type"
                      id={propertyType}
                      className="d-none"
                      value={propertyType}
                      onChange={(e) => {
                        setPropertyType(e.target.value);
                      }}
                    />
                    <label htmlFor={propertyType}>{propertyType}</label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="my-4">
          <div className="custom-form-select">
            <div
              className="form-select-btn"
              onClick={() => {
                setDropdown2(true);
                window.addEventListener("mousedown", (e) => {
                  if (!e.target.closest(".form-select-btn")) {
                    setDropdown2(false);
                  }
                });
              }}
            >
              <span className="fw-500">
                {propertyType2 ? propertyType2 : "Property type"}
              </span>
              <Chevron />
            </div>
            <div className={`form-select-content ${dropdown2 ? "show" : ""}`}>
              {propertyTypes2.map((propertyType2, index) => {
                return (
                  <div className="form-select-item" key={index}>
                    <input
                      type="radio"
                      name="property-type"
                      id={propertyType2}
                      className="d-none"
                      value={propertyType2}
                      onChange={(e) => {
                        setPropertyType2(e.target.value);
                      }}
                    />
                    <label htmlFor={propertyType2}>{propertyType2}</label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer d-flex align-items-center gap-4 px-5">
        <button
          type="button"
          className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Back
        </button>
        <button
          type="button"
          className="btn btn-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Save
        </button>
      </div>
    </UncutModal>
  );
};
