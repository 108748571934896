import { createContext, useContext, useEffect, useState } from "react";
import { storageService } from "../lib/storage.service";

export const ListingContext = createContext(null);

export const ListingProvider = ({ children }) => {
  const [listing, setListing] = useState({
    id: "",
    title: "",
    description: "",
    price: "",
    stay_duration: "",
    type: "",
    status: "",
    country: "",
    city: "",
    street: "",
    zip_code: "",
    show_address: true,
    latitude: "",
    longitude: "",
    featured_image: "",
    images: [],
    owner: {
      id: "",
      email: "",
      phone_number: "",
      first_name: "",
      last_name: "Tede",
      username: "",
      bio: "",
      profile_picture: null,
    },
    amenities: [],
    created: "",
  });

  useEffect(() => {
    const listing = storageService.getListing();
    setListing(listing);
  }, []);

  return (
    <ListingContext.Provider value={{ listing, setListing }}>
      {children}
    </ListingContext.Provider>
  );
};

export const useListingContext = () => {
  const context = useContext(ListingContext);
  if (context === null) {
    throw new Error("useListingContext must be used within a ListingProvider");
  }
  return context;
};
