import React from "react";
import { EditPasswordModal } from "../../../../components/modals/EditPasswordModal/EditPasswordModal";
import "./LoginAndSecurity.css";

export const LoginAndSecurity = () => {
  return (
    <>
      <div className="card shadow border-0 rounded-4 w-100">
        <div className="d-flex justify-content-between align-items-center p-4 px-5 border-bottom">
          <div>
            <h4 className="fw-bold text-dark fs-16 mb-0 fw-600">Password</h4>
            <p className="text-muted fs-14 mb-0 fw-500">
              Last updated 2 months ago
            </p>
          </div>
          <a
            href="#!"
            data-bs-toggle="modal"
            data-bs-target="#editPasswordModal"
            className="text-decoration-none text-primary fw-500"
          >
            Update
          </a>
        </div>
        <button
          className="btn btn-primary-outline fw-600"
          style={{
            width: "fit-content",
            margin: "auto",
            marginTop: "20em",
            marginBottom: "2em",
          }}
        >
          Deactivate Account
        </button>
      </div>
      <EditPasswordModal id="editPasswordModal" />
    </>
  );
};
