import React, { useState } from "react";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";
import { ReactComponent as ClosePrimary } from "../../../../assets/icons/close-primary.svg";
import { FormInput } from "../../../ui/form-input/FormInput";
import { UncutModal } from "../../../ui/modal/modal";

export const Rules = ({ id, listingsId }) => {
  const [rules, setRules] = useState([]);

  const [details, setDetails] = useState([]);

  const [additionNotes, setAdditionNotes] = useState("");

  const settings = [
    {
      name: "Suitable for children (2-12 years)",
      description:
        "You can say your listing isn’t suitable for infants or children if there are features that are dangerous for children or there’s a risk of property damage.",
      value: "children",
    },
    {
      name: "Suitable for infants (under 2 years)",
      description:
        "You can say your listing isn’t suitable for infants or children if there are features that are dangerous for children or there’s a risk of property damage.",
      value: "infants",
    },
    {
      name: "Pets allowed",
      description:
        "You can limit guests from bringing pets, but assistance animals—for example, seeing eye dogs—aren’t considered pets. ",
      value: "pets",
    },
  ];

  const detailsList = [
    {
      description: "Must climb stairs",
      value: "stairs",
    },
    {
      description: "Potential for noise",
      value: "noise",
    },
    {
      description: "No parking on property",
      value: "parking",
    },
    {
      description: "Some spaces are shared",
      value: "shared",
    },
    {
      description: "Amenity limitations",
      value: "amenity",
    },
    {
      description: "Surveillance or recording devices on property",
      value: "surveillance",
    },
  ];

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Edit Listing</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <div className="d-flex gap-4 align-items-center">
          <Chevron
            style={{ transform: "rotate(90deg)", cursor: "pointer" }}
            onClick={() => {
              document.getElementById(`close-${id}`).click();
            }}
            data-bs-toggle="modal"
            data-bs-target={`#${listingsId}`}
          />
          <h5 className="mb-0 fw-600">Rules</h5>
        </div>
        <p className="mb-0 mt-2 mb-4">
          Guests must agree to your House Rules before they book.
        </p>
        <div className="form">
          {settings.map((method, index) => (
            <div className="my-4 border rounded-3 d-flex p-3 gap-2" key={index}>
              <label
                htmlFor={method.value}
                className="form-label fw-500 mb-0 pointer"
              >
                <h5 className="mb-0 fw-500 fs-18">{method.name}</h5>
                <small className="text-muted">{method.description}</small>
              </label>
              <input
                type="checkbox"
                id={method.value}
                name={method.value}
                className="d-none"
                onChange={() => {
                  if (rules.includes(method.value)) {
                    setRules(rules.filter((rule) => rule !== method.value));
                  } else {
                    setRules([...rules, method.value]);
                  }
                }}
                checked={rules.includes(method.value)}
              />
              <label
                htmlFor={method.value}
                className="form-checkbox-rounded"
              ></label>
            </div>
          ))}
          <p className="mb-0 mt-2 mb-4">
            You can include any requirements around safety concerns, shared
            space rules, and your community regulations, as well as rules like:
            “No solo travelers” or “Quiet time after 10pm”.
          </p>
          <FormInput
            name="additional-notes"
            placeholder="Additional note.."
            required={false}
            type="textarea"
            rows={5}
            onChange={setAdditionNotes}
            value={additionNotes}
          />
          <h5 className="mb-0 fw-500 fs-18 mt-3">
            Details guests must know about your home
          </h5>
          <p className="mb-2">
            Set expectations about your home that your guests must agree to
            before booking.
          </p>
          <div className="form">
            {detailsList.map((method, index) => (
              <div
                className="my-4 border rounded-3 d-flex justify-content-between p-3 gap-2"
                key={index}
              >
                <label
                  htmlFor={method.value}
                  className="form-label mb-0 pointer"
                >
                  {method.description}
                </label>
                <input
                  type="checkbox"
                  id={method.value}
                  name={method.value}
                  className="d-none"
                  onChange={() => {
                    if (details.includes(method.value)) {
                      setDetails(
                        details.filter((rule) => rule !== method.value)
                      );
                    } else {
                      setDetails([...details, method.value]);
                    }
                  }}
                  checked={details.includes(method.value)}
                />
                <label
                  htmlFor={method.value}
                  className="form-checkbox-rounded"
                ></label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="modal-footer d-flex align-items-center px-4">
        <button
          type="button"
          className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Back
        </button>
        <button
          type="button"
          className="btn btn-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Save
        </button>
      </div>
    </UncutModal>
  );
};
