import React from "react";
import "./PaymentAndSecurity.css";
import { Payments } from "./Payments/Payments";
import { Payouts } from "./Payouts/Payouts";

export const PaymentAndSecurity = () => {
  return (
    <>
      <div className="card shadow border-0 rounded-4 w-100 payment-container">
        <ul className="nav nav-tabs gap-3 ps-3 mt-4" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="payments-tab"
              data-bs-toggle="tab"
              data-bs-target="#payments"
              type="button"
              role="tab"
              aria-controls="payments"
              aria-selected="true"
            >
              Payments
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="payouts-tab"
              data-bs-toggle="tab"
              data-bs-target="#payouts"
              type="button"
              role="tab"
              aria-controls="payouts"
              aria-selected="false"
            >
              Payouts
            </button>
          </li>
        </ul>

        <div className="tab-content">
          <div
            className="tab-pane active"
            id="payments"
            role="tabpanel"
            aria-labelledby="payments-tab"
          >
            <Payments />
          </div>
          <div
            className="tab-pane"
            id="payouts"
            role="tabpanel"
            aria-labelledby="payouts-tab"
          >
           <Payouts />
          </div>
        </div>
      </div>
    </>
  );
};
