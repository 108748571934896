// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadow {
  box-shadow: 0px 2px 17px 2px rgba(192, 192, 192, 0.17) !important;
}

.btn-primary-outline {
  color: var(--primary-color);
  background-color: transparent;
  border: 2px solid var(--primary-color);
  padding-block: 0.6rem;
}

.btn-primary-outline:hover,
.btn-primary-outline:focus {
  color: #fff;
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.btn-primary-outline:active {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
}

/* Add CSS for the table styling */
.booking-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.booking-row {
  display: table;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0px 2px 17px 2px rgba(192, 192, 192, 0.17) !important;
  background-color: #fff;
  margin-bottom: 20px;
}

.booking-cell {
  display: table-cell;
  padding: 15px;
}

.booking-cell img.avatar {
  max-width: 50px;
  border-radius: 50%;
}

.booking-cell p.mb-0.text-muted {
  font-size: 14px;
  color: #888;
}

.booking-cell b {
  font-weight: 600;
  display: block;
}

.booking-cell p,
.booking-cell b{
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Main/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,iEAAiE;AACnE;;AAEA;EACE,2BAA2B;EAC3B,6BAA6B;EAC7B,sCAAsC;EACtC,qBAAqB;AACvB;;AAEA;;EAEE,WAAW;EACX,sCAAsC;EACtC,sCAAsC;AACxC;;AAEA;EACE,sBAAsB;EACtB,iDAAiD;EACjD,iDAAiD;AACnD;;AAEA,kCAAkC;AAClC;EACE,WAAW;EACX,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,mBAAmB;EACnB,iEAAiE;EACjE,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;;EAEE,mBAAmB;AACrB","sourcesContent":[".shadow {\n  box-shadow: 0px 2px 17px 2px rgba(192, 192, 192, 0.17) !important;\n}\n\n.btn-primary-outline {\n  color: var(--primary-color);\n  background-color: transparent;\n  border: 2px solid var(--primary-color);\n  padding-block: 0.6rem;\n}\n\n.btn-primary-outline:hover,\n.btn-primary-outline:focus {\n  color: #fff;\n  background-color: var(--primary-color);\n  border: 2px solid var(--primary-color);\n}\n\n.btn-primary-outline:active {\n  color: #fff !important;\n  background-color: var(--primary-color) !important;\n  border: 2px solid var(--primary-color) !important;\n}\n\n/* Add CSS for the table styling */\n.booking-table {\n  width: 100%;\n  border-collapse: separate;\n  border-spacing: 0 10px;\n}\n\n.booking-row {\n  display: table;\n  width: 100%;\n  border-radius: 15px;\n  box-shadow: 0px 2px 17px 2px rgba(192, 192, 192, 0.17) !important;\n  background-color: #fff;\n  margin-bottom: 20px;\n}\n\n.booking-cell {\n  display: table-cell;\n  padding: 15px;\n}\n\n.booking-cell img.avatar {\n  max-width: 50px;\n  border-radius: 50%;\n}\n\n.booking-cell p.mb-0.text-muted {\n  font-size: 14px;\n  color: #888;\n}\n\n.booking-cell b {\n  font-weight: 600;\n  display: block;\n}\n\n.booking-cell p,\n.booking-cell b{\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
