import React, { useEffect, useState } from "react";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";
import { ReactComponent as ClosePrimary } from "../../../../assets/icons/close-primary.svg";
import { UncutModal } from "../../../ui/modal/modal";

export const Amenities = ({
  id,
  listingsId,
  amenities,
  listingAmenities,
  updateListingAmenities,
}) => {
  const [newAmenities, setNewAmenities] = useState([]);

  useEffect(() => {
    if (!listingAmenities) return;
    let newArray = listingAmenities.map((item) => item.amenity);
    setNewAmenities(newArray);
  }, [listingAmenities]);

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Edit Listing</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <div className="d-flex gap-4 align-items-center">
          <Chevron
            style={{ transform: "rotate(90deg)", cursor: "pointer" }}
            onClick={() => {
              document.getElementById(`close-${id}`).click();
            }}
            data-bs-toggle="modal"
            data-bs-target={`#${listingsId}`}
          />
          <h5 className="mb-0 fw-600">Amenities</h5>
        </div>
        <p className="mb-0 mt-2 mb-4">
          Let guests know which amenities are available for these listings.
        </p>
        <div className="form">
          {amenities.map((item, index) => (
            <div
              className="my-4 border rounded-2 d-flex justify-content-between p-3 gap-2"
              key={index}
            >
              <label
                htmlFor={item.name}
                className="form-label fw-500 mb-0 pointer w-100"
              >
                {item.name}
              </label>
              <input
                type="checkbox"
                id={item.name}
                name={item.name}
                className="d-none"
                checked={newAmenities.some((i) => i.id === item.id) || false}
                onChange={() => {
                  setNewAmenities((prev) => {
                    if (prev.some((i) => i.id === item.id)) {
                      return prev.filter((i) => i.id !== item.id);
                    } else {
                      return [...prev, item];
                    }
                  });
                }}
              />
              <label htmlFor={item.name} className="form-checkbox"></label>
            </div>
          ))}
        </div>
      </div>
      <div className="modal-footer d-flex align-items-center px-4">
        <button
          type="button"
          className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Back
        </button>
        <button
          type="button"
          className="btn btn-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
          onClick={async () => {
            await updateListingAmenities(newAmenities);
          }}
        >
          Save
        </button>
      </div>
    </UncutModal>
  );
};
