import React from "react";
import { ReactComponent as NoDataIcon } from "../../../assets/icons/no-data.svg";
import "./NoData.css";

export const NoData = ({
  title,
  text = `You don’t have any ${title.toLowerCase()} at the moment.`,
}) => {
  return (
    <div className="text-center">
      <NoDataIcon />
      <h3 className="mt-3 fw-600">No {title}</h3>
      <p className="text-muted">{text}</p>
    </div>
  );
};
