import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Heart } from "../../../assets/icons/heart.svg";
import { ReactComponent as Star } from "../../../assets/icons/star.svg";
import "./ListingCard.css";

export const ListingCard = ({
  images,
  title,
  description,
  id,
  amenities_percentage,
  price,
  stay_duration,
  maxWidth = "100%",
}) => {
  const navigate = useNavigate();

  const navigateToSpaceDetail = (id) => {
    navigate(`/find-space/space/${id}`);
  };

  const [currentImage, setCurrentImage] = useState(0);

  const [isFavourite, setIsFavourite] = useState(false);

  return (
    <div className="listing" style={{ maxWidth }}>
      <div className="image-container mb-3">
        <img
          src={images[currentImage]?.image || "https://via.placeholder.com/150"}
          alt="listing"
        />
        <div className="d-flex align-items-center justify-content-between w-100 tags">
          {amenities_percentage && (
            <p className="mb-0">{amenities_percentage}% Match</p>
          )}
          <button
            className="btn btn-link text-decoration-none text-white p-0 me-2 icon-btn"
            type="button"
            onClick={() => setIsFavourite(!isFavourite)}
          >
            <Heart className={isFavourite ? "filled" : ""} />
          </button>
        </div>
        <div className="d-flex align-items-center justify-content-center w-100 gap-1 navtabs">
          {images?.map((image, index) => (
            <span
              className={`dot ${index === currentImage ? "active" : ""}`}
              key={image.id}
              onClick={() => setCurrentImage(index)}
            ></span>
          ))}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between gap-3">
        <h5
          className="mb-0 fw-600 truncate"
          onClick={() => navigateToSpaceDetail(id)}
        >
          {title || "Kitchen in a beautiful house"}
        </h5>
        <p
          className="mb-1 fw-500 d-flex align-items-center"
          onClick={() => navigateToSpaceDetail(id)}
        >
          <Star className="me-1" /> 4.89(20)
        </p>
      </div>
      <p
        className="mb-1 truncate fs-14 text-muted"
        onClick={() => navigateToSpaceDetail(id)}
      >
        {description || "N/A"}
      </p>
      <p className="mb-1 fw-600 truncate fs-18">
        {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
          .format(price)
          .replace(".00", "")}
        /{stay_duration}
      </p>
    </div>
  );
};
