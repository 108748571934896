import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { ReactComponent as Calendar } from "../../../../assets/icons/calendar-lg.svg";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";
import { ReactComponent as FlagSharp } from "../../../../assets/icons/flag-sharp.svg";
import { ReactComponent as HeartOutlineIcon } from "../../../../assets/icons/heart-outline.svg";
import { ReactComponent as Key } from "../../../../assets/icons/key.svg";
import { ReactComponent as ShareOutlineIcon } from "../../../../assets/icons/share-outline.svg";
import { ReactComponent as ShieldOutline } from "../../../../assets/icons/shield-outline.svg";
import { ReactComponent as StarLG } from "../../../../assets/icons/star-lg.svg";
import { ReactComponent as Star } from "../../../../assets/icons/star.svg";
import { ReactComponent as TrophySm } from "../../../../assets/icons/trophy-sm.svg";
import { ReactComponent as Trophy } from "../../../../assets/icons/trophy.svg";
import { UncutCalendar } from "../../../../components/ui/calendar/calendar";
import { MainFooter } from "../../../../components/ui/main-footer/main-footer";
import { useListingContext } from "../../../../context/ListingContext";
import { useReservationFormContext } from "../../../../context/ReservationFormContext";
import { useSpinnerContext } from "../../../../context/SpinnerContext";
import { storageService } from "../../../../lib/storage.service";
import { getListing } from "../../../../services/listings.service";
import MapStyle from "../../../../utils/mapstyle.json";
import "./SpaceDetail.css";

export const SpaceDetail = () => {
  const navigate = useNavigate();

  const { listing, setListing } = useListingContext();

  const [nonAvailableReservationDates, setNonAvailableReservationDates] =
    useState([]);

  const { setSpinner } = useSpinnerContext();

  const [dropdown, setDropdown] = useState(false);

  const [dateDropdown, setDateDropdown] = useState(false);

  const [check1Dropdown, setCheck1Dropdown] = useState(false);

  const [check2Dropdown, setCheck2Dropdown] = useState(false);

  const [displayFullBio, setDisplayFullBio] = useState(false);

  const { reservationForm, setReservationForm } = useReservationFormContext();

  const [purchaseForm, setPurchaseForm] = useState({
    checkin_datetime: new Date(),
    checkout_datetime: new Date(),
  });

  const subscriptions = [
    {
      id: 1,
      name: "Basic",
    },
  ];

  const times = [
    { id: 1, name: "12 AM", value: "00" },
    { id: 2, name: "1 AM", value: "01" },
    { id: 3, name: "2 AM", value: "02" },
    { id: 4, name: "3 AM", value: "03" },
    { id: 5, name: "4 AM", value: "04" },
    { id: 6, name: "5 AM", value: "05" },
    { id: 7, name: "6 AM", value: "06" },
    { id: 8, name: "7 AM", value: "07" },
    { id: 9, name: "8 AM", value: "08" },
    { id: 10, name: "9 AM", value: "09" },
    { id: 11, name: "10 AM", value: "10" },
    { id: 12, name: "11 AM", value: "11" },
    { id: 13, name: "12 PM", value: "12" },
    { id: 14, name: "1 PM", value: "13" },
    { id: 15, name: "2 PM", value: "14" },
    { id: 16, name: "3 PM", value: "15" },
    { id: 17, name: "4 PM", value: "16" },
    { id: 18, name: "5 PM", value: "17" },
    { id: 19, name: "6 PM", value: "18" },
    { id: 20, name: "7 PM", value: "19" },
    { id: 21, name: "8 PM", value: "20" },
    { id: 22, name: "9 PM", value: "21" },
    { id: 23, name: "10 PM", value: "22" },
    { id: 24, name: "11 PM", value: "23" },
  ];

  const fetchListing = useCallback(async () => {
    try {
      setSpinner(true);
      const listingId = window.location.pathname.split("/")[3];
      const resp = await getListing(listingId);
      if (resp.status === 201 || resp.status === 200) {
        setListing(resp.result);
        setNonAvailableReservationDates(
          restructureDates(resp.non_available_reservation_dates)
        );
        storageService.setListing(resp.result);
      }
      setSpinner(false);
    } catch (ex) {
      setSpinner(false);
      console.log(ex);
    }
  }, [setListing, setSpinner]);

  const initializeMap = useCallback(() => {
    let coordinates = { lat: 42.3601, lng: -71.0589 };
    const map = new window.google.maps.Map(
      document.getElementById("map-container"),
      {
        center: {
          lat: Number(listing?.latitude) || coordinates.lat,
          lng: Number(listing?.longitude) || coordinates.lng,
        },
        zoom: 17,
        disableDefaultUI: true,
        zoomControl: true,
        styles: MapStyle,
      }
    );

    new window.google.maps.Marker({
      position: {
        lat: Number(listing?.latitude) || coordinates.lat,
        lng: Number(listing?.longitude) || coordinates.lng,
      },
      map: map,
      draggable: true,
      icon: {
        url: "https://res.cloudinary.com/dfna92as5/image/upload/v1701435883/dh3avl4xhlwhecsa3wud.svg",
        scaledSize: new window.google.maps.Size(50, 50),
      },
    });
  }, [listing?.latitude, listing?.longitude]);

  const reserveASpace = async () => {
    const data = {
      ...reservationForm,
      checkin_datetime: purchaseForm?.checkin_datetime,
      checkout_datetime: purchaseForm?.checkout_datetime,
      space_id: listing?.id,
    };
    setReservationForm(data);
    storageService.setReservationForm(data);
    navigate("/find-space/confirm-and-pay");
  };

  const restructureDates = (datesArray) => {
    let dates = datesArray.map((date) => {
      let checkin = new Date(date.checkin_date);
      let checkout = new Date(date.checkout_date);

      date.checkin_date = checkin;
      date.checkout_date = checkout;

      return date;
    });

    let months = dates.reduce((acc, date) => {
      let month = date.checkin_date.getMonth();

      if (acc[month]) {
        acc[month].push(date);
      } else {
        acc[month] = [date];
      }

      return acc;
    }, {});

    let monthsArray = Object.keys(months).map((month) => {
      let checkin = months[month].sort(
        (a, b) => a.checkin_date - b.checkin_date
      )[0].checkin_date;
      let checkout = months[month].sort(
        (a, b) => b.checkout_date - a.checkout_date
      )[0].checkout_date;

      return {
        month: Number(month),
        checkin: checkin,
        checkout: checkout,
      };
    });

    return monthsArray;
  };

  useEffect(() => {
    const init = async () => {
      await fetchListing();
      initializeMap();
    };

    init();
  }, [fetchListing, initializeMap]);

  return (
    <main className="bg-white h-100">
      <div className="container py-5">
        <h5 className="mb-2 fw-600 fs-30">
          {listing?.title || "Beautiful Kitchen hosted by Olivia"}
        </h5>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <p className="mb-0 fw-500 d-flex align-items-center">
              <Star className="me-1" /> 4.89
            </p>
            <span className="dot"></span>
            <p className="mb-0 fw-500">499 reviews</p>
            <span className="dot"></span>
            <p className="mb-0 fw-500 border-bottom">
              {listing?.city && listing?.city + ","}{" "}
              {listing?.state && listing?.state + ","} {listing?.country}
            </p>
          </div>
          <div className="d-flex align-items-center gap-3">
            <button className="btn-link text-dark">
              <ShareOutlineIcon className="me-2" />
              Share
            </button>
            <button className="btn-link text-dark">
              <HeartOutlineIcon className="me-2" />
              Save
            </button>
          </div>
        </div>
        <div className="d-flex align-items-center mt-4 featured-container gap-3">
          <img src={listing?.featured_image} alt="featured" />
          <div className="d-flex align-items-center gap-3 flex-wrap h-100">
            {listing?.images
              ?.filter((image) => image.image !== listing?.featured_image)
              .map((image, index) => (
                <img key={index} src={image.image} alt="other" />
              ))}
          </div>
        </div>
        <div className="row g-0 justify-content-between mt-5 border-bottom">
          <div className="col-md-6">
            <div className="border-bottom row g-0 py-3">
              <div className="d-flex align-items-center gap-4 my-4">
                <Trophy />
                <div>
                  <p className="mb-0 fw-600 fs-16">Melvis is a Genihost</p>
                  <p className="mb-0 text-muted">
                    Superhosts are experienced, highly rated hosts who are
                    committed to providing great stays for guests.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 my-4">
                <Key />
                <div>
                  <p className="mb-0 fw-600 fs-16">Great check-in experience</p>
                  <p className="mb-0 text-muted">
                    90% of recent guests gave the check-in process a 5-star
                    rating.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 my-4">
                <Calendar />
                <div>
                  <p className="mb-0 fw-600 fs-16">
                    Free cancellation before Sept 15
                  </p>
                  <p className="mb-0 text-muted">
                    You can still book and cancel before sept 15 for money back.
                  </p>
                </div>
              </div>
            </div>
            <div className="border-bottom py-3">
              <p className="mb-4 fw-500 fs-14 text-muted">
                {listing?.description}
              </p>
              <a href="/" className="text-dark fw-600 fs-16">
                Show more
              </a>
            </div>
            <div className="border-bottom py-3">
              <h5 className="my-4 fw-600 fs-30">What the space offers</h5>
              <div className="d-flex align-items-center gap-4 flex-wrap">
                {listing?.amenities?.map((item, index) => (
                  <span key={index} className="space-item text-dark">
                    <span className="space-dot"></span> {item?.amenity?.name}
                  </span>
                ))}
              </div>
              <button className="btn btn-outline-dark my-4 mt-5 fw-600 fs-18">
                Show more
              </button>
            </div>
            <div className="py-3">
              <h5 className="my-4 fw-600 fs-30">Space Availability</h5>
              <div className="d-flex align-items-center gap-4 mb-4">
                {nonAvailableReservationDates?.map((date, index) => {
                  return (
                    <UncutCalendar
                      key={index}
                      checkIn={date.checkin}
                      checkOut={date.checkout}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card shadow rounded-4 py-3 mb-4">
              <div className="card-body px-0">
                <h5 className="mb-4 fw-600 fs-30 mx-4">
                  {"$" + listing?.price}
                  {"/" + listing?.stay_duration?.toLowerCase()}
                </h5>
                <div
                  className="date-dropdown-select border rounded-3 mb-4 mx-4 pointer"
                  onClick={() => {
                    setDateDropdown(true);
                    window.addEventListener("mousedown", (e) => {
                      if (!e.target.closest(".date-dropdown-select")) {
                        setDateDropdown(false);
                      }
                    });
                  }}
                >
                  <div className="date-select-btn">
                    <span
                      className="fw-500 me-2 fs-16"
                      style={{
                        whiteSpace: "nowrap",
                        pointerEvents: "none",
                      }}
                    >
                      {new Intl.DateTimeFormat("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      }).format(purchaseForm?.checkin_datetime)}{" "}
                    </span>
                  </div>
                  <div
                    className={`date-select-content ${
                      dateDropdown ? "show" : ""
                    }`}
                  >
                    <UncutCalendar
                      calendar={true}
                      setDate={(date) => {
                        setPurchaseForm({
                          ...purchaseForm,
                          checkin_datetime: date,
                        });
                      }}
                      checkIn={purchaseForm?.checkin_datetime}
                    />
                  </div>
                </div>
                <div className="row g-0 border rounded-3 mb-4 mx-4 flex-nowrap">
                  <div
                    className="col-md p-2 px-4 border-end time-dropdown-select"
                    onClick={() => {
                      setCheck1Dropdown(true);
                      window.addEventListener("mousedown", (e) => {
                        if (!e.target.closest(".time-dropdown-select")) {
                          setCheck1Dropdown(false);
                        }
                      });
                    }}
                  >
                    <div className="time-select-btn">
                      <span
                        className="fw-500 me-2 fs-16"
                        style={{
                          whiteSpace: "nowrap",
                          pointerEvents: "none",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p className="mb-0 fw-600 fs-16">Check-in Time</p>
                        <p className="mb-0 text-muted fw-500">
                          {new Intl.DateTimeFormat("en-US", {
                            hour: "numeric",
                            hour12: true,
                          }).format(purchaseForm?.checkin_datetime) || "12 AM"}
                        </p>
                      </span>
                    </div>
                    <div
                      className={`time-select-content ${
                        check1Dropdown ? "show" : ""
                      }`}
                    >
                      {times.map((time, index) => {
                        return (
                          <div
                            className="time-select-item"
                            key={index}
                            onClick={() => {
                              setCheck1Dropdown(!check1Dropdown);
                              setPurchaseForm({
                                ...purchaseForm,
                                checkin_datetime: new Date(
                                  purchaseForm?.checkin_datetime?.setHours(
                                    time.value
                                  )
                                ),
                              });
                            }}
                          >
                            {time.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className="col-md p-2 px-4 time-dropdown-select"
                    onClick={() => {
                      setCheck2Dropdown(true);
                      window.addEventListener("mousedown", (e) => {
                        if (!e.target.closest(".time-dropdown-select")) {
                          setCheck2Dropdown(false);
                        }
                      });
                    }}
                  >
                    <div className="time-select-btn">
                      <span
                        className="fw-500 me-2 fs-16"
                        style={{
                          whiteSpace: "nowrap",
                          pointerEvents: "none",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p className="mb-0 fw-600 fs-16">Checkout Time</p>
                        <p className="mb-0 text-muted fw-500">
                          {new Intl.DateTimeFormat("en-US", {
                            hour: "numeric",
                            hour12: true,
                          }).format(purchaseForm?.checkout_datetime) || "12 AM"}
                        </p>
                      </span>
                    </div>
                    <div
                      className={`time-select-content ${
                        check2Dropdown ? "show" : ""
                      }`}
                    >
                      {times.map((time, index) => {
                        return (
                          <div
                            className="time-select-item"
                            key={index}
                            onClick={() => {
                              setCheck2Dropdown(!check1Dropdown);
                              setPurchaseForm({
                                ...purchaseForm,
                                checkout_datetime: new Date(
                                  purchaseForm?.checkout_datetime?.setHours(
                                    time.value
                                  )
                                ),
                              });
                            }}
                          >
                            {time.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="custom-form-select mb-4 mx-4">
                  <div
                    className="form-select-btn border-1 rounded-3"
                    onClick={() => {
                      setDropdown(true);
                      window.addEventListener("mousedown", (e) => {
                        if (!e.target.classList.contains("form-select-item")) {
                          setDropdown(false);
                        }
                      });
                    }}
                  >
                    <span
                      className="fw-500 me-2"
                      style={{ whiteSpace: "nowrap", pointerEvents: "none" }}
                    >
                      {"Subscribe to use space"}
                    </span>
                    <Chevron />
                  </div>
                  <div
                    className={`form-select-content ${dropdown ? "show" : ""}`}
                  >
                    {subscriptions.map((sub, index) => {
                      return (
                        <div
                          className="form-select-item"
                          key={index}
                          onClick={() => {
                            setDropdown(false);
                          }}
                        >
                          <label>{sub.name}</label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="px-4">
                  <button
                    className="btn btn-primary w-100 fw-600 py-3 fs-16 rounded-3 mb-4"
                    onClick={() => reserveASpace()}
                  >
                    Reserve Space
                  </button>
                </div>
                <p className="text-muted text-center mb-4 fw-500">
                  You won't be charged yet
                </p>
                <div className="border-bottom mb-4 px-4">
                  <div className="d-flex align-items-center gap-4 justify-content-between mb-4">
                    <p className="mb-0 fw-600 fs-16">
                      {"$" + listing?.price} x{" "}
                      {purchaseForm?.checkout_datetime?.getHours() -
                        purchaseForm?.checkin_datetime?.getHours()}{" "}
                      hours
                    </p>
                    <p className="mb-0 fw-600 fs-16">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(
                        listing?.price *
                          (purchaseForm?.checkout_datetime?.getHours() -
                            purchaseForm?.checkin_datetime?.getHours()) || 0
                      )}
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-4 justify-content-between mb-4">
                    <p className="mb-0 fw-600 fs-16">Service fee</p>
                    <p className="mb-0 fw-600 fs-16">$75</p>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-4 justify-content-between px-4">
                  <p className="mb-0 fw-600 fs-16">Total before taxes</p>
                  <p className="mb-0 fw-600 fs-16">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(
                      listing?.price *
                        (purchaseForm?.checkout_datetime?.getHours() -
                          purchaseForm?.checkin_datetime?.getHours()) +
                        75 || 0
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center">
              <button href="#" className="btn-link text-decoration-underline">
                <FlagSharp className="me-2" />
                Report this listing
              </button>
            </div>
          </div>
        </div>
        <div className="row g-0 justify-content-between mt-5 pb-5 gx-5">
          <div className="d-flex align-items-center gap-2">
            <p className="mb-0 fw-500 fs-30 d-flex align-items-center">
              <StarLG className="me-1" /> 4.89
            </p>
            <span className="dot"></span>
            <p className="mb-0 fw-500 fs-30">499 reviews</p>
            <span className="dot"></span>
            <p className="mb-0 fw-500 fs-30">Boston, USA</p>
          </div>
          <div className="col-md">
            <div className="d-flex align-items-center justify-content-between gap-3 mt-4">
              <p className="mb-0 fw-500 fs-16">Cleanliness</p>
              <div className="d-flex align-items-center gap-2">
                <Rating
                  initialValue={4.5}
                  allowFraction={true}
                  fillColor="#4c35de"
                  allowHover={false}
                  size={25}
                />
                <p className="mb-0 fw-500 fs-16">4.9</p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between gap-3 mt-4">
              <p className="mb-0 fw-500 fs-16">Communication</p>
              <div className="d-flex align-items-center gap-2">
                <Rating
                  initialValue={4.5}
                  allowFraction={true}
                  fillColor="#4c35de"
                  allowHover={false}
                  size={25}
                />
                <p className="mb-0 fw-500 fs-16">4.9</p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between gap-3 mt-4">
              <p className="mb-0 fw-500 fs-16">Check-in</p>
              <div className="d-flex align-items-center gap-2">
                <Rating
                  initialValue={4.5}
                  allowFraction={true}
                  fillColor="#4c35de"
                  allowHover={false}
                  size={25}
                />
                <p className="mb-0 fw-500 fs-16">4.9</p>
              </div>
            </div>
          </div>
          <div className="col-md">
            <div className="d-flex align-items-center justify-content-between gap-3 mt-4">
              <p className="mb-0 fw-500 fs-16">Accuracy</p>
              <div className="d-flex align-items-center gap-2">
                <Rating
                  initialValue={4.5}
                  allowFraction={true}
                  fillColor="#4c35de"
                  allowHover={false}
                  size={25}
                />
                <p className="mb-0 fw-500 fs-16">4.9</p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between gap-3 mt-4">
              <p className="mb-0 fw-500 fs-16">Location</p>
              <div className="d-flex align-items-center gap-2">
                <Rating
                  initialValue={4.5}
                  allowFraction={true}
                  fillColor="#4c35de"
                  allowHover={false}
                  size={25}
                />
                <p className="mb-0 fw-500 fs-16">4.9</p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between gap-3 mt-4">
              <p className="mb-0 fw-500 fs-16">Value</p>
              <div className="d-flex align-items-center gap-2">
                <Rating
                  initialValue={4.5}
                  allowFraction={true}
                  fillColor="#4c35de"
                  allowHover={false}
                  size={25}
                />
                <p className="mb-0 fw-500 fs-16">4.9</p>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom py-4">
          <div className="row g-0 justify-content-between">
            <div className="col-md-12">
              <div className="py-4">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-4">
                    <img
                      src="https://picsum.photos/50/50"
                      alt="user"
                      className="rounded-circle"
                    />
                    <div>
                      <p className="mb-0 fw-500 fs-20 ms-3">Katie</p>
                      <p className="mb-0 fw-500 fs-14 ms-3 text-muted">
                        August 2023
                      </p>
                    </div>
                  </div>
                  <p className="mb-2 fw-500 fs-14 text-muted">
                    Excellent location! This was our favorite place we stayed
                    while in Cuba. Very clean, beautiful balcony and view, and
                    very spacious! Highly recommend!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="py-4">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-4">
                    <img
                      src="https://picsum.photos/50/50"
                      alt="user"
                      className="rounded-circle"
                    />
                    <div>
                      <p className="mb-0 fw-500 fs-20 ms-3">Katie</p>
                      <p className="mb-0 fw-500 fs-14 ms-3 text-muted">
                        August 2023
                      </p>
                    </div>
                  </div>
                  <p className="mb-2 fw-500 fs-14 text-muted">
                    Excellent location! This was our favorite place we stayed
                    while in Cuba. Very clean, beautiful balcony and view, and
                    very spacious! Highly recommend!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="py-4">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-4">
                    <img
                      src="https://picsum.photos/50/50"
                      alt="user"
                      className="rounded-circle"
                    />
                    <div>
                      <p className="mb-0 fw-500 fs-20 ms-3">Katie</p>
                      <p className="mb-0 fw-500 fs-14 ms-3 text-muted">
                        August 2023
                      </p>
                    </div>
                  </div>
                  <p className="mb-2 fw-500 fs-14 text-muted">
                    Excellent location! This was our favorite place we stayed
                    while in Cuba. Very clean, beautiful balcony and view, and
                    very spacious! Highly recommend!
                  </p>
                  <a href="/" className="text-dark fw-600 fs-16">
                    Show more
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="py-4">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-4">
                    <img
                      src="https://picsum.photos/50/50"
                      alt="user"
                      className="rounded-circle"
                    />
                    <div>
                      <p className="mb-0 fw-500 fs-20 ms-3">Katie</p>
                      <p className="mb-0 fw-500 fs-14 ms-3 text-muted">
                        August 2023
                      </p>
                    </div>
                  </div>
                  <p className="mb-2 fw-500 fs-14 text-muted">
                    Excellent location! This was our favorite place we stayed
                    while in Cuba. Very clean, beautiful balcony and view, and
                    very spacious! Highly recommend!
                  </p>
                  <a href="/" className="text-dark fw-600 fs-16">
                    Show more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button className="btn btn-outline-dark fw-600 py-2 fs-18 rounded-3 mt-5">
            Show all reviews
          </button>
        </div>
        <div className="border-bottom mt-5 pb-4">
          <h5 className="mb-4 fw-600 fs-30">Where you’ll be</h5>
          <div className="map-holder" id="map-container"></div>
          <h5 className="my-4 fw-600 fs-28">Boston, Massachusetts</h5>
          <p className="mb-3 fw-500 fs-14 text-muted">
            Boston is Massachusetts’ capital and largest city. Founded in 1630,
            it’s one of the oldest cities in the U.S. The key role it played in
            the American Revolution is highlighted on the Freedom Trail, a 2.5-
            mile walking route of historic sites that tells the story of the
            nation’s founding. One stop, former meeting house Faneuil Hall, is a
            popular marketplace.
          </p>
          <a href="/" className="text-dark fw-600 fs-16">
            Show more
          </a>
        </div>
        <div className="border-bottom mt-5 pb-4">
          <div className="d-flex align-items-center justify-content-between gap-2 mb-3">
            <div className="d-flex align-items-center gap-2">
              <img
                src={
                  listing?.owner?.profile_picture ||
                  `http://ui-avatars.com/api/?name=${listing?.owner?.first_name}+${listing?.owner?.last_name}`
                }
                alt="user"
                className="rounded-circle"
                width={70}
                height={70}
              />
              <div>
                <p className="mb-1 fw-600 fs-20">
                  Hosted by {listing?.owner?.first_name}{" "}
                  {listing?.owner?.last_name}
                </p>
                <p className="mb-0 fw-500 fs-14 text-muted">
                  Member since August 2020
                </p>
              </div>
            </div>
            <button
              className="btn btn-outline-primary fw-600 py-2 fs-18 rounded-3"
              onClick={() => window.open(`mailto:${listing?.owner?.email}`)}
            >
              Contact Host
            </button>
          </div>
          <div className="d-flex align-items-center gap-2 mb-3">
            <p className="mb-0 fw-500 d-flex align-items-center fs-16">
              <Star className="me-1" /> 797 Reviews
            </p>
            <span className="dot"></span>
            <p className="mb-0 fw-500 fs-14 text-muted">Verified</p>
            <span className="dot"></span>
            <p className="mb-0 fw-500 fs-14 text-muted">
              <TrophySm className="me-1" />
              Genihost
            </p>
          </div>
          <p className="mb-3 fw-500 fs-14 text-muted">
            {displayFullBio
              ? listing?.owner?.bio
              : listing?.owner?.bio &&
                listing?.owner?.bio?.substring(0, 100) + "..."}
          </p>
          {listing?.owner?.bio?.length > 100 && displayFullBio === false && (
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                setDisplayFullBio(true);
              }}
              className="text-dark fw-600 fs-16"
            >
              Show more
            </a>
          )}
          <div className="d-flex align-items-center gap-2 my-3">
            <p className="mb-0 fw-500 fs-14 text-muted">
              Languages: English, Spanish
            </p>
            <span className="dot"></span>
            <p className="mb-0 fw-500 fs-14 text-muted">Response rate: 100%</p>
            <span className="dot"></span>
            <p className="mb-0 fw-500 fs-14 text-muted">
              Response time: within an hour
            </p>
          </div>
          <p className="mb-3 fw-500 fs-14 text-muted">
            <ShieldOutline className="me-2" />
            To protect your payment, never transfer money or communicate outside
            of the Spacegeni website or app.
          </p>
        </div>
        <div className="mt-5 pb-4">
          <h5 className="mb-5 fw-600 fs-30">Key Info to know</h5>
          <div className="row g-0">
            <div className="col-md-4">
              <h5 className="mb-4 fw-600 fs-20">Rules</h5>
              <p className="mb-4 fw-500 fs-14 text-muted">
                Check-in after 7 AM
              </p>
              <p className="mb-4 fw-500 fs-14 text-muted">
                Checkout before 6 PM
              </p>
              <a href="/" className="text-dark fw-600 fs-16">
                Show more
              </a>
            </div>
            <div className="col-md-4">
              <h5 className="mb-4 fw-600 fs-20">Safety & property</h5>
              <p className="mb-4 fw-500 fs-14 text-muted">
                Carbon monoxide alarm
              </p>
              <p className="mb-4 fw-500 fs-14 text-muted">Smoke alarm</p>
              <a href="/" className="text-dark fw-600 fs-16">
                Show more
              </a>
            </div>
            <div className="col-md-4">
              <h5 className="mb-4 fw-600 fs-20">Cancellation</h5>
              <p className="mb-4 fw-500 fs-14 text-muted">
                Free cancellation before Sept 15.
              </p>
              <p className="mb-4 fw-500 fs-14 text-muted">
                Review the Host’s full cancellation policy which applies even if
                you cancel for illness.
              </p>
              <a href="/" className="text-dark fw-600 fs-16">
                Show more
              </a>
            </div>
          </div>
        </div>
      </div>
      <MainFooter />
    </main>
  );
};
