// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  margin-top: 6.5rem;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 48em) {
  .main {
    margin-left: 0 !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Main/Main.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".main {\n  margin-top: 6.5rem;\n  transition: all 0.3s ease-in-out;\n}\n\n@media screen and (max-width: 48em) {\n  .main {\n    margin-left: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
