// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-input {
  outline: none;
  padding: 15px 20px;
  border-radius: 0.4375rem;
  border: 1px solid #d5d6d9;
}

.form-input::placeholder {
  font-weight: 500;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.form-group-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

textarea.form-input {
  resize: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/form-input/FormInput.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,wBAAwB;EACxB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,YAAY;AACd","sourcesContent":[".form-input {\n  outline: none;\n  padding: 15px 20px;\n  border-radius: 0.4375rem;\n  border: 1px solid #d5d6d9;\n}\n\n.form-input::placeholder {\n  font-weight: 500;\n}\n\ninput[type=\"number\"]::-webkit-inner-spin-button,\ninput[type=\"number\"]::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\ninput[type=\"number\"] {\n  -moz-appearance: textfield;\n}\n\n.form-group-icon {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  right: 20px;\n}\n\ntextarea.form-input {\n  resize: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
