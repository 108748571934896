import React from "react";
import "./SpaceDescription.css";

export const SpaceDescription = ({ listingForm, setListingForm }) => {
  const maxLength = 500;

  const handleDescriptionChange = (e) => {
    if (e.target.value.length > maxLength) {
      return;
    }
    setListingForm({ ...listingForm, description: e.target.value });
  };

  return (
    <div className="row g-0 justify-content-center mt-4">
      <div className="col-12 col-md-5">
        <h5 className="mb-2 text-center fs-36 fw-600">
          Create description of your space
        </h5>
        <p className="mb-0 fw-500 fs-16 text-center text-muted">
          Share what makes your space special.
        </p>
        <textarea
          className="form-control mt-4 fw-500"
          rows="10"
          placeholder="Add description"
          value={listingForm?.description}
          onChange={handleDescriptionChange}
        ></textarea>
        <p className="mb-0 mt-2 fw-600 fs-16">
          {listingForm?.description?.length || 0}/{maxLength}
        </p>
      </div>
    </div>
  );
};
