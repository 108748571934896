import React from "react";
import "./SpaceType.css";

export const SpaceType = ({ categories, listingForm, setListingForm }) => {
  return (
    <div className="row g-0 justify-content-center mt-4">
      <div className="col-12 col-md-5">
        <h5 className="mb-4 text-center fs-36 fw-600">
          Which of these best describes <br></br> your space?
        </h5>
        <div className="d-flex gap-2 justify-content-center flex-wrap">
          {categories?.map((space) => (
            <div
              className={`option d-flex align-items-center gap-4 border border-2 rounded-3 p-3 py-4 hoverable mb-3 ${
                listingForm?.category_id === space.id ? "selected" : ""
              }`}
              onClick={() => {
                setListingForm({ ...listingForm, category_id: space.id });
              }}
              key={space.id}
            >
              <img src={space.image} alt="space-icons" width={50} height={50} />
              <p className="mb-0 fw-600 fs-18">{space.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
