import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { ReactComponent as MapPinIcon } from "../../../assets/icons/map-pin.svg";

export const SpaceLocationForm = ({ handleSelect, address, setAddress }) => {
  const handleChange = (address) => {
    setAddress(address);
  };

  return (
    <>
      <h5 className="fs-30 fw-600">What’s your preferred location?</h5>
      <p className="mb-0 text-muted">
        Tell us the location you are looking for the space.
      </p>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="position-relative">
            <div className="address-input mt-4">
              <MapPinIcon />
              <input
                type="text"
                className="fs-16"
                placeholder="Enter address"
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                {...getInputProps()}
              />
            </div>
            {loading && (
              <div className="address-suggestions active">
                <div className="address-suggestion">
                  <MapPinIcon />
                  <div className="address-suggestion-content">
                    <p className="mb-0 fw-500">Loading...</p>
                  </div>
                </div>
              </div>
            )}
            <div
              className={`address-suggestions ${
                suggestions.length > 0 ? "active" : ""
              }`}
            >
              {suggestions.map((suggestion) => {
                return (
                  <div
                    className="address-suggestion"
                    {...getSuggestionItemProps(suggestion)}
                    key={suggestion.placeId}
                  >
                    <MapPinIcon />
                    <div className="address-suggestion-content">
                      <p className="mb-0 fw-500">
                        {suggestion.formattedSuggestion.mainText}
                      </p>
                      <p className="mb-0">
                        {suggestion.formattedSuggestion.secondaryText}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
  );
};
