import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Bell } from "../../../assets/icons/bell.svg";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron-primary.svg";
import { ReactComponent as Humburger } from "../../../assets/icons/humburger.svg";
import { ReactComponent as Logout } from "../../../assets/icons/logout.svg";
import { ReactComponent as Profile } from "../../../assets/icons/profile.svg";
import { ReactComponent as SettingsSm } from "../../../assets/icons/settings-sm.svg";
import { ReactComponent as Swap } from "../../../assets/icons/swap.svg";
import Logo from "../../../assets/images/dark-logo.svg";
import { useUserContext } from "../../../context/UserContext";
import { storageService } from "../../../lib/storage.service";
import "./Navbar.css";

export const Navbar = ({ type, sidebar, setSidebar }) => {
  const navigate = useNavigate();

  const { user } = useUserContext();

  const token = storageService.getToken();

  const dropdownOptions = [
    {
      label: "Switch to Guest",
      onClick: () => navigate("/auth/login"),
      icon: <Swap />,
    },
    {
      label: "Profile",
      onClick: () => navigate("/main/profile"),
      icon: <Profile />,
    },
    {
      label: "Settings",
      onClick: () => navigate("/main/settings"),
      icon: <SettingsSm />,
    },
    {
      label: "Logout",
      route: "/auth/login",
      onClick: () => {
        storageService.clear();
        navigate("/auth/login");
      },
      icon: <Logout />,
    },
  ];

  const tabOptions = [
    {
      label: "Find Space",
      route: "/find-space",
    },
    {
      label: "Bookings",
      route: "/bookings",
    },
    {
      label: "Messages",
      route: "/messages",
    },
    {
      label: "Favorites",
      route: "/favorites",
    },
  ];

  const [dropdownActive, setDropdownActive] = useState(false);
  const [active, setActive] = useState(false);

  return (
    <div className="row g-0 justify-content-between navbar py-0 py-md-2">
      <div className="col-3 d-flex align-items-center">
        {token && (
          <button
            className="humburger-btn d-block d-md-none"
            onClick={() => setSidebar(!sidebar)}
          >
            <Humburger style={{ width: "20px", height: "20px" }} />
          </button>
        )}
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
          className="navbar-brand ps-3 ps-md-5"
        >
          <img src={Logo} alt="Spacegeni" width={150} height={50} />
        </a>
        {type === "dashboard" &&
          window.location.pathname !== "/main/settings" && (
            <button
              className="collapseBtn d-none d-md-block"
              onClick={() => setSidebar(!sidebar)}
            >
              <Chevron
                style={{
                  transition: "0.3s",
                  rotate: sidebar ? "180deg" : "0deg",
                }}
              />
            </button>
          )}
      </div>
      <div className="col d-flex justify-content-center">
        {type !== "dashboard" &&
          token &&
          tabOptions.map((option, index) => (
            <a
              key={index}
              href={option.route}
              onClick={(e) => {
                e.preventDefault();
                navigate(option.route);
              }}
              className={`navigation-tabs nav-link ${
                window.location.pathname.includes(option.route) && "active"
              }`}
            >
              {option.label}
            </a>
          ))}
      </div>
      <div className="col">
        {!token && (
          <div className="d-flex justify-content-end gap-2 pe-2 pe-md-5">
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate("/auth/login");
              }}
              className="btn btn-link text-decoration-none text-dark fw-500 text-nowrap"
            >
              Log in
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate("/auth/register");
              }}
              className="btn btn-primary rounded fw-600"
            >
              Register
            </button>
          </div>
        )}
        {(type === "dashboard" || type === "website") && token && (
          <div className="d-flex justify-content-end gap-4 gap-md-5 pe-2 pe-md-5">
            <div
              onClick={() => {
                setActive(!active);
                window.addEventListener("mousedown", (e) => {
                  if (!e.target.closest(".notifications")) {
                    setActive(false);
                  }
                });
              }}
              className="position-relative notif align-items-center d-flex pointer"
            >
              <Bell />
              <div
                className={`custom-dropdown-menu notifications ${
                  active ? "d-block" : "d-none"
                }`}
                id="dropdownMenu2"
              >
                <div className="border-bottom py-2 px-3">
                  <h1 className="fs-18 text-dark mb-0 fw-600">Notifications</h1>
                </div>
                <div className="row g-0">
                  {[1, 2, 3, 4, 5].map((item, index) => (
                    <div
                      key={index}
                      className="col-12 d-flex p-3 gap-2 border-bottom hoverable"
                    >
                      <img
                        src="https://i.pravatar.cc/150?img=3"
                        className="img-fluid avatar"
                        alt="avatar"
                      />
                      <div className="me-auto">
                        <p className="fw-bold fs-14 mb-0">Marcus</p>
                        <p className="mb-0 fs-14 text-muted">
                          Sent you a message
                        </p>
                      </div>
                      <small className="fs-12">2 mins ago</small>
                    </div>
                  ))}

                  <div className="col-12 text-center hoverable">
                    <p className="mb-0 pt-3 pb-2 fs-14 fw-bold">
                      See all notifications
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="d-flex align-items-center gap-2 gap-md-3 pointer position-relative"
              onClick={() => {
                setDropdownActive(!dropdownActive);
                window.addEventListener("mousedown", (e) => {
                  if (!e.target.closest("#dropdownMenu")) {
                    setDropdownActive(false);
                  }
                });
              }}
            >
              <img
                src={
                  user?.profile_picture ||
                  "https://ui-avatars.com/api/?name=" +
                    user?.first_name +
                    "+" +
                    user?.last_name
                }
                alt="avatar"
                className="avatar"
              />
              <div className="d-none d-md-block">
                <h5 className="mb-0 fw-600 fs-16 text-nowrap">
                  {user?.first_name} {user?.last_name}
                </h5>
                <p className="mb-0 fw-500 fs-14">Hosting</p>
              </div>
              <Chevron style={{ rotate: "270deg" }} />
              <div
                className="custom-dropdown-menu"
                id="dropdownMenu"
                style={{
                  display: dropdownActive ? "block" : "none",
                }}
              >
                {dropdownOptions.map((option, index) => (
                  <button
                    key={index}
                    onClick={option.onClick || null}
                    className="custom-dropdown-item"
                  >
                    <div className="d-flex align-items-center gap-3">
                      {option.icon}
                      <span>{option.label}</span>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
