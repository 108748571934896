// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="radio"] {
  display: none;
}

.radio-label {
  width: 1.125rem;
  height: 1.125rem;
  border: 1px solid #d5d6d9;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

input[type="radio"]:checked + .radio-label {
  background-color: #4c35de;
  border: 1px solid #4c35de;
}

input[type="radio"]:checked + .radio-label::after {
  content: "";
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/ExportForm/ExportForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,aAAa;EACb,cAAc;EACd,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC","sourcesContent":["input[type=\"radio\"] {\n  display: none;\n}\n\n.radio-label {\n  width: 1.125rem;\n  height: 1.125rem;\n  border: 1px solid #d5d6d9;\n  border-radius: 50%;\n  aspect-ratio: 1 / 1;\n  position: relative;\n  cursor: pointer;\n  transition: all 0.3s ease-in-out;\n}\n\ninput[type=\"radio\"]:checked + .radio-label {\n  background-color: #4c35de;\n  border: 1px solid #4c35de;\n}\n\ninput[type=\"radio\"]:checked + .radio-label::after {\n  content: \"\";\n  width: 0.7rem;\n  height: 0.7rem;\n  border-radius: 50%;\n  border: 2px solid #fff;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
