// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="checkbox"] {
  display: none;
}

.form-check-input {
  border-radius: 0.25rem;
  width: 1.5625rem;
  height: 1.5625rem;
  margin-right: 0.7rem;
  position: relative;
  transition: 0.3s;
}

.form-check-input::after {
  content: "";
  position: absolute;
  width: 0.625rem;
  height: 0.3125rem;
  top: 0.425rem;
  left: 0.45rem;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-45deg);
  opacity: 0;
  transition: 0.3s;
}

input[type="checkbox"]:checked + .form-check-input {
  background: var(--primary-color);
}

input[type="checkbox"]:checked + .form-check-input::after {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/RegisterForm/RegisterForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,aAAa;EACb,6BAA6B;EAC7B,2BAA2B;EAC3B,yBAAyB;EACzB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,UAAU;AACZ","sourcesContent":["input[type=\"checkbox\"] {\n  display: none;\n}\n\n.form-check-input {\n  border-radius: 0.25rem;\n  width: 1.5625rem;\n  height: 1.5625rem;\n  margin-right: 0.7rem;\n  position: relative;\n  transition: 0.3s;\n}\n\n.form-check-input::after {\n  content: \"\";\n  position: absolute;\n  width: 0.625rem;\n  height: 0.3125rem;\n  top: 0.425rem;\n  left: 0.45rem;\n  border-bottom: 2px solid #fff;\n  border-left: 2px solid #fff;\n  transform: rotate(-45deg);\n  opacity: 0;\n  transition: 0.3s;\n}\n\ninput[type=\"checkbox\"]:checked + .form-check-input {\n  background: var(--primary-color);\n}\n\ninput[type=\"checkbox\"]:checked + .form-check-input::after {\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
