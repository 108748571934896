// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listing {
  border-radius: 0.77994rem;
  background: #fff;
  width: 100%;
  box-shadow: 0px 2.69805px 22.93341px 2.69805px rgba(192, 192, 192, 0.17);
  padding: 1.5rem;
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
  transition: all 0.1s ease-in-out;
}

.listing:active {
  transform: scale(0.98);
}

.listing .image-container {
  width: 100%;
  height: 15.625rem;
  border-radius: 0.77994rem;
  overflow: hidden;
  position: relative;
}

.listing .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.listing .image-container .tags {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem;
}

.listing .image-container .tags p {
  border-radius: 1.6875rem;
  background: #756cf8;
  padding: 0.3rem 1rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.75rem;
}

.listing .image-container .navtabs {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
}

.listing .image-container .navtabs .dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #fff;
  display: inline-block;
  aspect-ratio: 1/1;
  opacity: 0.5;
  cursor: pointer;
}

.listing .image-container .navtabs .dot.active {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/application_components/ListingCard/ListingCard.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,WAAW;EACX,wEAAwE;EACxE,eAAe;EACf,eAAe;EACf,wBAAmB;EAAnB,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,mBAAmB;EACnB,oBAAoB;EACpB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;EACrB,iBAAiB;EACjB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".listing {\n  border-radius: 0.77994rem;\n  background: #fff;\n  width: 100%;\n  box-shadow: 0px 2.69805px 22.93341px 2.69805px rgba(192, 192, 192, 0.17);\n  padding: 1.5rem;\n  cursor: pointer;\n  height: fit-content;\n  transition: all 0.1s ease-in-out;\n}\n\n.listing:active {\n  transform: scale(0.98);\n}\n\n.listing .image-container {\n  width: 100%;\n  height: 15.625rem;\n  border-radius: 0.77994rem;\n  overflow: hidden;\n  position: relative;\n}\n\n.listing .image-container img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.listing .image-container .tags {\n  position: absolute;\n  top: 0;\n  left: 0;\n  padding: 0.5rem;\n}\n\n.listing .image-container .tags p {\n  border-radius: 1.6875rem;\n  background: #756cf8;\n  padding: 0.3rem 1rem;\n  color: #fff;\n  font-weight: 500;\n  font-size: 0.75rem;\n}\n\n.listing .image-container .navtabs {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  padding: 0.5rem;\n}\n\n.listing .image-container .navtabs .dot {\n  width: 0.5rem;\n  height: 0.5rem;\n  border-radius: 50%;\n  background: #fff;\n  display: inline-block;\n  aspect-ratio: 1/1;\n  opacity: 0.5;\n  cursor: pointer;\n}\n\n.listing .image-container .navtabs .dot.active {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
