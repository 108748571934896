// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.upload-modal.show {
  opacity: 1;
  visibility: visible;
}

.upload-modal .modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 100%;
  height: 100%;
  max-height: 40.25rem;
  max-width: 40.5rem;
  border-radius: 5px;
}

.drop-area.document {
  border: 2px dashed #ccc;
  height: 20rem;
  width: calc(100% - 2rem);
  margin-inline: auto;
}

@media screen and (max-width: 48em) {
  .upload-modal .modal-content{
    max-height: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/CreateListingsModal/UploadVerificationDocument/UploadVerificationDocument.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,aAAa;EACb,0CAA0C;EAC1C,gCAAgC;EAChC,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".upload-modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 9999;\n  background-color: rgba(255, 255, 255, 0.5);\n  transition: all 0.3s ease-in-out;\n  opacity: 0;\n  visibility: hidden;\n}\n\n.upload-modal.show {\n  opacity: 1;\n  visibility: visible;\n}\n\n.upload-modal .modal-content {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: white;\n  width: 100%;\n  height: 100%;\n  max-height: 40.25rem;\n  max-width: 40.5rem;\n  border-radius: 5px;\n}\n\n.drop-area.document {\n  border: 2px dashed #ccc;\n  height: 20rem;\n  width: calc(100% - 2rem);\n  margin-inline: auto;\n}\n\n@media screen and (max-width: 48em) {\n  .upload-modal .modal-content{\n    max-height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
