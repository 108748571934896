// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.space-card {
  border-radius: 0.875rem;
  background: #fff;
  box-shadow: 0px 2px 17px 2px rgba(192, 192, 192, 0.17);
}

.started-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.mb-7 {
  margin-bottom: 7rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Website/FindSpace/GetStarted/GetStarted.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,sDAAsD;AACxD;;AAEA;EACE,eAAe;EACf,SAAS;EACT,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".space-card {\n  border-radius: 0.875rem;\n  background: #fff;\n  box-shadow: 0px 2px 17px 2px rgba(192, 192, 192, 0.17);\n}\n\n.started-footer {\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n}\n\n.mb-7 {\n  margin-bottom: 7rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
