export const Heart = ({ className }) => {
  if (className === "filled")
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
      >
        <path
          d="M24.8133 6.21094C20.2489 6.21094 17.999 10.7107 17.999 10.7107C17.999 10.7107 15.7491 6.21094 11.1847 6.21094C7.47521 6.21094 4.53771 9.31436 4.49974 13.0175C4.4224 20.7044 10.5976 26.1709 17.3662 30.7648C17.5528 30.8918 17.7733 30.9597 17.999 30.9597C18.2247 30.9597 18.4452 30.8918 18.6318 30.7648C25.3997 26.1709 31.5749 20.7044 31.4983 13.0175C31.4603 9.31436 28.5228 6.21094 24.8133 6.21094Z"
          fill="#756CF8"
          stroke="#756CF8"
          strokeWidth="2.24988"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  else
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
      >
        <path
          d="M24.8133 6.21094C20.2489 6.21094 17.999 10.7107 17.999 10.7107C17.999 10.7107 15.7491 6.21094 11.1847 6.21094C7.47521 6.21094 4.53771 9.31436 4.49974 13.0175C4.4224 20.7044 10.5976 26.1709 17.3662 30.7648C17.5528 30.8918 17.7733 30.9597 17.999 30.9597C18.2247 30.9597 18.4452 30.8918 18.6318 30.7648C25.3997 26.1709 31.5749 20.7044 31.4983 13.0175C31.4603 9.31436 28.5228 6.21094 24.8133 6.21094Z"
          fill="white"
          stroke="#756CF8"
          strokeWidth="2.24988"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
};
