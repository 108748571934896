import React from "react";
import "./Taxes.css";
import { Taxpayers } from "./Taxpayers/Taxpayers";
import { TaxDocuments } from "./TaxDocuments/TaxDocuments";

export const Taxes = () => {
  return (
    <>
      <div className="card shadow border-0 rounded-4 w-100 payment-container">
        <ul className="nav nav-tabs gap-3 ps-3 mt-4" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="taxpayers-tab"
              data-bs-toggle="tab"
              data-bs-target="#taxpayers"
              type="button"
              role="tab"
              aria-controls="taxpayers"
              aria-selected="true"
            >
              Taxpayers
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="taxdocuments-tab"
              data-bs-toggle="tab"
              data-bs-target="#taxdocuments"
              type="button"
              role="tab"
              aria-controls="taxdocuments"
              aria-selected="false"
            >
              Tax documents
            </button>
          </li>
        </ul>

        <div className="tab-content" style={{ minHeight: "400px" }}>
          <div
            className="tab-pane active"
            id="taxpayers"
            role="tabpanel"
            aria-labelledby="taxpayers-tab"
          >
            <Taxpayers />
          </div>
          <div
            className="tab-pane"
            id="taxdocuments"
            role="tabpanel"
            aria-labelledby="taxdocuments-tab"
          >
            <TaxDocuments />
          </div>
        </div>
      </div>
    </>
  );
};
