import React, { useEffect, useState } from "react";
import Logo from "../../../assets/images/dark-logo.svg";
import { useListingFormContext } from "../../../context/ListingFormContext";
import { useSpinnerContext } from "../../../context/SpinnerContext";
import { storageService } from "../../../lib/storage.service";
import { toastService } from "../../../lib/toast.service";
import { postListing } from "../../../services/listings.service";
import { utilService } from "../../../services/util.service";
import { UncutModal } from "../../ui/modal/modal";
import "./CreateListingsModal.css";
import { GetStarted } from "./GetStarted/GetStarted";
import { PlaceType } from "./PlaceType/PlaceType";
import { ReviewListing } from "./ReviewListing/ReviewListing";
import { ReviewListingFinal } from "./ReviewListingFinal/ReviewListingFinal";
import { SpaceAmenities } from "./SpaceAmenities/SpaceAmenities";
import { SpaceDescription } from "./SpaceDescription/SpaceDescription";
import { SpaceDiscounts } from "./SpaceDiscounts/SpaceDiscounts";
import { SpaceLastStep } from "./SpaceLastStep/SpaceLastStep";
import { SpaceLocation } from "./SpaceLocation/SpaceLocation";
import { SpacePhotos } from "./SpacePhotos/SpacePhotos";
import { SpacePrice } from "./SpacePrice/SpacePrice";
import { SpaceProfession } from "./SpaceProfession/SpaceProfession";
import { SpaceTitle } from "./SpaceTitle/SpaceTitle";
import { SpaceType } from "./SpaceType/SpaceType";
import { SpaceWhoToWelcome } from "./SpaceWhoToWelcome/SpaceWhoToWelcome";

export const CreateListingsModal = ({
  id,
  categories,
  amenities,
  fetchListings,
}) => {
  const { listingForm, setListingForm } = useListingFormContext();

  const { setSpinner } = useSpinnerContext();

  const [wizardStep, setWizardStep] = useState(0);

  const components = [
    <GetStarted />,
    <SpaceProfession
      listingForm={listingForm}
      setListingForm={setListingForm}
    />,
    <SpaceType
      categories={categories}
      listingForm={listingForm}
      setListingForm={setListingForm}
    />,
    <PlaceType listingForm={listingForm} setListingForm={setListingForm} />,
    <SpaceLocation listingForm={listingForm} setListingForm={setListingForm} />,
    <SpaceAmenities
      amenities={amenities}
      listingForm={listingForm}
      setListingForm={setListingForm}
    />,
    <SpacePhotos listingForm={listingForm} setListingForm={setListingForm} />,
    <SpaceTitle listingForm={listingForm} setListingForm={setListingForm} />,
    <SpaceDescription
      listingForm={listingForm}
      setListingForm={setListingForm}
    />,
    <SpaceWhoToWelcome
      listingForm={listingForm}
      setListingForm={setListingForm}
    />,
    <SpacePrice listingForm={listingForm} setListingForm={setListingForm} />,
    <SpaceDiscounts
      listingForm={listingForm}
      setListingForm={setListingForm}
    />,
    <SpaceLastStep listingForm={listingForm} setListingForm={setListingForm} />,
    <ReviewListing listingForm={listingForm} />,
    <ReviewListingFinal
      listingForm={listingForm}
      setListingForm={setListingForm}
    />,
  ];

  const renderComponent = (wizardStep) => {
    switch (wizardStep) {
      case 0:
        return components[0];
      case 1:
        return components[1];
      case 2:
        return components[2];
      case 3:
        return components[3];
      case 4:
        return components[4];
      case 5:
        return components[5];
      case 6:
        return components[6];
      case 7:
        return components[7];
      case 8:
        return components[8];
      case 9:
        return components[9];
      case 10:
        return components[10];
      case 11:
        return components[11];
      case 12:
        return components[12];
      case 13:
        return components[13];
      case 14:
        return components[14];
      default:
        return components[0];
    }
  };

  useEffect(() => {
    const renderProgress = () => {
      let progressBar = document.getElementById("wizard-progress");
      let progress = (wizardStep / (components.length - 1)) * 100;
      progressBar?.style.setProperty("--progress-width", `${progress}%`);
    };

    renderProgress();
  }, [wizardStep, components.length]);

  const createListing = async () => {
    // upload images to cloudinary using utilService.uploadImage
    // set listingForm.images to the cloudinary urls
    // set listingForm.featured_image to the first image in listingForm.images
    // set listingForm.amenities to an array of amenity ids
    // call listingService.createListing(listingForm)
    try {
      if (
        !listingForm.title ||
        !listingForm.description ||
        !listingForm.price ||
        !listingForm.category_id ||
        !listingForm.type ||
        !listingForm.latitude ||
        !listingForm.longitude ||
        listingForm.amenities.length === 0 ||
        listingForm.images.length === 0
      )
        return;

      setSpinner(true);
      let urlImages = [];

      for (let i = 0; i < listingForm.images.length; i++) {
        const image = listingForm.images[i];
        const file_type = "image";
        const resp = await utilService.uploadImage(image, file_type);
        urlImages.push(resp.results.secure_url);
      }

      if (urlImages.length !== listingForm.images.length) return;

      listingForm.images = urlImages;
      listingForm.featured_image = urlImages[0];
      listingForm.amenities = listingForm.amenities.map((amenity) => {
        let amenity_id = amenity.id;
        delete amenity.id;
        return { ...amenity, amenity_id: amenity_id };
      });

      const resp = await postListing(listingForm);
      if (resp.status === 201 || resp.status === 200) {
        setListingForm(null);
        setWizardStep(0);
        await fetchListings();
        storageService.removeListingForm();
        utilService.closeModal(id);
        toastService.success(resp.message);
      }
      setSpinner(false);
    } catch (error) {
      console.log(error);
      setSpinner(false);
    }
  };

  return (
    <UncutModal id={id} scrollable={true} centered={true} size="xl">
      <div className="modal-header">
        <a className="logo" href="/">
          <img src={Logo} alt="logo" width={150} height={50} />
        </a>
        <button
          type="button"
          className="close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            if (wizardStep === components.length - 1) {
              createListing();
            }
          }}
        >
          Save & Exit
        </button>
      </div>
      <div className="modal-body">{renderComponent(wizardStep)}</div>
      <div
        className={`modal-footer ${
          wizardStep > 0 && "wizard-progress justify-content-between"
        } px-2 px-md-5 flex-nowrap`}
        id="wizard-progress"
      >
        <button
          type="button"
          className={`action-btn outline ${wizardStep === 0 && "d-none"}`}
          onClick={() => setWizardStep(wizardStep - 1)}
        >
          Back
        </button>
        <button
          type="button"
          className="action-btn"
          onClick={() => {
            wizardStep < components.length - 1 && setWizardStep(wizardStep + 1);
            if (wizardStep === components.length - 1) {
              createListing();
            }
          }}
          disabled={
            (wizardStep === 1 && !listingForm?.profession) ||
            (wizardStep === 2 && !listingForm?.category_id) ||
            (wizardStep === 3 && !listingForm?.type) ||
            (wizardStep === 5 && !listingForm?.amenities) ||
            (wizardStep === 6 && !listingForm?.images) ||
            (wizardStep === 7 && !listingForm?.title) ||
            (wizardStep === 8 && !listingForm?.description)
          }
        >
          {wizardStep === 0 ? "Get Started" : "Continue"}
        </button>
      </div>
    </UncutModal>
  );
};
