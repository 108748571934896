import { Country } from "country-state-city";
import React, { useMemo, useState } from "react";
import { ReactComponent as CardBack } from "../../../assets/icons/card-back.svg";
import { ReactComponent as CardFront } from "../../../assets/icons/card-front.svg";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron.svg";
import { ReactComponent as ClosePrimary } from "../../../assets/icons/close-primary.svg";
import { UncutModal } from "../../ui/modal/modal";
import "./EditGovernmentIdModal.css";

export const EditGovernmentIdModal = ({ id }) => {
  const [uploadType, setUploadType] = useState("existing");

  const [imageType, setImageType] = useState({
    country: "",
    idType: "",
  });

  const idTypes = [
    {
      id: 1,
      name: "Driver’s license",
      value: "driver",
    },
    {
      id: 2,
      name: "Passport",
      value: "passport",
    },
    {
      id: 3,
      name: "National ID",
      value: "national",
    },
  ];

  const [image, setImage] = useState({
    front: "",
    back: "",
  });

  const options = useMemo(() => Country.getAllCountries(), []);

  const [dropdown, setDropdown] = useState(false);

  const [dragging, setDragging] = useState(false);

  const [draggingBack, setDraggingBack] = useState(false);

  const [step, setStep] = useState(1);

  const handleImageUpload = (e, type) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImage({
        ...image,
        [type]: reader.result,
      });
    };
  };

  const handleDragOver = (e, type) => {
    e.preventDefault();
    if (type === "front") {
      setDragging(true);
    } else {
      setDraggingBack(true);
    }
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    if (type === "front") {
      setDragging(false);
    } else {
      setDraggingBack(false);
    }
    const file = e.dataTransfer.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImage({
        ...image,
        [type]: reader.result,
      });
    };
  };

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Government ID</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        {
          // Step 1
          step === 1 && (
            <>
              <p className="mb-0 mt-2 mb-4 text-muted">
                We’ll need you to add an official government ID. This step helps
                make sure you’re really you.
              </p>
              <div className="form">
                <div className="my-4 border rounded-2 d-flex justify-content-between p-3 gap-2">
                  <label
                    htmlFor="existing"
                    className="form-label fw-500 mb-0 pointer w-75"
                  >
                    <h5 className="mb-0 fw-500 fs-18">
                      Upload an existing photo
                    </h5>
                    <small className="text-muted">Recommended</small>
                  </label>
                  <input
                    type="checkbox"
                    id="existing"
                    name="existing"
                    value={uploadType === "existing"}
                    className="d-none"
                    onChange={() => setUploadType("existing")}
                    checked={uploadType === "existing"}
                  />
                  <label
                    htmlFor="existing"
                    className="form-checkbox-rounded"
                  ></label>
                </div>
                <div className="my-4 border rounded-2 d-flex justify-content-between p-3 gap-2">
                  <label
                    htmlFor="existing"
                    className="form-label fw-500 mb-0 pointer w-75"
                  >
                    <h5 className="mb-0 fw-500 fs-18">
                      Take photo with your webcam
                    </h5>
                  </label>
                  <input
                    type="checkbox"
                    id="webcam"
                    name="webcam"
                    value={uploadType === "webcam"}
                    className="d-none"
                    onChange={() => setUploadType("webcam")}
                    checked={uploadType === "webcam"}
                  />
                  <label
                    htmlFor="webcam"
                    className="form-checkbox-rounded"
                  ></label>
                </div>
                <div className="my-4 border rounded-2 d-flex justify-content-between p-3 gap-2">
                  <label
                    htmlFor="existing"
                    className="form-label fw-500 mb-0 pointer w-75"
                  >
                    <h5 className="mb-0 fw-500 fs-18">
                      Take photo with our mobile app
                    </h5>
                  </label>
                  <input
                    type="checkbox"
                    id="mobile"
                    name="mobile"
                    value={uploadType === "mobile"}
                    className="d-none"
                    onChange={() => setUploadType("mobile")}
                    checked={uploadType === "mobile"}
                  />
                  <label
                    htmlFor="mobile"
                    className="form-checkbox-rounded"
                  ></label>
                </div>
              </div>
            </>
          )
        }
        {step === 2 && (
          <>
            <Chevron
              style={{ rotate: "-270deg" }}
              className="mx-auto mb-4 mt-3 pointer"
              onClick={() => setStep(1)}
            />
            <h5 className="mb-0 fw-600 fs-18" style={{ width: "20rem" }}>
              Choose an ID type to add
            </h5>
            <div className="form">
              <div className="custom-form-select my-3">
                <div
                  className="form-select-btn"
                  onClick={() => {
                    setDropdown(!dropdown);
                  }}
                >
                  <span
                    className="fw-500 me-2"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {imageType.country ? imageType.country : "Country"}
                  </span>
                  <Chevron />
                </div>
                <div
                  className={`form-select-content ${dropdown ? "show" : ""}`}
                >
                  {options.map((country, index) => {
                    return (
                      <div
                        className="form-select-item"
                        key={index}
                        onClick={() => {
                          setImageType({
                            ...imageType,
                            country: country.name,
                          });
                          setDropdown(false);
                        }}
                      >
                        <label>{country.name}</label>
                      </div>
                    );
                  })}
                </div>
              </div>

              {idTypes.map((photoType) => {
                return (
                  <div
                    className="my-4 rounded-2 d-flex justify-content-between p-3 gap-2"
                    key={photoType.id}
                  >
                    <label
                      htmlFor={photoType.value}
                      className="form-label fw-500 mb-0 pointer w-75"
                    >
                      <h5 className="mb-0 fw-500 fs-18">{photoType.name}</h5>
                    </label>
                    <input
                      type="radio"
                      id={photoType.value}
                      name="photoType"
                      value={photoType.value}
                      className="d-none"
                      onChange={() => {
                        setImageType({
                          ...imageType,
                          idType: photoType.value,
                        });
                      }}
                      checked={photoType.value === imageType.idType}
                    />
                    <label
                      htmlFor={photoType.value}
                      className="form-checkbox-rounded"
                    ></label>
                  </div>
                );
              })}

              <p className="text-muted mb-0">
                Your ID will be handled according to our{" "}
                <a href="#!" className="text-primary">
                  Privacy Policy
                </a>{" "}
                and won’t be shared with your Host or guests.
              </p>
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <Chevron
              style={{ rotate: "-270deg" }}
              className="mx-auto mb-4 mt-3 pointer"
              onClick={() => setStep(1)}
            />
            <h5 className="mb-2 fw-600 fs-18">
              Upload images of your driver’s license
            </h5>
            <p className="text-muted mb-0">
              Make sure your photos aren’t blurry and the front of your driver’s
              license clearly shows your face.
            </p>
            <div className="form">
              <label
                className={`uploadArea ${dragging ? "dragging" : ""}`}
                htmlFor="front"
                onDragOver={(e) => handleDragOver(e, "front")}
                onDrop={(e) => handleDrop(e, "front")}
                onDragLeave={() => setDragging(false)}
              >
                <CardFront />
                <h5 className="mt-2 mb-2 fw-600 fs-18">Upload front</h5>
                <p className="mb-0">JPEG or PNG only</p>
              </label>
              <input
                type="file"
                className="d-none"
                id="front"
                onChange={(e) => handleImageUpload(e, "front")}
              />
              <label
                className={`uploadArea ${draggingBack ? "dragging" : ""}`}
                htmlFor="back"
                onDrop={(e) => handleDrop(e, "back")}
                onDragOver={(e) => handleDragOver(e, "back")}
                onDragLeave={() => setDraggingBack(false)}
              >
                <CardBack />
                <h5 className="mt-2 mb-2 fw-600 fs-18">Upload back</h5>
                <p className="mb-0">JPEG or PNG only</p>
              </label>
              <input
                type="file"
                className="d-none"
                id="back"
                onChange={(e) => handleImageUpload(e, "back")}
              />
            </div>
          </>
        )}
      </div>
      <div className="modal-footer px-5 d-flex align-items-center">
        {step === 1 && (
          <button
            type="button"
            className="btn btn-primary fw-600 fs-20 mx-auto"
            onClick={() => setStep(2)}
          >
            Continue
          </button>
        )}
        {step === 2 && (
          <>
            <button
              type="button"
              className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
              onClick={() => setStep(1)}
            >
              Back
            </button>
            <button
              type="button"
              className="btn btn-primary fw-600 flex-grow-1 fs-20"
              onClick={() => setStep(3)}
            >
              Continue
            </button>
          </>
        )}
        {step === 3 && (
          <>
            <button
              type="button"
              className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
              onClick={() => setStep(1)}
            >
              Back
            </button>
            <button
              type="button"
              className="btn btn-primary fw-600 flex-grow-1 fs-20"
            >
              Continue
            </button>
          </>
        )}
      </div>
    </UncutModal>
  );
};
