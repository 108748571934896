import { post } from "../lib/http.service";

const API_URL = process.env.REACT_APP_API_URL;

export const login = async ({ email, password }) => {
  try {
    const resp = await post(`${API_URL}/user/signin/`, {
      email,
      password,
    });
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const register = async ({
  first_name,
  last_name,
  email,
  phone,
  password,
  confirmPassword,
}) => {
  try {
    const resp = await post(`${API_URL}/user/signup/`, {
      email,
      first_name,
      last_name,
      phone_number: phone,
      password,
      confirm_password: confirmPassword,
    });
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const confirmEmail = async ({
  user_id,
  email_verification_string,
  email,
}) => {
  try {
    const resp = await post(`${API_URL}/user/confirm-email/`, {
      email,
      email_verification_string,
      user_id,
    });
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const forgotPassword = async ({ email }) => {
  try {
    const resp = await post(`${API_URL}/user/forgot-password/`, {
      email,
    });
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const resetPassword = async ({
  email,
  user_id,
  reset_token,
  password,
}) => {
  try {
    const resp = await post(`${API_URL}/user/forgot-password/`, {
      email,
      user_id,
      reset_token,
      password,
    });
    console.log(resp);
    return resp.data;
  } catch (ex) {
    console.log(ex);
  }
};
