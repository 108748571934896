// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-form-select {
  position: relative;
}

.custom-form-select .form-select-btn {
  padding: 0.8rem 1.3rem;
  border: 2px solid #d5d6d9;
  border-radius: 0.625rem;
  background-color: #fff;
  color: #333;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-form-select .form-select-content {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  max-height: 15rem;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  border-radius: 0.625rem;
  padding: 0.5rem 0;
  display: none;
}

.custom-form-select .form-select-content.show {
  display: block;
}

.custom-form-select .form-select-content .form-select-item {
  padding: 0.5rem 1.3rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-form-select .form-select-content .form-select-item:hover {
  background-color: #f5f5f5;
}

.custom-form-select .form-select-content .form-select-item label {
  font-weight: 500;
  font-size: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/EditListingsModal/CancellationPolicy/CancellationPolicy.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,uBAAuB;EACvB,sBAAsB;EACtB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,UAAU;EACV,iBAAiB;EACjB,gBAAgB;EAChB,sBAAsB;EACtB,8CAA8C;EAC9C,uBAAuB;EACvB,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".custom-form-select {\n  position: relative;\n}\n\n.custom-form-select .form-select-btn {\n  padding: 0.8rem 1.3rem;\n  border: 2px solid #d5d6d9;\n  border-radius: 0.625rem;\n  background-color: #fff;\n  color: #333;\n  font-size: 1rem;\n  line-height: 1.5;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.custom-form-select .form-select-content {\n  position: absolute;\n  top: 100%;\n  left: 0;\n  right: 0;\n  z-index: 1;\n  max-height: 15rem;\n  overflow-y: auto;\n  background-color: #fff;\n  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);\n  border-radius: 0.625rem;\n  padding: 0.5rem 0;\n  display: none;\n}\n\n.custom-form-select .form-select-content.show {\n  display: block;\n}\n\n.custom-form-select .form-select-content .form-select-item {\n  padding: 0.5rem 1.3rem;\n  cursor: pointer;\n  transition: all 0.3s ease;\n}\n\n.custom-form-select .form-select-content .form-select-item:hover {\n  background-color: #f5f5f5;\n}\n\n.custom-form-select .form-select-content .form-select-item label {\n  font-weight: 500;\n  font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
