import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { ReactComponent as ChatBox } from "../../../assets/icons/chatbox-sm.svg";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron.svg";
import { ReactComponent as ClosePrimary } from "../../../assets/icons/close-primary.svg";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { ReactComponent as Ellipsis } from "../../../assets/icons/ellipsis.svg";
import { ReactComponent as Email } from "../../../assets/icons/email.svg";
import { ReactComponent as Options } from "../../../assets/icons/options.svg";
import { NoData } from "../../../components/application_components/NoData/NoData";
import { ExportForm } from "../../../components/forms/ExportForm/ExportForm";
import { FilterForm } from "../../../components/forms/FilterForm/FilterForm";
import { UncutModal } from "../../../components/ui/modal/modal";
import { useSpinnerContext } from "../../../context/SpinnerContext";
import {
  filterReservations,
  getReservations,
} from "../../../services/reservations.service";
import "./Reservations.css";

export const Reservations = () => {
  const { setSpinner } = useSpinnerContext();

  const tabs = [
    {
      name: "Upcoming",
      value: "upcoming",
    },
    {
      name: "Completed",
      value: "completed",
    },
    {
      name: "Cancelled",
      value: "cancelled",
    },
    {
      name: "All",
      value: "all",
    },
  ];

  const dropdownOptions = [
    {
      label: "Message Guest",
      onClick: (e, reservation) => {},
      icon: <ChatBox />,
    },
    {
      label: "Email Guest",
      onClick: (e, reservation) => {
        window.location.href = `mailto:${reservation.owner.email}`;
      },
      icon: <Email />,
    },
    {
      label: "Change or Cancel",
      onClick: () => {},
      icon: <Close />,
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[3].value);
  const [filterFormActive, setFilterFormActive] = useState(false);

  const [reservations, setReservations] = useState([]);
  const [reservationDetails, setReservationDetails] = useState(null);

  useEffect(() => {
    const fetchReservations = async () => {
      setSpinner(true);
      const resp = await getReservations();
      setReservations(resp.result);
      setSpinner(false);
    };
    fetchReservations();
  }, [setSpinner]);

  const fetchFilteredReservations = async (
    status,
    checkin_datetime,
    checkout_datetime
  ) => {
    setSpinner(true);
    const resp = await filterReservations({
      status,
      checkin_datetime,
      checkout_datetime,
    });
    setReservations(resp.result);
    setSpinner(false);
  };

  return (
    <div className="h-100">
      <div className="d-flex justify-content-between gap-2 border-bottom pb-3 px-4 px-md-3 flex-wrap">
        <h4 className="fw-600 text-dark fs-28 mb-0">Reservations</h4>
        <div className="d-flex align-items-center gap-3">
          <div className="position-relative">
            <div
              className="btn btn-secondary text-dark fw-600 px-3"
              onClick={() => {
                setFilterFormActive(!filterFormActive);
                window.addEventListener("mousedown", (e) => {
                  if (!e.target.closest("#filterContainer")) {
                    setFilterFormActive(false);
                  }
                });
              }}
            >
              <Options className="me-2" width={16} height={16} />
              Filter
            </div>
            <div
              className={`filter-container ${filterFormActive ? "active" : ""}`}
              id="filterContainer"
            >
              <div className="modal-header p-3 px-4 border-bottom">
                <h5 className="modal-title fs-22 fw-600">Filters</h5>
                <button
                  type="button"
                  className="close-btn"
                  onClick={() => setFilterFormActive(!filterFormActive)}
                >
                  <ClosePrimary />
                </button>
              </div>
              <div className="modal-body px-4">
                <p className="mb-2 text-muted fs-16 py-3">
                  Reservations that start or end within the following dates.
                </p>
                <FilterForm
                  filterReservations={fetchFilteredReservations}
                  filter={activeTab === "all" ? {} : { status: activeTab }}
                  setFilterFormActive={setFilterFormActive}
                />
              </div>
            </div>
          </div>
          <button
            className="btn btn-secondary text-dark fw-600 px-3"
            data-bs-toggle="modal"
            data-bs-target="#exportModal"
          >
            Export <Chevron className="ms-2" />
          </button>
        </div>
      </div>
      <div className="d-flex align-items-center gap-2 px-4 px-md-3 my-3 flex-wrap">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`btn btn-label ${
              activeTab === tab.value ? "active" : ""
            }`}
            onClick={() => {
              setActiveTab(tab.value);
              if (tab.value === "all") {
                fetchFilteredReservations();
              } else {
                fetchFilteredReservations(tab.name);
              }
            }}
          >
            {tab.name}
          </button>
        ))}
      </div>
      {reservations.length > 0 && (
        <div className="table-responsive h-100 px-4 px-md-3">
          <table className="booking-table">
            <tbody>
              {reservations.map((reservation, index) => (
                <tr className="booking-row" key={index}>
                  <td className="booking-cell">
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src={
                          reservation.space.featured_image ||
                          "https://via.placeholder.com/50"
                        }
                        alt=""
                        className="avatar"
                      />
                      <div>
                        <p className="mb-0 text-muted">Guest Name</p>
                        <b>
                          {reservation.owner.first_name +
                            " " +
                            reservation.owner.last_name}
                        </b>
                      </div>
                    </div>
                  </td>
                  <td className="booking-cell">
                    <p className="mb-0 text-muted">Listing</p>
                    <p className="fw-600 mb-0">{reservation.space.title}</p>
                  </td>
                  <td className="booking-cell">
                    <p className="mb-0 text-muted">Booked</p>
                    <b>
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      }).format(new Date(reservation.checkin_date)) +
                        " - "}{" "}
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      }).format(new Date(reservation.checkout_date))}
                    </b>
                  </td>
                  <td className="booking-cell">
                    <p className="mb-0 text-muted">Status</p>
                    <b>{reservation.status}</b>
                  </td>
                  <td className="booking-cell">
                    <p className="mb-0 text-muted">Date</p>
                    <b>
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                      }).format(new Date(reservation.created))}
                    </b>
                  </td>
                  <td className="booking-cell">
                    <p className="mb-0 text-muted">Price</p>
                    <b>{"$" + reservation.price}</b>
                  </td>
                  <td className="booking-cell">
                    <button
                      className="btn btn-primary-outline fw-600"
                      data-bs-toggle="modal"
                      data-bs-target="#reservationModal"
                      onClick={() => setReservationDetails(reservation)}
                    >
                      Details
                    </button>
                  </td>
                  <td className="booking-cell">
                    <div
                      className="btn btn-link p-0 position-relative"
                      onClick={(e) => {
                        let dropdownMenu =
                          e.target.parentElement.querySelector(
                            ".res-dropdown-menu"
                          );
                        dropdownMenu && (dropdownMenu.style.display = "block");

                        window.addEventListener("mousedown", (e) => {
                          if (
                            dropdownMenu &&
                            !e.target.closest(".res-dropdown-menu")
                          ) {
                            dropdownMenu.style.display = "none";
                          }
                        });
                      }}
                    >
                      <Ellipsis width={25} height={25} />
                      <div className="res-dropdown-menu">
                        {dropdownOptions.map((option, index) => (
                          <button
                            key={index}
                            onClick={(e) => option.onClick(e, reservation)}
                            className="custom-dropdown-item"
                          >
                            <div className="d-flex align-items-center gap-3">
                              {option.icon}
                              <span>{option.label}</span>
                            </div>
                          </button>
                        ))}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <UncutModal id="reservationModal" scrollable={true} right={true}>
        <div className="modal-header">
          <h5 className="modal-title fs-22 fw-600">Reservation Details</h5>
          <button
            type="button"
            className="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <ClosePrimary />
          </button>
        </div>
        <div className="modal-body px-4">
          <div className="col-12 text-center mt-3">
            <img
              src={
                reservationDetails?.space?.featured_image ||
                "https://via.placeholder.com/200"
              }
              alt="avatar"
              style={{
                objectFit: "cover",
                borderRadius: "50%",
                width: 80,
                height: 80,
                aspectRatio: 1 / 1,
              }}
              className="img-fluid mb-3"
            />
            <h5 className="fw-600 fs-20">
              {reservationDetails?.owner?.first_name +
                " " +
                reservationDetails?.owner?.last_name}
            </h5>
            <div className="d-flex align-items-center gap-2 justify-content-center">
              <Rating
                initialValue={4.5}
                allowFraction={true}
                fillColor="#4c35de"
                allowHover={false}
                size={25}
              />
              <p className="mb-0 text-dark fs-14">(15)</p>
            </div>
            <p className="mb-0 text-muted fs-14">Member since: 2019</p>
          </div>
          <div className="row g-0 gy-4 mt-4 border rounded-4 p-4 py-4">
            <div className="col-12 mt-0">
              <p className="mb-2 text-muted fs-14 fw-500">Confirmation #</p>
              <p className="mb-0 text-dark fw-600">
                #{reservationDetails?.id?.slice(-6)}
              </p>
            </div>
            <div className="col-12">
              <p className="mb-2 text-muted fs-14 fw-500">Book</p>
              <p className="mb-0 text-dark fw-600">
                {new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                }).format(
                  new Date(reservationDetails?.checkin_date || Date.now())
                ) + " - "}{" "}
                {new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                }).format(
                  new Date(reservationDetails?.checkout_date || Date.now())
                )}
              </p>
            </div>
            <div className="col-12">
              <p className="mb-2 text-muted fs-14 fw-500">Status</p>
              <p className="mb-0 text-dark fw-600">
                {reservationDetails?.status}
              </p>
            </div>
            <div className="col-12">
              <p className="mb-2 text-muted fs-14 fw-500">Date</p>
              <p className="mb-0 text-dark fw-600">
                {new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                }).format(new Date(reservationDetails?.created || Date.now()))}
              </p>
            </div>
            <div className="col-12">
              <p className="mb-2 text-muted fs-14 fw-500">Price</p>
              <p className="mb-0 text-dark fw-600">{`$${reservationDetails?.price}`}</p>
            </div>
          </div>
          <h5 className="fw-600 fs-20 mt-4">Listing</h5>
          <div className="row g-0 mt-3 border rounded-4 p-3">
            <div className="col-12 d-flex align-items-center gap-4">
              <img
                src="https://via.placeholder.com/200"
                alt="listing"
                style={{ width: "7.125rem", height: "7.125rem" }}
                className="rounded-2"
              />
              <div>
                <p className="mb-2 text-dark fw-600">
                  Well furnished Kitchen in Boston on Main Street.
                </p>
                <p className="mb-2 text-muted fs-14 fw-500">
                  Replace this text with space features later.
                </p>
              </div>
            </div>
          </div>
        </div>
      </UncutModal>
      <UncutModal
        id="exportModal"
        scrollable={true}
        centered={true}
        size={"lg"}
        backdropColor="white"
      >
        <div className="modal-header border-bottom px-4">
          <h5 className="modal-title fs-22 fw-600">Export Reservations</h5>
          <button
            type="button"
            className="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <ClosePrimary />
          </button>
        </div>
        <div className="modal-body px-0">
          <ExportForm />
        </div>
      </UncutModal>
      {reservations.length === 0 && (
        <div className="h-75 d-flex align-items-center justify-content-center">
          <NoData title="Reservations" />
        </div>
      )}
    </div>
  );
};
