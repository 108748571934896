import React from "react";

export const Conversation = () => {
  return (
    <div className="d-flex align-items-center border-bottom px-3 py-2 hoverable">
      <img
        src="http://placekitten.com/50/50"
        alt="kitten"
        className="rounded-circle me-3"
        style={{ objectFit: "cover", width: "3rem", height: "3rem" }}
      />
      <div>
        <h6 className="fw-bold text-dark fs-18 mb-0">Jane Lee</h6>
        <p className="mb-0 fs-16 truncate" style={{ maxWidth: "calc(100% - 5rem)" }}>
          Hi Jane, Thanks for booking my place...
        </p>
        <small className="text-muted fs-10">Response time: 1 hour</small>
      </div>
    </div>
  );
};
