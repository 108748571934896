// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline-btn {
  border-radius: 1.875rem;
  border: 1px solid #d5d6d9;
  background: #fff;
  width: 5.25rem;
  height: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  font-size: 0.75rem;
  margin-inline: 0.5rem;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/timeline/Timeline.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,uBAAuB;EACvB,kBAAkB;EAClB,qBAAqB;EACrB,gBAAgB;AAClB","sourcesContent":[".timeline-btn {\n  border-radius: 1.875rem;\n  border: 1px solid #d5d6d9;\n  background: #fff;\n  width: 5.25rem;\n  height: 2rem;\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n  justify-content: center;\n  font-size: 0.75rem;\n  margin-inline: 0.5rem;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
