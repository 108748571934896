// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address-input {
  border-radius: 0.875rem;
  border: 1px solid #e5e5e5;
  background: #fff;
  box-shadow: 0px 2px 17px 2px rgba(192, 192, 192, 0.17);
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  gap: 1.25rem;
}

.address-input input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
}

.map-container {
  height: 400px;
  width: 100%;
  border-radius: 0.875rem;
}

.address-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  border-radius: 0.875rem;
  box-shadow: 0px 2px 17px 2px rgba(192, 192, 192, 0.17);
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 20rem;
  transition: all 0.3s ease;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-1rem);
}

.address-suggestions.active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0);
}

.address-suggestions .address-suggestion {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  cursor: pointer;
  padding: 1rem 1.25rem;
  transition: all 0.3s ease;
}

.address-suggestions .address-suggestion:hover {
  background: #f5f5f5;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/CreateListingsModal/SpaceLocation/SpaceLocation.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,gBAAgB;EAChB,sDAAsD;EACtD,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,gBAAgB;EAChB,uBAAuB;EACvB,sDAAsD;EACtD,UAAU;EACV,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;EACzB,UAAU;EACV,oBAAoB;EACpB,4BAA4B;AAC9B;;AAEA;EACE,UAAU;EACV,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".address-input {\n  border-radius: 0.875rem;\n  border: 1px solid #e5e5e5;\n  background: #fff;\n  box-shadow: 0px 2px 17px 2px rgba(192, 192, 192, 0.17);\n  display: flex;\n  align-items: center;\n  padding: 1rem 1.25rem;\n  gap: 1.25rem;\n}\n\n.address-input input {\n  border: none;\n  outline: none;\n  width: 100%;\n  font-size: 1rem;\n  font-weight: 500;\n}\n\n.map-container {\n  height: 400px;\n  width: 100%;\n  border-radius: 0.875rem;\n}\n\n.address-suggestions {\n  position: absolute;\n  top: 100%;\n  left: 0;\n  width: 100%;\n  background: #fff;\n  border-radius: 0.875rem;\n  box-shadow: 0px 2px 17px 2px rgba(192, 192, 192, 0.17);\n  z-index: 1;\n  overflow-x: hidden;\n  overflow-y: auto;\n  max-height: 20rem;\n  transition: all 0.3s ease;\n  opacity: 0;\n  pointer-events: none;\n  transform: translateY(-1rem);\n}\n\n.address-suggestions.active {\n  opacity: 1;\n  pointer-events: all;\n  transform: translateY(0);\n}\n\n.address-suggestions .address-suggestion {\n  display: flex;\n  align-items: center;\n  gap: 1.25rem;\n  cursor: pointer;\n  padding: 1rem 1.25rem;\n  transition: all 0.3s ease;\n}\n\n.address-suggestions .address-suggestion:hover {\n  background: #f5f5f5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
