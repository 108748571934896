import { Country, State } from "country-state-city";
import React, { useMemo, useState } from "react";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron.svg";
import { ReactComponent as ClosePrimary } from "../../../assets/icons/close-primary.svg";
import { FormInput } from "../../ui/form-input/FormInput";
import { UncutModal } from "../../ui/modal/modal";

export const VatIdModal = ({ id }) => {
  const [vatform, setVatform] = useState({
    country: "",
    vatId: "",
    name: "",
    street: "",
    apt: "",
    city: "",
    state: "",
    zip: "",
    isoCode: "",
  });

  const [error, setError] = useState({
    country: "",
    vatId: "",
    name: "",
    street: "",
    apt: "",
    city: "",
    state: "",
    zip: "",
    isoCode: "",
  });

  const [dropdown, setDropdown] = useState(false);

  const [dropdown2, setDropdown2] = useState(false);

  const options = useMemo(() => Country.getAllCountries(), []);

  const stateOptions = useMemo(() => {
    if (vatform.isoCode) {
      return State.getStatesOfCountry(vatform.isoCode);
    } else {
      return [];
    }
  }, [vatform.isoCode]);

  const handleVatFormChange = (e, type) => {
    let value = e.target.value;

    if (value === "") {
      setError({
        ...error,
        [type]: `${type === "vatId" ? "VAT ID" : type} is required`,
      });
    } else {
      setError({
        ...error,
        [type]: "",
      });
    }

    setVatform({ ...vatform, [type]: value });
  };

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">VAT ID</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <p className="mb-0 mt-2 mb-4" style={{ width: "25em" }}>
          If you are registered with the European Commission, verification may
          take up to 48 hours. We’ll send you an email when its finished.
        </p>
        <div className="custom-form-select my-3">
          <div
            className="form-select-btn"
            onClick={() => {
              setDropdown(!dropdown);
            }}
          >
            <span className="fw-500 me-2" style={{ whiteSpace: "nowrap" }}>
              {vatform.country ? vatform.country : "Country / Region"}
            </span>
            <Chevron />
          </div>
          <div className={`form-select-content ${dropdown ? "show" : ""}`}>
            {options.map((country, index) => {
              return (
                <div
                  className="form-select-item"
                  key={index}
                  onClick={() => {
                    setVatform({
                      ...vatform,
                      country: country.name,
                      isoCode: country.isoCode,
                    });
                    setDropdown(false);
                  }}
                >
                  <label>{country.name}</label>
                </div>
              );
            })}
          </div>
          {error.country && (
            <p className="text-danger fs-14 fw-500">{error.country}</p>
          )}
        </div>
        <div className="my-4">
          <FormInput
            name="vatId"
            placeholder="Add VAT ID Number"
            value={vatform.vatId}
            required
            type="number"
            onChange={(e) => handleVatFormChange(e, "vatId")}
          />
          {error.vatId && (
            <p className="text-danger fs-14 fw-500">{error.vatId}</p>
          )}
        </div>
        <div className="my-4">
          <FormInput
            name="name"
            placeholder="Name on registration"
            value={vatform.name}
            required
            type="text"
            onChange={(e) => setVatform({ ...vatform, name: e.target.value })}
          />
          {error.name && (
            <p className="text-danger fs-14 fw-500">{error.name}</p>
          )}
        </div>
        <h5 className="fw-600 fs-18 mb-0 mt-4">Address</h5>
        <div className="my-4">
          <FormInput
            name="street"
            placeholder="Street address"
            value={vatform.street}
            required
            type="text"
            onChange={(e) => handleVatFormChange(e, "street")}
          />
          {error.street && (
            <p className="text-danger fs-14 fw-500">{error.street}</p>
          )}
        </div>
        <div className="my-4">
          <FormInput
            name="apt"
            placeholder="Apt, suite, (optional)"
            value={vatform.apt}
            type="text"
            onChange={(e) => handleVatFormChange(e, "apt")}
          />
        </div>
        <div className="my-4">
          <FormInput
            name="city"
            placeholder="City"
            value={vatform.city}
            required
            type="text"
            onChange={(e) => handleVatFormChange(e, "city")}
          />
          {error.city && (
            <p className="text-danger fs-14 fw-500">{error.city}</p>
          )}
        </div>
        <div className="custom-form-select my-3">
          <div
            className="form-select-btn"
            onClick={() => {
              setDropdown2(!dropdown2);
            }}
          >
            <span className="fw-500 me-2" style={{ whiteSpace: "nowrap" }}>
              {vatform.state ? vatform.state : "Province or region"}
            </span>
            <Chevron />
          </div>
          <div className={`form-select-content ${dropdown2 ? "show" : ""}`}>
            {stateOptions.map((state, index) => {
              return (
                <div
                  className="form-select-item"
                  key={index}
                  onClick={() => {
                    setVatform({ ...vatform, state: state.name });
                    setDropdown2(false);
                  }}
                >
                  <label>{state.name}</label>
                </div>
              );
            })}
          </div>
          {error.state && (
            <p className="text-danger fs-14 fw-500">{error.state}</p>
          )}
        </div>
        <div className="my-4">
          <FormInput
            name="zip"
            placeholder="ZIP code"
            value={vatform.zip}
            required
            type="text"
            onChange={(e) => handleVatFormChange(e, "zip")}
          />
          {error.zip && <p className="text-danger fs-14 fw-500">{error.zip}</p>}
        </div>
      </div>
      <div className="modal-footer d-flex align-items-center px-4">
        <button
          type="button"
          className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Add
        </button>
      </div>
    </UncutModal>
  );
};
