import React, { useState } from "react";
import { ReactComponent as ClosePrimary } from "../../../assets/icons/close-primary.svg";
import { passwordValidation } from "../../../utils/validation";
import { FormInput } from "../../ui/form-input/FormInput";
import { UncutModal } from "../../ui/modal/modal";

export const EditPasswordModal = ({ id }) => {
  const [passwordForm, setPasswordForm] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "currentPassword":
        if (!value) {
          setError((prevState) => ({
            ...prevState,
            currentPassword: "Current password is required",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            currentPassword: "",
          }));
        }
        setPasswordForm((prevState) => ({
          ...prevState,
          currentPassword: value,
        }));
        break;
      case "newPassword":
        if (!value) {
          setError((prevState) => ({
            ...prevState,
            newPassword: "New password is required",
          }));
        } else if (passwordValidation(value)) {
          setError((prevState) => ({
            ...prevState,
            newPassword: passwordValidation(value),
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            newPassword: "",
          }));
        }

        setPasswordForm((prevState) => ({
          ...prevState,
          newPassword: value,
        }));
        break;
      case "confirmPassword":
        if (!value) {
          setError((prevState) => ({
            ...prevState,
            confirmPassword: "Confirm password is required",
          }));
        } else if (passwordForm.newPassword !== value) {
          setError((prevState) => ({
            ...prevState,
            confirmPassword: "Passwords do not match",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            confirmPassword: "",
          }));
        }

        setPasswordForm((prevState) => ({
          ...prevState,
          confirmPassword: value,
        }));
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      passwordForm.currentPassword &&
      passwordForm.newPassword &&
      passwordForm.confirmPassword
    ) {
      console.log(passwordForm);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <UncutModal id={id} scrollable={true} right={true}>
        <div className="modal-header">
          <h5 className="modal-title fs-22 fw-600">Password</h5>
          <button
            type="button"
            className="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            id={`close-${id}`}
          >
            <ClosePrimary />
          </button>
        </div>
        <div className="modal-body px-4">
          <p className="mb-0 mt-2 mb-4" style={{ width: "25em" }}>
            This is the name on your travel document, which could be a license
            or a passport.
          </p>
          <div className="my-4">
            <FormInput
              name="currentPassword"
              placeholder="Current Password"
              value={passwordForm.currentPassword}
              required
              type="password"
              onChange={(e) => handlePasswordChange(e)}
            />
            {error.currentPassword && (
              <p className="text-danger">{error.currentPassword}</p>
            )}
          </div>
          <div className="my-4">
            <FormInput
              name="newPassword"
              placeholder="New Password"
              value={passwordForm.newPassword}
              required
              type="password"
              onChange={(e) => handlePasswordChange(e)}
            />
            {error.newPassword && (
              <p className="text-danger">{error.newPassword}</p>
            )}
          </div>
          <div className="my-4">
            <FormInput
              name="confirmPassword"
              placeholder="Confirm Password"
              value={passwordForm.confirmPassword}
              required
              type="password"
              onChange={(e) => handlePasswordChange(e)}
            />
            {error.confirmPassword && (
              <p className="text-danger">{error.confirmPassword}</p>
            )}
          </div>
        </div>
        <div className="modal-footer d-flex align-items-center px-4">
          <button
            type="button"
            className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary fw-600 flex-grow-1 fs-20"
            data-bs-dismiss="modal"
            disabled={
              passwordForm.currentPassword ||
              passwordForm.newPassword ||
              passwordForm.confirmPassword
            }
          >
            Save
          </button>
        </div>
      </UncutModal>
    </form>
  );
};
