import React, { useEffect, useState } from "react";
import  AddCircleIcon  from "../../../../assets/icons/add-circle.svg";
import  RemoveCircleIcon  from "../../../../assets/icons/remove-circle.svg";
import "./SpaceAmenities.css";

export const SpaceAmenities = ({ amenities, listingForm, setListingForm }) => {
  const [selectedAmenities, setSelectedAmenities] = useState([]);

  useEffect(() => {
    setSelectedAmenities(
      amenities.map((amenity) => {
        return { ...amenity, quantity: 0 };
      })
    );
  }, [amenities]);

  const handleClick = (type, amenity) => {
    switch (type) {
      case "subtract":
        setSelectedAmenities(
          selectedAmenities.map((item) => {
            if (item.id === amenity.id) {
              return { ...item, quantity: item.quantity - 1 };
            }
            return item;
          })
        );
        handleChange();
        break;
      case "add":
        setSelectedAmenities(
          selectedAmenities.map((item) => {
            if (item.id === amenity.id) {
              return { ...item, quantity: item.quantity + 1 };
            }
            return item;
          })
        );
        handleChange();
        break;
      default:
        break;
    }
  };

  const handleChange = () => {
    const finalAmenities = selectedAmenities.filter(
      (amenity) => amenity.quantity > 0
    );
    setListingForm({ ...listingForm, amenities: finalAmenities });
  };

  return (
    <div className="row g-0 justify-content-center mt-4">
      <div className="col-12 col-md-5">
        <h5 className="mb-4 text-center fs-36 fw-600">
          Let’s start with space amenities
        </h5>
        <p className="mb-3 fw-500 fs-16">
          How many people can stay at your place?
        </p>
        <div className="row g-0">
          {selectedAmenities.map((amenity) => {
            return (
              <div
                className="col-12 border-bottom row g-0 pb-2 mb-4"
                key={amenity.id}
              >
                <div className="col col-md">
                  <p className="mb-0 fw-500">{amenity.name}</p>
                </div>
                <div className="col-4 col-md-2 d-flex gap-3 align-items-center">
                  <img src={RemoveCircleIcon} alt="remove" className="pointer" onClick={() => {handleClick("subtract", amenity)}}/>
                  <input
                    type="number"
                    value={amenity.quantity}
                    min={0}
                    className="text-center count-input"
                    onChange={(e) => {
                      setSelectedAmenities((prevSelectedAmenities) => {
                        return prevSelectedAmenities.map((item) => {
                          if (item.id === amenity.id) {
                            return { ...item, quantity: e.target.value };
                          }
                          return item;
                        });
                      });
                      handleChange();
                    }}
                  />
                  <img src={AddCircleIcon} alt="add" className="pointer" onClick={() => {handleClick("add", amenity)}}/>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
