// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  background: #f9f9f9;
}

.social-link{
  transition: all 0.3s ease;
}

.social-link:hover{
  transform: scale(1.1);
}

.footer-link{
  transition: all 0.3s ease;
  width: -moz-fit-content;
  width: fit-content;
}

.footer-link::after{
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #c7c7c7;
  transition: width 0.3s;
}

.footer-link:hover::after{
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/main-footer/main-footer.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,QAAQ;EACR,WAAW;EACX,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".footer-container {\n  background: #f9f9f9;\n}\n\n.social-link{\n  transition: all 0.3s ease;\n}\n\n.social-link:hover{\n  transform: scale(1.1);\n}\n\n.footer-link{\n  transition: all 0.3s ease;\n  width: fit-content;\n}\n\n.footer-link::after{\n  content: \"\";\n  display: block;\n  width: 0;\n  height: 2px;\n  background: #c7c7c7;\n  transition: width 0.3s;\n}\n\n.footer-link:hover::after{\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
