import React, { useEffect, useState } from "react";
import Chevron from "../../../assets/icons/chevron-primary.svg";
import "./calendar.css";

export const UncutCalendar = ({ checkIn, checkOut, setDate }) => {
  const [date, setCurrentDate] = useState(new Date());

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const getFirstDayOfMonth = (month, year) => {
    return new Date(year, month, 1).getDay();
  };

  const getMonth = () => {
    return date.toLocaleDateString("en-US", { month: "long" });
  };

  const getYear = () => {
    return date.toLocaleDateString("en-US", { year: "numeric" });
  };

  const getMonthAndYear = (month, year) => {
    return `${getMonth(month)} ${getYear(year)}`;
  };

  const getDays = () => {
    let days = [];
    let previousMonth = [];
    let currentMonth = [];
    let nextMonth = [];
    let firstDayOfMonth = getFirstDayOfMonth(
      date.getMonth(),
      date.getFullYear()
    );
    let daysInMonth = getDaysInMonth(date.getMonth() + 1, date.getFullYear());
    let daysInPreviousMonth = getDaysInMonth(
      date.getMonth(),
      date.getFullYear()
    );

    for (let i = 0; i < firstDayOfMonth; i++) {
      previousMonth.push(daysInPreviousMonth - i);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      currentMonth.push(i);
    }

    for (
      let i = 1;
      i <= 35 - (previousMonth.length + currentMonth.length);
      i++
    ) {
      nextMonth.push(i);
    }

    days = [...previousMonth.reverse(), ...currentMonth, ...nextMonth];

    return days;
  };

  const handleDateClick = (e) => {
    let day = e.target.innerText;
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let selectedDate = new Date(`${month}/${day}/${year}`);

    if (setDate) {
      setDate(selectedDate);
    }
  };

  const handlePrevMonthClick = () => {
    setCurrentDate(new Date(date.setMonth(date.getMonth() - 1)));
  };

  const handleNextMonthClick = () => {
    setCurrentDate(new Date(date.setMonth(date.getMonth() + 1)));
  };

  useEffect(() => {
    if (checkIn && checkOut) {
      setCurrentDate(checkIn);
    }
  }, [checkIn, checkOut]);

  return setDate ? (
    <div className="calendar">
      <div className={`d-flex align-items-center my-2 justify-content-between`}>
        <span
          className="fw-600 fs-14 text-muted"
          onClick={handlePrevMonthClick}
        >
          <img src={Chevron} alt="chevron" />
        </span>

        <h5 className="fw-600 fs-16 mb-0 text-center">
          {getMonthAndYear(date.getMonth() + 1, date.getFullYear())}
        </h5>

        <span
          className="fw-600 fs-14 text-muted"
          onClick={handleNextMonthClick}
        >
          <img
            src={Chevron}
            alt="chevron"
            style={{ transform: "rotate(180deg)" }}
          />
        </span>
      </div>

      <div className="days-of-week">
        <span className="fw-600 fs-14">Su</span>
        <span className="fw-600 fs-14">Mo</span>
        <span className="fw-600 fs-14">Tu</span>
        <span className="fw-600 fs-14">We</span>
        <span className="fw-600 fs-14">Th</span>
        <span className="fw-600 fs-14">Fri</span>
        <span className="fw-600 fs-14">Sa</span>
      </div>
      <div className="dates-of-month">
        {getDays().map((day, index) => {
          let dayIsInMonth =
            index >= getFirstDayOfMonth(date.getMonth(), date.getFullYear()) &&
            index <
              getFirstDayOfMonth(date.getMonth(), date.getFullYear()) +
                getDaysInMonth(date.getMonth() + 1, date.getFullYear());

          let dayIsCheckIn = day === checkIn?.getDate() ?? false;
          let dayIsCheckOut = day === checkOut?.getDate() ?? false;
          let dayIsBetweenCheckInAndCheckOut =
            (day > checkIn?.getDate() && day < checkOut?.getDate()) ?? false;
          let selectedDate =
            (day === checkIn?.getDate() || day === checkOut?.getDate()) ??
            false;

          return (
            <span
              key={index}
              className={`fs-14 ${dayIsInMonth ? "text-dark" : "text-muted"} ${
                !setDate && dayIsCheckIn ? "text-muted strikethrough" : ""
              } ${!setDate && dayIsCheckOut ? "text-muted strikethrough" : ""}
              ${
                !setDate && dayIsBetweenCheckInAndCheckOut
                  ? "text-muted strikethrough"
                  : ""
              } 
              ${setDate && selectedDate && dayIsInMonth ? "selected" : ""}`}
              onClick={handleDateClick}
            >
              {day}
            </span>
          );
        })}
      </div>
    </div>
  ) : (
    <div className="calendar">
      <div className={`d-flex align-items-center my-2 justify-content-center`}>
        <h5 className="fw-600 fs-16 mb-0 text-center">
          {getMonthAndYear(date.getMonth() + 1, date.getFullYear())}
        </h5>
      </div>

      <div className="days-of-week">
        <span className="fw-600 fs-14">Su</span>
        <span className="fw-600 fs-14">Mo</span>
        <span className="fw-600 fs-14">Tu</span>
        <span className="fw-600 fs-14">We</span>
        <span className="fw-600 fs-14">Th</span>
        <span className="fw-600 fs-14">Fri</span>
        <span className="fw-600 fs-14">Sa</span>
      </div>
      <div className="dates-of-month">
        {getDays().map((day, index) => {
          let dayIsInMonth =
            index >= getFirstDayOfMonth(date.getMonth(), date.getFullYear()) &&
            index <
              getFirstDayOfMonth(date.getMonth(), date.getFullYear()) +
                getDaysInMonth(date.getMonth() + 1, date.getFullYear());

          let dayIsCheckIn = day === checkIn?.getDate() ?? false;
          let dayIsCheckOut = day === checkOut?.getDate() ?? false;
          let dayIsBetweenCheckInAndCheckOut =
            (day > checkIn?.getDate() && day < checkOut?.getDate()) ?? false;

          return (
            <span
              key={index}
              className={`fs-14 ${dayIsInMonth ? "text-dark" : "text-muted"} ${
                dayIsCheckIn && "text-muted strikethrough"
              } ${dayIsCheckOut && "text-muted strikethrough"}
              ${dayIsBetweenCheckInAndCheckOut && "text-muted strikethrough"}`}
              onClick={handleDateClick}
            >
              {day}
            </span>
          );
        })}
      </div>
    </div>
  );
};
