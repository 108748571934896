// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-settings {
  margin-top: 1.2em;
  margin-inline: 7em;
}

.account-settings .nav-link {
  text-align: start;
  max-width: 20em;
  min-height: 5em;
  border-radius: 0.875rem;
  border: 1px solid #d5d6d9;
  background: #fff;
  margin-bottom: 0.8em;
}

.account-settings .nav-link.active {
  border: 1px solid #4c35de;
  background: #fff;
}

.account-settings .nav-link.active * {
  color: #4c35de !important;
}

.account-settings .tab-content {
  flex: 1 1;
}

@media screen and (max-width: 48em) {
  .account-settings {
    margin-inline: 2em;
  }

  .account-settings .nav-link {
    max-width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Main/AccountSettings/AccountSettings.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,eAAe;EACf,uBAAuB;EACvB,yBAAyB;EACzB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,SAAO;AACT;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".account-settings {\n  margin-top: 1.2em;\n  margin-inline: 7em;\n}\n\n.account-settings .nav-link {\n  text-align: start;\n  max-width: 20em;\n  min-height: 5em;\n  border-radius: 0.875rem;\n  border: 1px solid #d5d6d9;\n  background: #fff;\n  margin-bottom: 0.8em;\n}\n\n.account-settings .nav-link.active {\n  border: 1px solid #4c35de;\n  background: #fff;\n}\n\n.account-settings .nav-link.active * {\n  color: #4c35de !important;\n}\n\n.account-settings .tab-content {\n  flex: 1;\n}\n\n@media screen and (max-width: 48em) {\n  .account-settings {\n    margin-inline: 2em;\n  }\n\n  .account-settings .nav-link {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
