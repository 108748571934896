import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AmericanExpress } from "../../../../assets/icons/american-express.svg";
import { ReactComponent as ChevronLG } from "../../../../assets/icons/chevron-lg.svg";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";
import { ReactComponent as MasterCard } from "../../../../assets/icons/mastercard.svg";
import { ReactComponent as Paypal } from "../../../../assets/icons/paypal.svg";
import { ReactComponent as Star } from "../../../../assets/icons/star.svg";
import { ReactComponent as TrophySm } from "../../../../assets/icons/trophy-sm.svg";
import { ReactComponent as Visa } from "../../../../assets/icons/visa.svg";
import { useListingContext } from "../../../../context/ListingContext";
import { useReservationFormContext } from "../../../../context/ReservationFormContext";
import { useSpinnerContext } from "../../../../context/SpinnerContext";
import { toastService } from "../../../../lib/toast.service";
import { reserveSpace } from "../../../../services/reservations.service";
import { getPaymentMethods } from "../../../../services/settings.service";
import "./ConfirmAndPay.css";

export const ConfirmAndPay = () => {
  const navigate = useNavigate();

  const [dropdown, setDropdown] = useState(false);

  const [checkoutForm, setCheckoutForm] = useState({
    paymentType: "pay-in-full",
    card: null,
  });

  const { listing, setListing } = useListingContext();

  const { reservationForm, setReservationForm } = useReservationFormContext();

  const { setSpinner } = useSpinnerContext();

  const [paymentMethods, setPaymentMethods] = useState([]);

  // const paymentTypes = [
  //   {
  //     id: 1,
  //     name: "Pay in full",
  //     description: "Pay the total ($1,575) now and you’re all set.",
  //     value: "pay-in-full",
  //   },
  //   {
  //     id: 2,
  //     name: "Pay part now, part later",
  //     description: "$600 due today, $970 on Sep 7, 2023. No extra fees.",
  //     value: "pay-part-now-part-later",
  //   },
  // ];

  const fetchPaymentMethods = useCallback(async () => {
    try {
      setSpinner(true);
      let res = await getPaymentMethods();
      setPaymentMethods(res.result);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.log(error);
    }
  }, [setSpinner]);

  const reserveASpace = useCallback(async () => {
    try {
      setSpinner(true);
      const res = await reserveSpace({
        ...reservationForm,
        checkout_datetime: new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        })
          .format(reservationForm?.checkout_datetime)
          .replace(/\//g, "-")
          .replace(/, /g, ":")
          .split(" ")[0],
        checkin_datetime: new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        })
          .format(reservationForm?.checkin_datetime)
          .replace(/\//g, "-")
          .replace(/, /g, ":")
          .split(" ")[0],
      });
      setSpinner(false);
      if (res.status === 201 || res.status === 200) {
        toastService.success(res.message);
        navigate("/find-space/reservation-success/" + reservationForm.space_id);
        setReservationForm({
          checkin_datetime: new Date(),
          checkout_datetime: new Date(),
          payment_method_id: "",
          space_id: "",
        });
        setListing(null);
        return;
      }
    } catch (error) {
      setSpinner(false);
      console.log(error);
    }
  }, [navigate, reservationForm, setListing, setReservationForm, setSpinner]);

  useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  return (
    <main className="bg-white h-100">
      <div className="container py-5">
        <h5 className="mb-5 fw-600 fs-30">
          <ChevronLG className="me-3 pointer" onClick={() => navigate(-1)} />
          Confirm and pay
        </h5>
        <div className="row g-0 justify-content-between">
          <div className="col-md-6">
            <div className="border-bottom mb-4 pb-4">
              <h5 className="mb-4 fw-600 fs-24">Your Booking</h5>
              <div className="d-flex align-items-center gap-4 mb-4 justify-content-between">
                <div>
                  <h6 className="mb-0 fw-600">Dates</h6>
                  <p className="mb-0 text-muted fw-500">
                    {new Intl.DateTimeFormat("en-US", {
                      month: "short",
                      day: "numeric",
                    }).format(new Date(reservationForm?.checkin_datetime))}
                    {" - "}
                    {new Intl.DateTimeFormat("en-US", {
                      month: "short",
                      day: "numeric",
                    }).format(new Date(reservationForm?.checkout_datetime))}
                  </p>
                </div>
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/find-space/space/${listing?.id}`);
                  }}
                  className="text-decoration-none text-primary fw-500"
                >
                  Edit
                </a>
              </div>
              <div className="d-flex align-items-center gap-4 mb-4 justify-content-between">
                <div>
                  <h6 className="mb-0 fw-600">Subscription</h6>
                  <p className="mb-0 text-muted fw-500">
                    {listing?.stay_duration}
                  </p>
                </div>
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/find-space/space/${listing?.id}`);
                  }}
                  className="text-decoration-none text-primary fw-500"
                >
                  Edit
                </a>
              </div>
              <div className="d-flex align-items-center gap-4 mb-4 justify-content-between">
                <div>
                  <h6 className="mb-0 fw-600">Hours</h6>
                  <p className="mb-0 text-muted fw-500">
                    {reservationForm?.checkout_datetime?.getHours() -
                      reservationForm?.checkin_datetime?.getHours()}{" "}
                    hours
                  </p>
                </div>
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/find-space/space/${listing?.id}`);
                  }}
                  className="text-decoration-none text-primary fw-500"
                >
                  Edit
                </a>
              </div>
            </div>
            {/* <div className="row g-0 mt-5 border-bottom mb-4 pb-5">
              <h5 className="mb-4 fw-600 fs-24">Choose how to pay</h5>
              {paymentTypes.map((pay, index) => {
                return (
                  <div className="col-12" key={index}>
                    <label
                      className={`my-2 rounded-4 d-flex justify-content-between align-items-center p-4 gap-2 border ${
                        pay.value === checkoutForm.paymentType
                          ? "border-primary"
                          : "border-gray"
                      }`}
                      style={{ transition: "all 0.3s ease" }}
                      htmlFor={pay.value}
                    >
                      {pay.icon}
                      <div className="form-label fw-500 mb-0 pointer w-75">
                        <h5 className="mb-0 fw-500 fs-18">{pay.name}</h5>
                        <p className="mb-0 fs-14 text-muted">
                          {pay.description}
                        </p>
                      </div>
                      <input
                        type="radio"
                        id={pay.value}
                        name="payment-type"
                        value={pay.value}
                        className="d-none"
                        onChange={(e) =>
                          setCheckoutForm({
                            ...checkoutForm,
                            paymentType: e.target.value,
                          })
                        }
                        checked={pay.value === checkoutForm.paymentType}
                      />
                      <label
                        htmlFor={pay.value}
                        className="form-checkbox-rounded"
                      ></label>
                    </label>
                  </div>
                );
              })}
            </div> */}
            <div className="row g-0 mt-5 border-bottom mb-4 pb-5">
              <div className="d-flex align-items-center gap-4 mb-4 justify-content-between">
                <h5 className="mb-0 fw-600 fs-24">Pay with</h5>
                <div className="d-flex align-items-center gap-2">
                  <Visa />
                  <MasterCard />
                  <Paypal />
                  <AmericanExpress />
                </div>
              </div>
              <div className="custom-form-select mb-3">
                <div
                  className="form-select-btn border-1"
                  onClick={() => {
                    setDropdown(true);
                    window.addEventListener("mousedown", (e) => {
                      if (!e.target.classList.contains("form-select-item")) {
                        setDropdown(false);
                      }
                    });
                  }}
                >
                  <span
                    className="fw-500 me-2"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {checkoutForm.card?.brand === "Visa" ? (
                      <Visa />
                    ) : checkoutForm.card?.brand === "Mastercard" ? (
                      <MasterCard />
                    ) : checkoutForm.card?.brand === "American Express" ? (
                      <AmericanExpress />
                    ) : null}
                    &nbsp;&nbsp;
                    {checkoutForm.card?.card_number
                      ? "****" + checkoutForm.card?.card_number
                      : "Select card"}
                  </span>
                  <Chevron />
                </div>
                <div
                  className={`form-select-content ${dropdown ? "show" : ""}`}
                >
                  {paymentMethods.map((paymentMethod, index) => {
                    return (
                      <div
                        className="form-select-item"
                        key={index}
                        onClick={() => {
                          setCheckoutForm({
                            ...checkoutForm,
                            card: paymentMethod,
                          });
                          setReservationForm({
                            ...reservationForm,
                            payment_method_id: paymentMethod.id,
                          });
                          setDropdown(false);
                        }}
                      >
                        {paymentMethod.brand === "Visa" ? (
                          <Visa style={{ pointerEvents: "none" }} />
                        ) : paymentMethod.brand === "Mastercard" ? (
                          <MasterCard style={{ pointerEvents: "none" }} />
                        ) : paymentMethod.brand === "American Express" ? (
                          <AmericanExpress style={{ pointerEvents: "none" }} />
                        ) : null}{" "}
                        &nbsp;&nbsp;
                        <label style={{ pointerEvents: "none" }}>
                          {paymentMethod.brand} ****{paymentMethod.card_number}
                        </label>
                      </div>
                    );
                  })}
                  <div
                    className="form-select-item button"
                    onClick={() => {
                      navigate("/main/settings#payment");
                    }}
                  >
                    <button
                      className="btn border px-3 py-2 fw-600"
                      style={{ pointerEvents: "none" }}
                      type="button"
                    >
                      Add new card
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-bottom mb-4 pt-5 pb-4">
              <h5 className="mb-4 fw-600 fs-24">Required for booking</h5>
              <div className="d-flex align-items-center gap-4 mb-4 justify-content-between">
                <div>
                  <h6 className="mb-0 fw-600">Message the Host</h6>
                  <p className="text-muted mb-0">
                    Let the Host know why you're traveling and when you'll check
                    in.
                  </p>
                </div>
                <button className="btn btn-primary-outline px-3 py-2 fw-600">
                  Add
                </button>
              </div>
              <div className="d-flex align-items-center gap-4 mb-4 justify-content-between">
                <div>
                  <h6 className="mb-0 fw-600">Purpose of Booking</h6>
                  <p className="text-muted mb-0">
                    The US Department of Treasury requires this info from
                    travelers.
                  </p>
                </div>
                <button className="btn btn-primary-outline px-3 py-2 fw-600">
                  Add
                </button>
              </div>
            </div>
            <div className="border-bottom mb-4 pt-5 pb-4">
              <h5 className="mb-4 fw-600 fs-24">Cancellation policy</h5>
              <p className="mb-0 text-muted">
                <b>Free cancellation before Sept 15.</b> Cancel before check-in
                on Sep 20 for a partial refund.
              </p>
            </div>
            <div className="mb-4 pb-4">
              <p className="mb-0 text-muted">
                By selecting the button below, I agree to the{" "}
                <a href="/" className="fw-bold text-dark">
                  Host's House Rules, Ground rules for guests, Spacegeni's
                  Rebooking and Refund Policy,
                </a>{" "}
                and that Spacegeni can{" "}
                <a href="/" className="fw-bold text-dark">
                  charge my payment method
                </a>{" "}
                if I’m responsible for damage.
              </p>
              <button
                className="btn btn-primary px-5 py-2 mt-5 fw-600"
                onClick={reserveASpace}
              >
                Confirm and pay
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card rounded-4 py-3 mb-4">
              <div className="card-body px-0">
                <div className="border-bottom mb-4 px-4 d-flex gap-4 pb-3">
                  <img
                    src={
                      listing?.featured_image ||
                      "https://via.placeholder.com/80"
                    }
                    alt="space-look"
                    className="rounded-2"
                    width={80}
                    height={80}
                    style={{ objectFit: "cover" }}
                  />
                  <div>
                    <h5 className="mb-2 fw-600 fs-20">
                      {listing?.title || "Space title"}
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center gap-2">
                        <p className="mb-0 fw-500 d-flex align-items-center">
                          <Star className="me-1" /> 4.89 (499 Reviews)
                        </p>
                        <span className="dot"></span>
                        <p className="mb-0 fw-500 fs-16">
                          <TrophySm className="me-1" />
                          Genihost
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 className="mb-4 fw-600 fs-20 px-4">Price details</h5>
                <div className="border-bottom mb-4 px-4">
                  <div className="d-flex align-items-center gap-4 justify-content-between mb-4">
                    <p className="mb-0 fw-600 fs-16">
                      {"$" + listing?.price} x{" "}
                      {reservationForm?.checkout_datetime?.getHours() -
                        reservationForm?.checkin_datetime?.getHours()}{" "}
                      hours
                    </p>
                    <p className="mb-0 fw-600 fs-16">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(
                        listing?.price *
                          (reservationForm?.checkout_datetime?.getHours() -
                            reservationForm?.checkin_datetime?.getHours())
                      ) || 0}
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-4 justify-content-between mb-4">
                    <p className="mb-0 fw-600 fs-16">Service fee</p>
                    <p className="mb-0 fw-600 fs-16">$75</p>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-4 justify-content-between px-4">
                  <p className="mb-0 fw-600 fs-16">Total (USD)</p>
                  <p className="mb-0 fw-600 fs-16">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(
                      listing?.price *
                        (reservationForm?.checkout_datetime?.getHours() -
                          reservationForm?.checkin_datetime?.getHours()) +
                        75
                    ) || 0}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
