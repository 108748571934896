import { get, post } from "../lib/http.service";

const API_URL = process.env.REACT_APP_API_URL;

export const getReservations = async () => {
  try {
    const resp = await get(`${API_URL}/space/host-reservations/`);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const filterReservations = async (filter) => {
  try {
    const resp = await post(`${API_URL}/space/filter-reservations/`, filter);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const reserveSpace = async (reservation) => {
  try {
    const resp = await post(`${API_URL}/space/reserve-space/`, reservation);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
}

