import React from "react";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron.svg";
import { ReactComponent as ClosePrimary } from "../../../assets/icons/close-primary.svg";
import { UncutModal } from "../../ui/modal/modal";

export const EditListingsModal = ({
  id,
  feesandchargesId,
  lengthofstayId,
  amenitiesId,
  locationId,
  propertytypeId,
  checkinmethodId,
  listingSyncSettingsId,
  listingStatusId,
  cancellationPolicyId,
  rulesId,
  bookingDurationId,
}) => {
  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Edit Listing</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <div className="col-12 mt-2">
          <h5 className="mb-3 fw-500 fs-20">Pricing</h5>
          <div className="row g-0 border rounded-3 p-4">
            <div
              className="d-flex align-items-center justify-content-between mb-4 pointer"
              onClick={() => {
                document.getElementById(`close-${id}`).click();
              }}
              data-bs-toggle="modal"
              data-bs-target={`#${feesandchargesId}`}
            >
              <p className="mb-0 fw-500">Fees and Charges</p>
              <Chevron style={{ transform: "rotate(270deg)" }} />
            </div>
            <div
              className="d-flex align-items-center justify-content-between pointer"
              onClick={() => {
                document.getElementById(`close-${id}`).click();
              }}
              data-bs-toggle="modal"
              data-bs-target={`#${lengthofstayId}`}
            >
              <p className="mb-0 fw-500">Length-of-stay Discounts</p>
              <Chevron style={{ transform: "rotate(270deg)" }} />
            </div>
          </div>
        </div>
        <div className="col-12 mt-4">
          <h5 className="mb-3 fw-500 fs-20">Listing Settings</h5>
          <div className="row g-0 border rounded-3 p-4">
            <div
              className="col-12 d-flex align-items-center justify-content-between mb-4 pointer"
              onClick={() => {
                document.getElementById(`close-${id}`).click();
              }}
              data-bs-toggle="modal"
              data-bs-target={`#${amenitiesId}`}
            >
              <p className="mb-0 fw-500">Amenities</p>
              <Chevron style={{ transform: "rotate(270deg)" }} />
            </div>
            <div
              className="col-12 d-flex align-items-center justify-content-between mb-4 pointer"
              onClick={() => {
                document.getElementById(`close-${id}`).click();
              }}
              data-bs-toggle="modal"
              data-bs-target={`#${locationId}`}
            >
              <p className="mb-0 fw-500">Location</p>
              <Chevron style={{ transform: "rotate(270deg)" }} />
            </div>
            <div
              className="col-12 d-flex align-items-center justify-content-between mb-4 pointer"
              onClick={() => {
                document.getElementById(`close-${id}`).click();
              }}
              data-bs-toggle="modal"
              data-bs-target={`#${propertytypeId}`}
            >
              <p className="mb-0 fw-500">Property Type</p>
              <Chevron style={{ transform: "rotate(270deg)" }} />
            </div>
            <div
              className="col-12 d-flex align-items-center justify-content-between mb-4 pointer"
              onClick={() => {
                document.getElementById(`close-${id}`).click();
              }}
              data-bs-toggle="modal"
              data-bs-target={`#${checkinmethodId}`}
            >
              <p className="mb-0 fw-500">Check-in Method</p>
              <Chevron style={{ transform: "rotate(270deg)" }} />
            </div>
            <div
              className="col-12 d-flex align-items-center justify-content-between mb-4 pointer"
              onClick={() => {
                document.getElementById(`close-${id}`).click();
              }}
              data-bs-toggle="modal"
              data-bs-target={`#${listingSyncSettingsId}`}
            >
              <p className="mb-0 fw-500">Sync Settings</p>
              <Chevron style={{ transform: "rotate(270deg)" }} />
            </div>
            <div
              className="col-12 d-flex align-items-center justify-content-between gap-14 pointer"
              onClick={() => {
                document.getElementById(`close-${id}`).click();
              }}
              data-bs-toggle="modal"
              data-bs-target={`#${listingStatusId}`}
            >
              <p className="mb-0 fw-500">Listing Status</p>
              <Chevron style={{ transform: "rotate(270deg)" }} />
            </div>
          </div>
        </div>
        <div className="col-12 mt-4">
          <h5 className="mb-3 fw-500 fs-20">Booking Settings</h5>
          <div className="row g-0 border rounded-3 p-4">
            <div
              className="col-12 d-flex align-items-center justify-content-between mb-4 pointer"
              onClick={() => {
                document.getElementById(`close-${id}`).click();
              }}
              data-bs-toggle="modal"
              data-bs-target={`#${cancellationPolicyId}`}
            >
              <p className="mb-0 fw-500">Cancellation Policy</p>
              <Chevron style={{ transform: "rotate(270deg)" }} />
            </div>
            <div
              className="col-12 d-flex align-items-center justify-content-between mb-4 pointer"
              onClick={() => {
                document.getElementById(`close-${id}`).click();
              }}
              data-bs-toggle="modal"
              data-bs-target={`#${rulesId}`}
            >
              <p className="mb-0 fw-500">Rules</p>
              <Chevron style={{ transform: "rotate(270deg)" }} />
            </div>
            <div
              className="col-12 d-flex align-items-center justify-content-between pointer"
              onClick={() => {
                document.getElementById(`close-${id}`).click();
              }}
              data-bs-toggle="modal"
              data-bs-target={`#${bookingDurationId}`}
            >
              <p className="mb-0 fw-500">Booking Duration</p>
              <Chevron style={{ transform: "rotate(270deg)" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer px-4">
        <button
          type="button"
          className="btn btn-primary fw-600 w-100 fs-20"
          data-bs-dismiss="modal"
        >
          Done
        </button>
      </div>
    </UncutModal>
  );
};
