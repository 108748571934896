import { createContext, useContext, useEffect, useState } from "react";
import { storageService } from "../lib/storage.service";

export const SearchWizardContext = createContext({
  location: "",
  amenities: [],
  space_size: "",
  duration: "",
  profession: "",
});

export const SearchWizardProvider = ({ children }) => {
  const [searchWizard, setSearchWizard] = useState({
    location: "",
    amenities: [],
    space_size: "",
    duration: "",
    profession: "",
  });

  useEffect(() => {
    const searchWizard = storageService.getSearchWizard();
    setSearchWizard(searchWizard);
  }, []);

  return (
    <SearchWizardContext.Provider value={{ searchWizard, setSearchWizard }}>
      {children}
    </SearchWizardContext.Provider>
  );
};

export const useSearchWizardContext = () => {
  const context = useContext(SearchWizardContext);
  if (context === null) {
    throw new Error(
      "useSearchWizardContext must be used within a SearchWizardProvider"
    );
  }
  return context;
};
