import { loadStripe } from "@stripe/stripe-js";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as ClosePrimary } from "../../../assets/icons/close-primary.svg";
import { useSpinnerContext } from "../../../context/SpinnerContext";
import { useUserContext } from "../../../context/UserContext";
import { toastService } from "../../../lib/toast.service";
import { addPaymentMethod } from "../../../services/settings.service";
import { UncutModal } from "../../ui/modal/modal";
import "./AddPaymentMethodModal.css";

export const AddPaymentMethodModal = ({ id }) => {
  const [paymentType, setPaymentType] = useState("credit");

  const { setSpinner } = useSpinnerContext();

  const { user } = useUserContext();

  let cardNumber = useRef(null);
  let stripePromise = useRef(null);

  const handleStripe = useCallback(async () => {
    stripePromise.current = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    let stripe = await stripePromise.current;
    let stripeElement = stripe.elements();
    cardNumber.current = stripeElement.create("card", {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
          padding: "1rem",
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    });
    cardNumber.current.mount("#card-number");

    cardNumber.current.addEventListener("change", function (event) {
      var displayError = document.getElementById("card-errors");
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    });
  }, [stripePromise]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSpinner(true);
      let stripe = await stripePromise.current;
      stripe.createPaymentMethod({
        type: "card",
        card: cardNumber.current,
      });
      let { token } = await stripe.createToken(cardNumber.current);
      const resp = await addPaymentMethod({
        strip_token: token.id,
        card_id: token.card.id,
        brand: token.card.brand,
        exp_month: token.card.exp_month,
        exp_year: token.card.exp_year,
        card_number: token.card.last4,
        type: token.type,
        card_holder: user.first_name + " " + user.last_name,
      });
      setSpinner(false);
      if (resp.status === 201 || resp.status === 200) {
        toastService.success(resp.message);
        document.getElementById(`close-${id}`).click();
      }
    } catch (ex) {
      setSpinner(false);
      console.log(ex);
    }
  };

  useEffect(() => {
    handleStripe();
  }, [handleStripe]);

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Add Payment</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4" style={{ width: "30rem" }}>
        <div className="my-4 border border-2 rounded-4">
          <div className="d-flex align-items-center gap-2 border-bottom border-2 p-3">
            <input
              type="radio"
              id="credit"
              name="paymentType"
              value="credit"
              className="d-none"
              onChange={(e) => {
                setPaymentType(e.target.value);
              }}
              checked={paymentType === "credit"}
            />
            <label htmlFor="credit" className="form-checkbox-rounded"></label>{" "}
            <div>
              <h5 className="fw-bold text-dark fs-16 mb-0 fw-500">
                Credit or Debit Card
              </h5>
              <p className="text-muted fs-14 mb-0">
                Add a credit or debit card for payment.
              </p>
            </div>
          </div>
          <div id="card-number" style={{ padding: "1rem" }}></div>
          <div id="card-errors" role="alert"></div>
        </div>
      </div>
      <div className="modal-footer d-flex align-items-center px-4">
        <button
          type="button"
          className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </UncutModal>
  );
};
