import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron-primary.svg";
import "./AccountSettings.css";
import { LoginAndSecurity } from "./LoginAndSecurity/LoginAndSecurity";
import { Notifications } from "./Notifications/Notifications";
import { PaymentAndSecurity } from "./PaymentAndSecurity/PaymentAndSecurity";
import { PersonalInfo } from "./PersonalInfo/PersonalInfo";
import { Taxes } from "./Taxes/Taxes";

export const AccountSettings = () => {
  const navigate = useNavigate();

  const [navtabs, setNavtabs] = useState([
    {
      label: "Personal info",
      description: "Provide personal details and how we can reach you.",
      id: "v-pills-personal-info-tab",
      target: "#v-pills-personal-info",
      ariaControls: "v-pills-personal-info",
      ariaSelected: "true",
      active: true,
      component: <PersonalInfo />,
    },
    {
      label: "Login & security",
      description: "Update your password and secure your account.",
      id: "v-pills-login-security-tab",
      target: "#v-pills-login-security",
      ariaControls: "v-pills-login-security",
      ariaSelected: "false",
      active: false,
      component: <LoginAndSecurity />,
    },
    {
      label: "Payment & security",
      description: "Add or update your payment method.",
      id: "v-pills-payment-security-tab",
      target: "#v-pills-payment-security",
      ariaControls: "v-pills-payment-security",
      ariaSelected: "false",
      active: false,
      component: <PaymentAndSecurity />,
    },
    {
      label: "Taxes",
      description: "Manage taxpayer information and tax documents.",
      id: "v-pills-taxes-tab",
      target: "#v-pills-taxes",
      ariaControls: "v-pills-taxes",
      ariaSelected: "false",
      active: false,
      component: <Taxes />,
    },
    {
      label: "Notification",
      description:
        "Choose notification preferences and how you want to be contracted.",
      id: "v-pills-notification-tab",
      target: "#v-pills-notification",
      ariaControls: "v-pills-notification",
      ariaSelected: "false",
      active: false,
      component: <Notifications />,
    },
  ]);

  useEffect(() => {
    const hash = window.location.hash;
    const tab = hash.split("#")[1];
    if (tab) {
      setNavtabs((prev) =>
        prev.map((navtab) => {
          if (navtab.ariaControls.includes(tab)) {
            return { ...navtab, active: true };
          } else {
            return { ...navtab, active: false };
          }
        })
      );
    }
  }, []);

  return (
    <div className="account-settings mb-4">
      <a
        onClick={() => {
          let mobileNavTab = document.getElementById("v-pills-tab");
          let mobileNavTabContent =
            document.getElementById("v-pills-tabContent");

          if (!mobileNavTab.classList.contains("d-none")) {
            return navigate("/main/dashboard");
          }
          mobileNavTab.classList.toggle("d-none");
          mobileNavTabContent.classList.add("d-none");
        }}
        href="#!"
        className="text-decoration-none text-primary fw-600"
      >
        <Chevron className="me-2" /> Back
      </a>
      <h4 className="fw-600 text-dark fs-28 my-4">Account Settings</h4>
      <div className="d-flex align-items-start gap-4">
        <div
          className="nav flex-column nav-pills me-0 me-md-3"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          {navtabs.map((navtab) => {
            return (
              <button
                className={`nav-link ${navtab.active ? "active" : ""}`}
                id={navtab.id}
                key={navtab.id}
                data-bs-toggle="pill"
                data-bs-target={navtab.target}
                type="button"
                role="tab"
                aria-controls={navtab.ariaControls}
                aria-selected={navtab.ariaSelected}
                onClick={() => {
                  let mobileNavTab = document.getElementById("v-pills-tab");
                  mobileNavTab.classList.toggle("d-none");

                  let mobileNavTabContent =
                    document.getElementById("v-pills-tabContent");
                  mobileNavTabContent.classList.remove("d-none");
                }}
              >
                <h3 className="fw-600 text-dark fs-18 mb-1">{navtab.label}</h3>
                <p className="text-muted fs-14 mb-0 fw-500">
                  {navtab.description}
                </p>
              </button>
            );
          })}
        </div>
        <div className="tab-content d-none d-md-flex" id="v-pills-tabContent">
          {navtabs.map((navtab) => {
            return (
              <div
                className={`tab-pane fade ${
                  navtab.active ? "show active" : ""
                }`}
                key={navtab.id}
                id={navtab.ariaControls}
                role="tabpanel"
                aria-labelledby={navtab.id}
                tabIndex="0"
              >
                {navtab.component}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
