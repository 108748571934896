import React from "react";
import "./SpaceTitle.css";

export const SpaceTitle = ({ listingForm, setListingForm }) => {
  const maxLength = 40;

  const handleTitleChange = (e) => {
    if (e.target.value.length > maxLength) {
      return;
    }
    setListingForm({ ...listingForm, title: e.target.value });
  };

  return (
    <div className="row g-0 justify-content-center mt-4">
      <div className="col-12 col-md-5">
        <h5 className="mb-2 text-center fs-36 fw-600">
          Now, let’s give your space a title
        </h5>
        <p className="mb-0 fw-500 fs-16 text-center text-muted">
          Short titles work best. Have fun with it — you can always update it
          later.
        </p>
        <textarea
          className="form-control mt-4 fw-500"
          rows="10"
          placeholder="Add title"
          value={listingForm?.title}
          onChange={handleTitleChange}
        ></textarea>
        <p className="mb-0 mt-2 fw-600 fs-16">
          {listingForm?.title?.length || 0}/{maxLength}
        </p>
      </div>
    </div>
  );
};
