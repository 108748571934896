// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.count-input {
  width: 1rem;
  -moz-appearance: textfield;
  text-align: center;
  border: none;
  outline: none;
  font-weight: 500;
}

.count-input::-webkit-inner-spin-button,
.count-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/CreateListingsModal/SpaceAmenities/SpaceAmenities.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,0BAA0B;EAC1B,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX","sourcesContent":[".count-input {\n  width: 1rem;\n  -moz-appearance: textfield;\n  text-align: center;\n  border: none;\n  outline: none;\n  font-weight: 500;\n}\n\n.count-input::-webkit-inner-spin-button,\n.count-input::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
