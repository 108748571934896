import React from "react";
import { ReactComponent as FramedHouseIcon } from "../../../../assets/icons/framed-house.svg";
import { ReactComponent as FullFramedHouseIcon } from "../../../../assets/icons/full-framed-house.svg";
import { ReactComponent as HalfFramedHouseIcon } from "../../../../assets/icons/half-framed-house.svg";
import "./PlaceType.css";

export const PlaceType = ({ listingForm, setListingForm }) => {
  const places = [
    {
      id: 1,
      name: "An entire place",
      description: "Guests have the whole place to themselves.",
      value: "Entire Space",
      image: <FramedHouseIcon />,
    },
    {
      id: 2,
      name: "A part of the space",
      description:
        "Guests have their own portion of the space, plus access to shared spaces.",
      value: "Part of Space",
      image: <HalfFramedHouseIcon />,
    },
    {
      id: 3,
      name: "A shared space",
      value: "Shared Space",
      description:
        "Guests have access to a space or common area that may be shared with you or others.",
      image: <FullFramedHouseIcon />,
    },
  ];

  return (
    <div className="row g-0 justify-content-center mt-4">
      <div className="col-12 col-md-5">
        <h5 className="mb-4 text-center fs-36 fw-600">
          What type of place will guests have?
        </h5>
        {places.map((place) => (
          <div
            className={`row g-0 border border-2 rounded-4 p-4 mb-4 hoverable ${
              listingForm?.type === place.value ? "selected" : ""
            }`}
            onClick={() => {
              setListingForm({ ...listingForm, type: place.value });
            }}
            key={place.id}
          >
            <div className="col">
              <h5 className="fs-20 fw-600">{place.name}</h5>
              <p className="mb-0 fs-14">{place.description}</p>
            </div>
            <div className="col-2 d-flex align-items-center justify-content-center">
              {place.image}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
