import React from "react";
import { ReactComponent as ClosePrimary } from "../../../assets/icons/close-primary.svg";
import { SpaceAppliancesForm } from "../../forms/SpaceAppliancesForm/SpaceAppliancesForm";
import { SpaceDurationForm } from "../../forms/SpaceDurationForm/SpaceDurationForm";
import { SpaceSizeForm } from "../../forms/SpaceSizeForm/SpaceSizeForm";
import { UncutModal } from "../../ui/modal/modal";

export const SearchFilterModal = ({ id }) => {
  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Search Filters</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <SpaceDurationForm />
        <br />
        <SpaceSizeForm />
        <br />
        <SpaceAppliancesForm />
      </div>
      <div className="modal-footer px-4">
        <button
          type="button"
          className="btn btn-primary fw-600 flex-grow-1 fs-14 p-3"
          data-bs-dismiss="modal"
        >
          Show matching listings
        </button>
      </div>
    </UncutModal>
  );
};
