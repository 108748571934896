// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploadArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 25rem;
  height: 14.1875rem;
  border: 2px dashed #ccc;
  border-radius: 1.25rem;
  padding: 20px;
  margin: 20px 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.uploadArea.dragging {
  border: 2px dashed #000;
  background-color: #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/EditGovernmentIdModal/EditGovernmentIdModal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,sBAAsB;EACtB,aAAa;EACb,cAAc;EACd,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;AAC3B","sourcesContent":[".uploadArea {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  max-width: 25rem;\n  height: 14.1875rem;\n  border: 2px dashed #ccc;\n  border-radius: 1.25rem;\n  padding: 20px;\n  margin: 20px 0;\n  cursor: pointer;\n  transition: all 0.3s ease-in-out;\n}\n\n.uploadArea.dragging {\n  border: 2px dashed #000;\n  background-color: #f0f0f0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
