import React from "react";
import { Account } from "./Account/Account";
import "./Notifications.css";
import { OffersAndUpdates } from "./OffersAndUpdates/OffersAndUpdates";

export const Notifications = () => {
  return (
    <>
      <div className="card shadow border-0 rounded-4 w-100 payment-container mb-5">
        <ul className="nav nav-tabs gap-3 ps-3 mt-4" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="offers-tab"
              data-bs-toggle="tab"
              data-bs-target="#offers"
              type="button"
              role="tab"
              aria-controls="offers"
              aria-selected="true"
            >
              Offers & Updates
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="account-tab"
              data-bs-toggle="tab"
              data-bs-target="#account"
              type="button"
              role="tab"
              aria-controls="account"
              aria-selected="false"
            >
              Account
            </button>
          </li>
        </ul>

        <div className="tab-content" style={{ minHeight: "400px" }}>
          <div
            className="tab-pane active"
            id="offers"
            role="tabpanel"
            aria-labelledby="offers-tab"
          >
            <OffersAndUpdates />
          </div>
          <div
            className="tab-pane"
            id="account"
            role="tabpanel"
            aria-labelledby="account-tab"
          >
            <Account />
          </div>
        </div>
      </div>
    </>
  );
};
