import React from "react";
import { EditAddressModal } from "../../../../components/modals/EditAddressModal/EditAddressModal";
import { EditBioModal } from "../../../../components/modals/EditBioModal/EditBioModal";
import { EditEmailModal } from "../../../../components/modals/EditEmailModal/EditEmailModal";
import { EditEmergencyContact } from "../../../../components/modals/EditEmergencyContact/EditEmergencyContact";
import { EditGovernmentIdModal } from "../../../../components/modals/EditGovernmentIdModal/EditGovernmentIdModal";
import { EditNameModal } from "../../../../components/modals/EditNameModal/EditNameModal";
import { EditPhoneModal } from "../../../../components/modals/EditPhoneModal/EditPhoneModal";
import { useSpinnerContext } from "../../../../context/SpinnerContext";
import { useUserContext } from "../../../../context/UserContext";
import { toastService } from "../../../../lib/toast.service";
import {
  updateAddress,
  updateBio,
  updateEmailAddress,
  updateLegalName,
  updatePhoneNumber,
} from "../../../../services/settings.service";
import "./PersonalInfo.css";

export const PersonalInfo = () => {
  const { user, setUser } = useUserContext();

  const { setSpinner } = useSpinnerContext();

  const updateName = async () => {
    setSpinner(true);
    const resp = await updateLegalName({
      first_name: user.first_name,
      last_name: user.last_name,
    });
    setSpinner(false);
    if (resp.status === 201 || resp.status === 200) {
      toastService.success(resp.message);
    }
  };

  const updateAddressInfo = async (address) => {
    setSpinner(true);
    const resp = await updateAddress(address);
    setSpinner(false);
    if (resp.status === 201 || resp.status === 200) {
      toastService.success(resp.message);
    }
  };

  const updateBioInfo = async () => {
    setSpinner(true);
    const resp = await updateBio({
      bio: user.bio,
    });
    setSpinner(false);
    if (resp.status === 201 || resp.status === 200) {
      toastService.success(resp.message);
    }
  };

  const updateEmail = async () => {
    setSpinner(true);
    const resp = await updateEmailAddress({
      email: user.email,
    });
    setSpinner(false);
    if (resp.status === 201 || resp.status === 200) {
      toastService.success(resp.message);
    }
  };

  const updatePhone = async () => {
    setSpinner(true);
    const resp = await updatePhoneNumber({
      phone_number: user.phone_number,
    });
    setSpinner(false);
    if (resp.status === 201 || resp.status === 200) {
      toastService.success(resp.message);
    }
  };

  return (
    <>
      <div className="card shadow border-0 rounded-4 w-100 mb-5">
        <div className="d-flex justify-content-between align-items-center p-4 px-5 border-bottom">
          <div>
            <h4 className="fw-bold text-dark fs-16 mb-0 fw-600">Legal name</h4>
            <p className="text-muted fs-14 mb-0 fw-500">
              {user?.first_name} {user?.last_name}
            </p>
          </div>
          <a
            href="#!"
            data-bs-toggle="modal"
            data-bs-target="#editNameModal"
            className="text-decoration-none text-primary fw-500"
          >
            Edit
          </a>
        </div>
        <div className="d-flex justify-content-between align-items-center p-4 px-5 border-bottom">
          <div>
            <h4 className="fw-bold text-dark fs-16 mb-0 fw-600">
              Email address
            </h4>
            <p className="text-muted fs-14 mb-0 fw-500">{user?.email}</p>
          </div>
          <a
            href="#!"
            data-bs-toggle="modal"
            data-bs-target="#editEmailModal"
            className="text-decoration-none text-primary fw-500"
          >
            Edit
          </a>
        </div>
        <div className="d-flex justify-content-between align-items-center p-4 px-5 border-bottom">
          <div>
            <h4 className="fw-bold text-dark fs-16 mb-0 fw-600">Bio</h4>
            <p className="text-muted fs-14 mb-0 fw-500">
              {user?.bio
                ? user.bio.substring(0, 50) +
                  (user.bio.length > 50 ? "..." : "")
                : "Not provided"}
            </p>
          </div>
          <a
            href="#!"
            data-bs-toggle="modal"
            data-bs-target="#editBioModal"
            className="text-decoration-none text-primary fw-500"
          >
            Edit
          </a>
        </div>
        <div className="d-flex justify-content-between align-items-center p-4 px-5 border-bottom">
          <div>
            <h4 className="fw-bold text-dark fs-16 mb-0 fw-600">
              Phone number
            </h4>
            <p className="text-muted fs-14 mb-0 fw-500">{user?.phone_number}</p>
          </div>
          <a
            href="#!"
            data-bs-toggle="modal"
            data-bs-target="#editPhoneModal"
            className="text-decoration-none text-primary fw-500"
          >
            Edit
          </a>
        </div>
        <div className="d-flex justify-content-between align-items-center p-4 px-5 border-bottom">
          <div>
            <h4 className="fw-bold text-dark fs-16 mb-0 fw-600">
              Government ID
            </h4>
            <p className="text-muted fs-14 mb-0 fw-500">Not provided</p>
          </div>
          <a
            href="#!"
            data-bs-toggle="modal"
            data-bs-target="#editGovernmentIdModal"
            className="text-decoration-none text-primary fw-500"
          >
            Edit
          </a>
        </div>
        <div className="d-flex justify-content-between align-items-center p-4 px-5 border-bottom">
          <div>
            <h4 className="fw-bold text-dark fs-16 mb-0 fw-600">Address</h4>
            <p className="text-muted fs-14 mb-0 fw-500">Not provided</p>
          </div>
          <a
            href="#!"
            data-bs-toggle="modal"
            data-bs-target="#editAddressModal"
            className="text-decoration-none text-primary fw-500"
          >
            Edit
          </a>
        </div>
        <div className="d-flex justify-content-between align-items-center p-4 px-5 border-bottom">
          <div>
            <h4 className="fw-bold text-dark fs-16 mb-0 fw-600">
              Emergency Contact
            </h4>
            <p className="text-muted fs-14 mb-0 fw-500">Not provided</p>
          </div>
          <a
            href="#!"
            data-bs-toggle="modal"
            data-bs-target="#editEmergencyContact"
            className="text-decoration-none text-primary fw-500"
          >
            Edit
          </a>
        </div>
      </div>
      <EditNameModal
        id="editNameModal"
        setUser={setUser}
        user={user}
        updateName={updateName}
      />
      <EditEmailModal
        id="editEmailModal"
        setUser={setUser}
        user={user}
        updateEmail={updateEmail}
      />
      <EditPhoneModal
        id="editPhoneModal"
        setUser={setUser}
        user={user}
        updatePhone={updatePhone}
      />
      <EditBioModal
        id="editBioModal"
        setUser={setUser}
        user={user}
        updateBioInfo={updateBioInfo}
      />
      <EditAddressModal
        id="editAddressModal"
        updateAddressInfo={updateAddressInfo}
      />
      <EditEmergencyContact id="editEmergencyContact" />
      <EditGovernmentIdModal id="editGovernmentIdModal" />
    </>
  );
};
