import React from "react";
import "./GetStarted.css";

export const GetStarted = () => {
  return (
    <div className="row g-0 justify-content-center mt-4">
      <div className="col-12 col-md-5">
        <h5 className="mb-4 text-center fs-36 fw-600">
          3 Info you need to post <br></br> on Spacegeni. It’s that easy!
        </h5>
        <div className="row g-0 border border-2 rounded-3 p-3 mb-4">
          <div className="col-2 d-flex align-items-center justify-content-center">
            <span className="fs-34 fw-600 color-purple">1</span>
          </div>
          <div className="col">
            <h5 className="fs-20 fw-600">Tell us about your space</h5>
            <p className="mb-0 fs-16">
              Share some basic info, like where your space is and how many
              guests can stay.
            </p>
          </div>
        </div>
        <div className="row g-0 border border-2 rounded-3 p-3 mb-4">
          <div className="col-2 d-flex align-items-center justify-content-center">
            <span className="fs-34 fw-600 color-purple">2</span>
          </div>
          <div className="col">
            <h5 className="fs-20 fw-600">Make it stand out</h5>
            <p className="mb-0 fs-16">
              Add 5 or more photos plus a title and description — we’ll help you
              out.
            </p>
          </div>
        </div>
        <div className="row g-0 border border-2 rounded-3 p-3 mb-4">
          <div className="col-2 d-flex align-items-center justify-content-center">
            <span className="fs-34 fw-600 color-purple">3</span>
          </div>
          <div className="col">
            <h5 className="fs-20 fw-600">Finish up and publish </h5>
            <p className="mb-0 fs-16">
              Choose if you’d like to start with an experience guest, set a
              start price, and publish your listing.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
