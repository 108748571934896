import React from "react";
import { ReactComponent as Bank } from "../../../../../assets/icons/bank.svg";
import { ReactComponent as Ellipsis } from "../../../../../assets/icons/ellipsis.svg";
import { ReactComponent as Paypal } from "../../../../../assets/icons/paypal.svg";

export const Payouts = () => {
  return (
    <div className="p-4">
      <h5 className="fw-bold text-dark fs-20 mb-0 fw-600 mt-3">
        How you get paid
      </h5>
      <p className="text-muted fs-14 mb-0 fw-500">
        You can send your money to one or more payout methods.
      </p>

      <div className="d-flex align-items-center justify-content-between border rounded-4 p-3 mt-4">
        <div className="d-flex gap-4">
          <Bank />
          <div>
            <h4 className="fw-bold text-dark fs-16 mb-0 fw-500">
              Bank Account (Default)
            </h4>
            <p className="text-muted fs-14 mb-0 fw-500">
              Olivia Kennedy, Checking ****4949(USD)
            </p>
          </div>
        </div>
        <div
          className="btn btn-link p-0 position-relative"
          onClick={(e) => {
            let dropdownMenu =
              e.target.parentElement.querySelector(".res-dropdown-menu");
            dropdownMenu && (dropdownMenu.style.display = "block");

            window.addEventListener("mousedown", (e) => {
              if (dropdownMenu) {
                dropdownMenu.style.display = "none";
              }
            });
          }}
        >
          <Ellipsis width={25} height={25} />
          <div className="res-dropdown-menu listing">
            <button className="custom-dropdown-item">
              <div className="d-flex align-items-center gap-3">
                <span>Set Default</span>
              </div>
            </button>
            <button className="custom-dropdown-item">
              <div className="d-flex align-items-center gap-3">
                <span>Remove</span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between border rounded-4 p-3 mt-4">
        <div className="d-flex gap-4">
          <Paypal />
          <div>
            <h4 className="fw-bold text-dark fs-16 mb-0 fw-500">Paypal</h4>
            <p className="text-muted fs-14 mb-0 fw-500">
              olivia.kennedy@gmail.com
            </p>
          </div>
        </div>
        <div
          className="btn btn-link p-0 position-relative"
          onClick={(e) => {
            let dropdownMenu =
              e.target.parentElement.querySelector(".res-dropdown-menu");
            dropdownMenu && (dropdownMenu.style.display = "block");

            window.addEventListener("mousedown", (e) => {
              if (dropdownMenu) {
                dropdownMenu.style.display = "none";
              }
            });
          }}
        >
          <Ellipsis width={25} height={25} />
          <div className="res-dropdown-menu listing">
            <button className="custom-dropdown-item">
              <div className="d-flex align-items-center gap-3">
                <span>Set Default</span>
              </div>
            </button>
            <button className="custom-dropdown-item">
              <div className="d-flex align-items-center gap-3">
                <span>Remove</span>
              </div>
            </button>
          </div>
        </div>
      </div>

      <button className="btn btn-primary mt-4 fw-600 py-2">
        Add Payout Method
      </button>
    </div>
  );
};
