// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.option {
  width: 16rem;
}

@media screen and (max-width: 48em) {
  .option{
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/CreateListingsModal/SpaceType/SpaceType.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".option {\n  width: 16rem;\n}\n\n@media screen and (max-width: 48em) {\n  .option{\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
