import { del, get, post, put } from "../lib/http.service";

const API_URL = process.env.REACT_APP_API_URL;

export const getFeaturedListings = async () => {
  try {
    const resp = await get(`${API_URL}/space/featured-spaces/`);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const getListings = async () => {
  try {
    const resp = await get(`${API_URL}/space/`);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const getListing = async (listingId) => {
  try {
    const resp = await get(`${API_URL}/space/space-detail/${listingId}`);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const filterListings = async (filter) => {
  try {
    const resp = await post(`${API_URL}/space/search-listings/`, filter);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const getListingsCategories = async () => {
  try {
    const resp = await get(`${API_URL}/space/space-categories/`);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const getAmenities = async () => {
  try {
    const resp = await get(`${API_URL}/space/amenities/`);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const getMyAmenities = async () => {
  try {
    const resp = await get(`${API_URL}/space/my-amenities/`);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const postListing = async (listing) => {
  try {
    const resp = await post(`${API_URL}/space/`, listing);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const removeListing = async (listingId) => {
  try {
    const resp = await del(`${API_URL}/space/delete-space/${listingId}`);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const getSearchWizard = async (search) => {
  try {
    const resp = await post(`${API_URL}/space/search-wizard/`, search);
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};

export const updateAmenities = async ({ space_id, amenities }) => {
  try {
    const resp = await put(`${API_URL}/space/update-space-amenities/`, {
      space_id,
      amenities,
    });
    return resp;
  } catch (ex) {
    console.log(ex);
  }
};
