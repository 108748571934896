import { createContext, useContext, useEffect, useState } from "react";

export const SidebarContext = createContext(false);

export const SidebarProvider = ({ children }) => {
  const [sidebar, setSidebar] = useState(false);

  useEffect(() => {
    const sidebar = localStorage.getItem("sidebar");
    setSidebar(sidebar);
  }, []);

  return (
    <SidebarContext.Provider value={{ sidebar, setSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error("useSidebarContext must be used within a SidebarProvider");
  }
  return context;
};
