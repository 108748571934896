import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Navbar } from "../../components/ui/navbar/Navbar";
import { storageService } from "../../lib/storage.service";
import "./Auth.css";

export const Auth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const autoLogin = () => {
      const token = storageService.getToken();
      if (token) {
        navigate("/main/dashboard");
      }
    };

    autoLogin();
  }, [navigate]);

  return (
    <div className="container-fluid row g-0 min-vh-100">
      <Navbar />
      <Outlet />
    </div>
  );
};
