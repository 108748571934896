import { City } from "country-state-city";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import CheckBoxCircle from "../../../assets/icons/checkbox-circle.png";
import CheckmarkCircle from "../../../assets/icons/checkmark-circle.png";
import { ReactComponent as ChevronPrimary } from "../../../assets/icons/chevron-primary.svg";
import { ReactComponent as Close } from "../../../assets/icons/close-light.svg";
import { ReactComponent as DollarCircle } from "../../../assets/icons/dollar-circle.svg";
import { ReactComponent as Humburger } from "../../../assets/icons/humburger-light.svg";
import LocationCircle from "../../../assets/icons/location-circle.png";
import { ReactComponent as RibbonCircle } from "../../../assets/icons/ribbon-circle.svg";
import SearchIcon from "../../../assets/icons/search-white.svg";
import { ReactComponent as SyncCircle } from "../../../assets/icons/sync-circle.svg";
import { ReactComponent as TrailCircle } from "../../../assets/icons/trail-circle.svg";
import EarnImage from "../../../assets/images/71d277e71b93a998984be5afcd12a187.png";
import LandingImage from "../../../assets/images/landing-image.png";
import Logo from "../../../assets/images/logo-2.svg";
import { ReactComponent as Sparks } from "../../../assets/images/sparks.svg";
import { ListingCard } from "../../../components/application_components/ListingCard/ListingCard";
import { TestimonialCard } from "../../../components/application_components/TestimonialCard/TestimonialCard";
import { UncutCalendar } from "../../../components/ui/calendar/calendar";
import { MainFooter } from "../../../components/ui/main-footer/main-footer";
import { getFeaturedListings } from "../../../services/listings.service";
import "./Home.css";

export const Home = () => {
  const navigate = useNavigate();

  const [featuredSpaces, setFeaturedSpaces] = useState([]);

  const [search, setSearch] = useState("");

  const cities = useMemo(() => {
    const massachusettsCities = City.getCitiesOfState("US", "MA");
    return massachusettsCities || [];
  }, []);

  const [ctaForm, setCtaForm] = useState({
    location: "",
    checkin_datetime: "",
    checkout_datetime: "",
  });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const responsive2 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const getAllFeaturedSpaces = useCallback(async () => {
    try {
      const resp = await getFeaturedListings();
      setFeaturedSpaces(resp.result);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getAllFeaturedSpaces();
  }, [getAllFeaturedSpaces]); // Empty dependency array

  const handleCTAForm = (e) => {
    e.preventDefault();
    navigate("/find-space/get-started", { state: ctaForm });
  };

  const handleMobileMenu = () => {
    let mobileMenu = document.getElementById("mobile-menu");
    mobileMenu.classList.toggle("show");
  };

  return (
    <div className="min-vh-100 justify-content-between gap-5">
      <section className="landing-section">
        <div className="container">
          <nav className="d-flex align-items-center justify-content-between py-3">
            <div className="logo">
              <img
                src={Logo}
                alt="logo"
                width="150"
                className="pointer"
                onClick={() => navigate("/")}
              />
            </div>
            <ul className="align-items-center gap-5 m-0 d-none d-md-flex">
              <li>
                <button
                  className="cta-button"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/auth/register");
                  }}
                >
                  Register
                </button>
              </li>
              {/* <li>
                <a
                  href="#!"
                  className="text-decoration-none text-white fw-500"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/auth/register");
                  }}
                >
                  Sign up
                </a>
              </li> */}
              <li>
                <a
                  href="#!"
                  className="text-decoration-none text-white fw-500"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/auth/login");
                  }}
                >
                  Log in
                </a>
              </li>
            </ul>
            <ul
              className="d-flex align-items-center gap-5 m-0 d-md-none mobile-menu"
              id="mobile-menu"
            >
              <Close
                width={30}
                height={30}
                className="d-md-none close-btn"
                onClick={handleMobileMenu}
              />
              <li>
                <a
                  href="#!"
                  className="text-decoration-none text-white fw-500"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/auth/register");
                  }}
                >
                  Register
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  className="text-decoration-none text-white fw-500"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/auth/login");
                  }}
                >
                  Log in
                </a>
              </li>
            </ul>

            <Humburger
              width={25}
              height={25}
              className="d-md-none"
              onClick={handleMobileMenu}
            />
          </nav>
          <div className="row g-0 gx-md-5 justify-content-between h-100">
            <div className="col-12 col-md mt-5">
              <h1 className="fw-600 text-white hero-text">
                Find your next ideal production space effortlessly!
              </h1>
              <div className="rounded-pill bg-white d-flex align-items-center justify-content-between gap-3 py-2 px-3 mt-4 landing-page-input">
                <div className="border-end pe-0 ps-0 ps-md-3">
                  <small className="fw-500">Location</small>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="cta-input"
                      placeholder="Select City"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onClick={(e) => {
                        let allDropdowns =
                          document.querySelectorAll(".dropdown-menu");
                        allDropdowns.forEach((dropdown) => {
                          dropdown.classList.remove("show");
                        });

                        let dropdownMenu = e.target.nextElementSibling;
                        dropdownMenu && dropdownMenu.classList.add("show");

                        window.addEventListener("mousedown", (e) => {
                          if (!e.target.closest(".dropdown-menu")) {
                            dropdownMenu.classList.remove("show");
                          }
                        });
                      }}
                    />
                    <div className="dropdown-menu overflow-hidden w-100 border-0 shadow">
                      {cities
                        .filter((city) =>
                          city.name.toLowerCase().includes(search.toLowerCase())
                        )
                        .map((city, index) => (
                          <div
                            className="dropdown-item pointer fw-500"
                            key={index}
                            onClick={(e) => {
                              setCtaForm({
                                ...ctaForm,
                                location: city.name,
                              });
                              setSearch(city.name);
                              let dropdownMenu =
                                e.target.closest(".dropdown-menu");
                              dropdownMenu.classList.remove("show");
                            }}
                          >
                            {city.name}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="border-end pe-5">
                  <small className="fw-500">Check In</small>
                  <div
                    className="date-dropdown-select cta-date pointer"
                    onClick={(e) => {
                      let allDropdowns = document.querySelectorAll(
                        ".date-dropdown-select .date-select-content"
                      );
                      allDropdowns.forEach((dropdown) => {
                        dropdown.classList.remove("show");
                      });

                      let dateSelectContent = e.target
                        .closest(".date-dropdown-select")
                        .querySelector(".date-select-content");
                      dateSelectContent.classList.add("show");

                      window.addEventListener("mousedown", (e) => {
                        if (!e.target.closest(".date-dropdown-select")) {
                          dateSelectContent.classList.remove("show");
                        }
                      });
                    }}
                  >
                    <div className="date-select-btn">
                      <span
                        className="fw-500 me-2 fs-16"
                        style={{
                          whiteSpace: "nowrap",
                          pointerEvents: "none",
                        }}
                      >
                        {ctaForm?.checkin_datetime
                          ? new Intl.DateTimeFormat("en-US", {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            }).format(ctaForm?.checkin_datetime)
                          : "Choose Date"}
                      </span>
                    </div>
                    <div className={`date-select-content`}>
                      <UncutCalendar
                        setDate={(date) => {
                          setCtaForm({
                            ...ctaForm,
                            checkin_datetime: date,
                          });
                        }}
                        checkIn={ctaForm?.checkin_datetime || new Date()}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <div>
                    <small className="fw-500">Check Out</small>
                    <div
                      className="date-dropdown-select cta-date pointer"
                      onClick={(e) => {
                        let allDropdowns = document.querySelectorAll(
                          ".date-dropdown-select .date-select-content"
                        );
                        allDropdowns.forEach((dropdown) => {
                          dropdown.classList.remove("show");
                        });

                        let dateSelectContent = e.target
                          .closest(".date-dropdown-select")
                          .querySelector(".date-select-content");
                        dateSelectContent.classList.add("show");

                        window.addEventListener("mousedown", (e) => {
                          if (!e.target.closest(".date-dropdown-select")) {
                            dateSelectContent.classList.remove("show");
                          }
                        });
                      }}
                    >
                      <div className="date-select-btn">
                        <span
                          className="fw-500 me-2 fs-16"
                          style={{
                            whiteSpace: "nowrap",
                            pointerEvents: "none",
                          }}
                        >
                          {ctaForm?.checkout_datetime
                            ? new Intl.DateTimeFormat("en-US", {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              }).format(ctaForm?.checkout_datetime)
                            : "Choose Date"}
                        </span>
                      </div>
                      <div className={`date-select-content`}>
                        <UncutCalendar
                          setDate={(date) => {
                            setCtaForm({ ...ctaForm, checkout_datetime: date });
                          }}
                          checkOut={ctaForm?.checkout_datetime || new Date()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button className="search-button" onClick={handleCTAForm}>
                  <img src={SearchIcon} alt="search" width="20" />
                  <span className="d-md-none fs-16 ms-2">Search</span>
                </button>
              </div>
            </div>
            <div className="col-12 col-md d-none d-md-flex align-items-center justify-content-center">
              <img
                src={LandingImage}
                alt="landing"
                className="img-fluid"
                width="70%"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="how-it-works-section my-5">
        <div className="container">
          <h5 className="fw-800 fs-2 pt-5">How it works</h5>
          <p className="text-muted">
            Discover a production space that matches your need in 3 easy steps{" "}
          </p>
          <div className="row g-0 gap-5" style={{ marginBottom: "10rem" }}>
            <div className="col-12 col-md d-flex align-items-center">
              <img src={LocationCircle} alt="location" width="50" />
              <div className="ms-3">
                <h6 className="fw-600 mt-3">Find a space</h6>
                <p className="fw-500 text-muted">
                  Choose a location and share specifics about the kind of space
                  you need for your production.
                </p>
              </div>
            </div>
            <div className="col-12 col-md d-flex align-items-center">
              <img src={CheckBoxCircle} alt="checkbox" width="50" />
              <div className="ms-3">
                <h6 className="fw-600 mt-3">Choose a space</h6>
                <p className="fw-500 text-muted">
                  Explore our curated list of spaces, precisely matched using AI
                  technology.
                </p>
              </div>
            </div>
            <div className="col-12 col-md d-flex align-items-center">
              <img src={CheckmarkCircle} alt="checkmark" width="50" />
              <div className="ms-3">
                <h6 className="fw-600 mt-3">Book & enjoy your production</h6>
                <p className="fw-500 text-muted">
                  Complete payment and dive into your production. Don't forget
                  to leave a review when you're done!
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="fw-800 fs-2">Featured Spaces</h5>
          </div>
          <div className="position-relative">
            <Carousel
              swipeable={true}
              draggable={false}
              showDots={false}
              arrows={false}
              responsive={responsive2}
              ssr={true}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              customTransition="all .5s"
              transitionDuration={1000}
              containerClass="featured-spaces pb-4"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="mx-4"
              renderButtonGroupOutside={true}
              customButtonGroup={
                <CustomButtonGroup
                  style={{
                    top: "-1.5rem",
                    right: "0",
                  }}
                />
              }
            >
              {featuredSpaces.length > 0 &&
                featuredSpaces.map((listing) => (
                  <ListingCard key={listing.id} {...listing} />
                ))}
            </Carousel>
          </div>
        </div>
      </section>
      <section className="ai-section">
        <div className="container">
          <div className="ai-card bg-primary-linear rounded-5">
            <div className="col-12 col-md-6">
              <h5 className="fw-600 fs-2 text-white mb-3 fs-48">
                Experience a seamless space search with our advanced AI!
              </h5>
              <p className="mb-0 text-white">
                Let our powerful AI do the work! It sorts listings based on your
                needs, showing you 90-100% matches. No more hours of
                scrolling—find your ideal space effortlessly.
              </p>
            </div>
            <Sparks className="sparks" />
          </div>
        </div>
      </section>
      <section
        className="how-it-works-section"
        style={{ marginBlock: "10rem" }}
      >
        <div className="container">
          <h5 className="fw-800 fs-2 pt-5 mb-5">Why book with Spacegeni?</h5>
          <div className="row g-0 gap-5 mb-5">
            <div className="col-12 col-md">
              <SyncCircle />
              <div>
                <h6 className="fw-600 mt-3">Seamless process</h6>
                <p className="fw-500 text-muted">
                  Streamlines the process of finding budget-friendly production
                  spaces tailored to specific requirements.
                </p>
              </div>
            </div>
            <div className="col-12 col-md">
              <DollarCircle />
              <div>
                <h6 className="fw-600 mt-3">Cost savings</h6>
                <p className="fw-500 text-muted">
                  Quickly understand the equipment available so informed
                  business decisions can be made.
                </p>
              </div>
            </div>
            <div className="col-12 col-md">
              <TrailCircle />
              <div>
                <h6 className="fw-600 mt-3">Easy Decision making</h6>
                <p className="fw-500 text-muted">
                  Readily available information about production spaces within a
                  specific location.
                </p>
              </div>
            </div>
            <div className="col-12 col-md">
              <RibbonCircle />
              <div>
                <h6 className="fw-600 mt-3">Easy permitting</h6>
                <p className="fw-500 text-muted">
                  Help you to understand the permitting process of your target
                  municipality to reduce production risk.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cta-section">
        <div className="container">
          <div
            className="d-flex align-items-center flex-wrap justify-content-center"
            style={{ gap: "5rem" }}
          >
            <img src={EarnImage} alt="person" />
            <div className="text-center text-lg-start">
              <h5 className="fw-800 fs-2 mb-3">
                Earn income by becoming <br></br> a host – it's that simple!
              </h5>
              <p className="fw-500 text-muted mb-3">
                Boost your income by listing your space for both personal and
                commercial productions. You'd be amazed at how many
                entrepreneurs are seeking spaces like yours!
              </p>
              <button
                className="cta-button"
                onClick={() => navigate("/auth/login")}
              >
                List your space
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial-section pb-7">
        <div className="container position-relative">
          <div className="d-flex align-items-center mb-5">
            <div>
              <h5 className="fw-800 fs-2">
                We’re proud of our customers’ success.
              </h5>
              <p className="text-muted mb-0 fw-500">
                Don’t take our words for it.
              </p>
            </div>
          </div>
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            arrows={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            customTransition="all .5s"
            transitionDuration={1000}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="mx-5"
            renderButtonGroupOutside={true}
            customButtonGroup={<CustomButtonGroup />}
          >
            {Array.from({ length: 6 }).map((_, cardIndex) => (
              <TestimonialCard key={cardIndex} />
            ))}
          </Carousel>
        </div>
      </section>

      <MainFooter />
    </div>
  );
};

const CustomButtonGroup = ({ next, previous, goToSlide, style, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div
      className="d-flex justify-content-between align-items-center carousel-arrows"
      style={style}
    >
      <button
        className="btn-arrows"
        onClick={() => previous()}
        style={{ pointerEvents: currentSlide === 0 ? "none" : "auto" }}
      >
        <ChevronPrimary style={{ pointerEvents: "none" }} />
      </button>
      <button
        className="btn-arrows"
        onClick={() => next()}
        style={{ pointerEvents: currentSlide === 5 ? "none" : "auto" }}
      >
        <ChevronPrimary
          style={{ transform: "rotate(180deg)", pointerEvents: "none" }}
        />
      </button>
    </div>
  );
};
