import React, { useState } from "react";
import { ReactComponent as DocumentOutline } from "../../../../assets/icons/document-outline.svg";
import { useSpinnerContext } from "../../../../context/SpinnerContext";
import { utilService } from "../../../../services/util.service";
import "./UploadVerificationDocument.css";

export const UploadVerificationDocument = ({
  setListingForm,
  show,
  setShow,
}) => {
  const [dragging, setDragging] = useState(false);

  const { setSpinner } = useSpinnerContext();

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
      setShow(false);
    }
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        setSpinner(true);
        let resp = await utilService.uploadImage(reader.result, "document");
        setListingForm((prevState) => ({
          ...prevState,
          verification_documents: [
            ...(prevState.verification_documents || []),
            resp.results.secure_url,
          ],
        }));
        setSpinner(false);
        setShow(false);
      };
    }
  };

  return (
    <div className={`upload-modal ${show ? "show" : ""}`}>
      <div className="modal-content">
        <div className="modal-header shadow-none">
          <button
            type="button"
            className="btn-close"
            onClick={() => setShow(false)}
          ></button>
        </div>
        <div className="modal-body">
          <h3 className="text-center fw-bold mb-4">
            Add Verification Document
          </h3>
          <p className="text-center mb-4 mx-5">
            Adding this verification document not only ensures the safety of
            your guests but also grants your listings a verified badge for added
            credibility.
          </p>

          <label
            className={`drop-area document mt-4 ${dragging ? "dragging" : ""}`}
            onDragOver={(e) => {
              e.preventDefault();
              setDragging(true);
            }}
            onDragEnter={(e) => {
              e.preventDefault();
              setDragging(true);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              setDragging(false);
            }}
            onDrop={handleDrop}
          >
            <div className="text-center">
              <DocumentOutline />
              <h5 className="mb-0 fw-600 my-3">Drag your document here</h5>
            </div>
            <label
              htmlFor="file"
              className="text-dark mt-4 fw-600 text-decoration-underline pointer"
            >
              Upload from your device
            </label>
            <input
              type="file"
              id="file"
              className="d-none"
              onChange={(e) => handleFiles(e.target.files)}
            />
          </label>
        </div>
        <div className="modal-footer px-2 px-md-5">
          <button
            type="button"
            className="btn btn-primary-outline fw-600"
            onClick={() => setShow(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            style={{ paddingBlock: "0.7rem", paddingInline: "2rem" }}
            className="btn btn-primary fw-600"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};
