import React, { useState } from "react";
import { ReactComponent as Archive } from "../../../assets/icons/archive.svg";
import { ReactComponent as Ellipses } from "../../../assets/icons/ellipses-sm.svg";
import { Conversation } from "../../../components/application_components/Conversation/Conversation";
import { Message } from "../../../components/application_components/Message/Message";
import { MessageForm } from "../../../components/forms/MessageForm/MessageForm";
import { Timeline } from "../../../components/ui/timeline/Timeline";
import "./Inbox.css";

export const Inbox = () => {
  const [messages, setMessages] = useState([
    {
      id: 1,
      name: "Jane Lee",
      message:
        "Hi Jane, Thanks for booking my place. Hope you enjoy your stay. Please don’t hesitate to reach out in case you need anything. Same here. He’s been thought me a lot when it comes to trading in stock. I was a novice before I joined his class, but I’m way more advanced now.",
      time: "1 hour",
      image: "https://via.placeholder.com/150",
    },
  ]);

  const [message, setMessage] = useState({
    id: Math.random() * 100,
    name: "Jane Lee",
    image: "https://via.placeholder.com/150",
    message: "",
    time: "1 hour",
  });

  const onEnter = () => {
    setMessages([...messages, message]);
    setMessage({ ...message, message: "" });
  };

  return (
    <div className="row g-0 main-container">
      <div className="col-3 border-end">
        <div className="d-flex align-items-center justify-content-between border-bottom px-3 headers">
          <h4 className="fw-bold text-dark fs-28 mb-0">Messages</h4>
          <Ellipses />
        </div>
        <div className="conversations-container">
          <Conversation />
          <Conversation />
          <Conversation />
          <Conversation />
          <Conversation />
          <Conversation />
          <Conversation />
          <Conversation />
          <Conversation />
          <Conversation />
          <Conversation />
          <Conversation />
        </div>
      </div>
      <div className="col">
        <div className="d-flex align-items-center justify-content-between border-bottom px-3 headers">
          <div>
            <h6 className="fw-bold text-dark fs-18 mb-0">Jane Lee</h6>
            <small className="text-muted">Response time: 1 hour</small>
          </div>
          <Archive />
        </div>
        <div className="chat-container d-flex flex-column justify-content-end">
          <div className="col-12 content">
            <Timeline />
          </div>

          {messages.map((message) => (
            <div className="col-12 content" key={message.id}>
              <Message {...message} />
            </div>
          ))}
        </div>
        <MessageForm
          message={message.message}
          onEnter={onEnter}
          onChange={(e) => setMessage({ ...message, message: e.target.value })}
        />
      </div>
    </div>
  );
};
