import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSpinnerContext } from "../../../context/SpinnerContext";
import { toastService } from "../../../lib/toast.service";
import { register } from "../../../services/auth.service";
import {
  confirmPasswordValidation,
  emailValidation,
  passwordValidation,
} from "../../../utils/validation";
import { FormInput } from "../../ui/form-input/FormInput";
import "./RegisterForm.css";

export const RegisterForm = () => {
  const navigate = useNavigate();
  const { setSpinner } = useSpinnerContext();

  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    agree: false,
  });

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    handleErrors(e);
  };

  const handleErrors = (e) => {
    if (e.target.name === "first_name") {
      setErrors((prev) => ({
        ...prev,
        first_name: e.target.value ? "" : "First Name is required",
      }));
    }

    if (e.target.name === "last_name") {
      setErrors((prev) => ({
        ...prev,
        last_name: e.target.value ? "" : "Last Name is required",
      }));
    }

    if (e.target.name === "email") {
      setErrors((prev) => ({
        ...prev,
        email: emailValidation(e.target.value),
      }));
    }

    if (e.target.name === "phone") {
      setErrors((prev) => ({
        ...prev,
        phone: e.target.value ? "" : "Phone is required",
      }));
    }

    if (e.target.name === "password") {
      setErrors((prev) => ({
        ...prev,
        password: passwordValidation(e.target.value),
      }));
    }

    if (e.target.name === "confirmPassword") {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: confirmPasswordValidation(
          form.password,
          e.target.value
        ),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    const resp = await register(form);
    setSpinner(false);
    if (resp.status === 200 || resp.status === 201) {
      toastService.success(resp.message);
      navigate("/auth/verify-email?email=" + form.email);
      setForm({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        agree: false,
      });
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)} noValidate>
      <div className="mb-4">
        <FormInput
          name="first_name"
          type="text"
          placeholder="First Name"
          required={true}
          value={form.first_name}
          onChange={handleChange}
        />
        {errors.first_name && (
          <div className="text-danger fw-500 ms-3">
            <small className="ps-1">{errors.first_name}</small>
          </div>
        )}
      </div>
      <div className="mb-4">
        <FormInput
          name="last_name"
          type="text"
          placeholder="Last Name"
          value={form.last_name}
          required={true}
          onChange={handleChange}
        />
        {errors.last_name && (
          <div className="text-danger fw-500 ms-3">
            <small className="ps-1">{errors.last_name}</small>
          </div>
        )}
      </div>
      <div className="mb-4">
        <FormInput
          name="email"
          type="email"
          placeholder="Email Address"
          required={true}
          onChange={handleChange}
        />
        {errors.email && (
          <div className="text-danger fw-500 ms-3">
            <small className="ps-1">{errors.email}</small>
          </div>
        )}
      </div>
      <div className="mb-4">
        <FormInput
          name="phone"
          type="tel"
          placeholder="Phone Number"
          value={form.phone}
          required={true}
          onChange={handleChange}
        />
        {errors.phone && (
          <div className="text-danger fw-500 ms-3">
            <small className="ps-1">{errors.phone}</small>
          </div>
        )}
      </div>
      <div className="mb-4">
        <FormInput
          name="password"
          type="password"
          placeholder="Password"
          value={form.password}
          required={true}
          onChange={handleChange}
        />
        {errors.password && (
          <div className="text-danger fw-500 ms-3">
            <small className="ps-1">{errors.password}</small>
          </div>
        )}
      </div>
      <div className="mb-4">
        <FormInput
          name="confirmPassword"
          type="password"
          placeholder="Confirm Password"
          value={form.confirmPassword}
          required={true}
          onChange={handleChange}
        />
        {errors.confirmPassword && (
          <div className="text-danger fw-500 ms-3">
            <small className="ps-1">{errors.confirmPassword}</small>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center mb-5">
        <input
          className="d-none"
          type="checkbox"
          value={form.agree}
          id="rememberMe"
          onChange={(e) =>
            setForm((prev) => ({ ...prev, agree: e.target.checked }))
          }
        />
        <label className="form-check-input" htmlFor="rememberMe"></label>
        <label className="fs-16 fw-500" htmlFor="rememberMe">
          I agree to Spacegeni’s{" "}
          <a href="/#" className="text-primary text-decoration-none">
            Terms of Condition
          </a>{" "}
          and{" "}
          <a href="/#" className="text-primary text-decoration-none">
            Privacy Policy
          </a>
        </label>
      </div>
      <div className="d-grid">
        <button
          className="btn btn-primary rounded fw-500 fs-18"
          type="submit"
          disabled={
            errors.first_name ||
            errors.last_name ||
            errors.email ||
            errors.phone ||
            errors.password ||
            errors.confirmPassword ||
            !form.first_name ||
            !form.last_name ||
            !form.email ||
            !form.phone ||
            !form.password ||
            !form.confirmPassword ||
            !form.agree
          }
        >
          Sign Up
        </button>
      </div>
    </form>
  );
};
