// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar {
  border-radius: 1.30344rem;
  border: 0.948px solid #d5d6d9;
  box-shadow: 0px 0px 10px 0px rgba(92, 90, 90, 0.18);
  width: 100%;
  max-width: 20rem;
  padding: 1rem;
}

.calendar .days-of-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0.5rem;
  margin-top: 1rem;
}

.calendar .days-of-week span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  cursor: pointer;
  font-family: "Inter", sans-serif;
}

.calendar .dates-of-month {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0.5rem;
  margin-bottom: 1rem;
}

.calendar .dates-of-month span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  transition: all 0.3s ease-in-out;
}

.calendar .dates-of-month span:hover {
  background-color: #f1f1f1;
}

.calendar .dates-of-month span.selected {
  background-color: var(--primary-color);
  color: #fff !important;
}

.calendar .dates-of-month span.strikethrough {
  text-decoration: line-through;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/calendar/calendar.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,6BAA6B;EAC7B,mDAAmD;EACnD,WAAW;EACX,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,gCAAgC;EAChC,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sCAAsC;EACtC,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".calendar {\n  border-radius: 1.30344rem;\n  border: 0.948px solid #d5d6d9;\n  box-shadow: 0px 0px 10px 0px rgba(92, 90, 90, 0.18);\n  width: 100%;\n  max-width: 20rem;\n  padding: 1rem;\n}\n\n.calendar .days-of-week {\n  display: grid;\n  grid-template-columns: repeat(7, 1fr);\n  grid-gap: 0.5rem;\n  margin-top: 1rem;\n}\n\n.calendar .days-of-week span {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 2rem;\n  width: 2rem;\n  border-radius: 50%;\n  cursor: pointer;\n  font-family: \"Inter\", sans-serif;\n}\n\n.calendar .dates-of-month {\n  display: grid;\n  grid-template-columns: repeat(7, 1fr);\n  grid-gap: 0.5rem;\n  margin-bottom: 1rem;\n}\n\n.calendar .dates-of-month span {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 2rem;\n  width: 2rem;\n  border-radius: 50%;\n  cursor: pointer;\n  font-family: \"Inter\", sans-serif;\n  transition: all 0.3s ease-in-out;\n}\n\n.calendar .dates-of-month span:hover {\n  background-color: #f1f1f1;\n}\n\n.calendar .dates-of-month span.selected {\n  background-color: var(--primary-color);\n  color: #fff !important;\n}\n\n.calendar .dates-of-month span.strikethrough {\n  text-decoration: line-through;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
