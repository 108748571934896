import { createContext, useContext, useEffect, useState } from "react";
import { storageService } from "../lib/storage.service";

export const ListingFormContext = createContext(null);

export const ListingFormProvider = ({ children }) => {
  const [listingForm, setListingForm] = useState({
    title: "",
    description: "",
    price: {
      amount: 0.0,
      duration: "",
    },
    category_id: "",
    type: "",
    country: "",
    street: "",
    city: "",
    state: "",
    zip_code: "",
    space_size: "",
    show_address: true,
    latitude: "",
    longitude: "",
    first_reservation: "",
    fee_and_charges: 0,
    profession: "",
    verification_documents: [],
    new_listing_promotion: true,
    weekly_discount: false,
    monthly_discount: true,
    amenities: [],
    featured_image: "",
    images: [],
    weapon: false,
    security_cameras: false,
    dangerous_animals: true,
  });

  useEffect(() => {
    const listingForm = storageService.getListingForm();
    setListingForm(listingForm);
  }, []);

  return (
    <ListingFormContext.Provider value={{ listingForm, setListingForm }}>
      {children}
    </ListingFormContext.Provider>
  );
};

export const useListingFormContext = () => {
  const context = useContext(ListingFormContext);
  if (context === null) {
    throw new Error(
      "useListingFormContext must be used within a ListingFormProvider"
    );
  }
  return context;
};
