import React, { useState } from "react";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";
import { ReactComponent as ClosePrimary } from "../../../../assets/icons/close-primary.svg";
import { UncutModal } from "../../../ui/modal/modal";

export const CheckInMethod = ({ id, listingsId }) => {
  const [checkinMethod, setCheckinMethod] = useState("smart_lock");

  const methods = [
    {
      name: "Smart Lock",
      description: "A lock guests open with a mobile app or keypad",
      value: "smart_lock",
    },
    {
      name: "Keypad",
      description: "Guests can open the door with a code",
      value: "keypad",
    },
    {
      name: "Lockbox",
      description:
        "The key is stored in a small safe, which guests can open with a code",
      value: "lockbox",
    },
    {
      name: "Building Staff",
      description: "Someone is available 24 hours a day to let guests in",
      value: "building_staff",
    },
    {
      name: "Host greets you",
      description: "A host or co-host will meet guests to exchange the key",
      value: "host_greets_you",
    },
    {
      name: "Other",
      description: "Guests can use a different method not listed above",
      value: "other",
    },
  ];

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Edit Listing</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <div className="d-flex gap-4 align-items-center">
          <Chevron
            style={{ transform: "rotate(90deg)", cursor: "pointer" }}
            onClick={() => {
              document.getElementById(`close-${id}`).click();
            }}
            data-bs-toggle="modal"
            data-bs-target={`#${listingsId}`}
          />
          <h5 className="mb-0 fw-600">Check-in Method</h5>
        </div>
        <p className="mb-0 mt-2 mb-4">
          Clarify the usual way guests will check in to the selected listings.
        </p>
        <div className="form">
          {methods.map((method, index) => (
            <div className="my-4 border rounded-3 d-flex p-3 gap-2" key={index}>
              <input
                type="checkbox"
                id={method.value}
                name={method.value}
                className="d-none"
                onChange={() => setCheckinMethod(method.value)}
                checked={checkinMethod === method.value}
              />
              <label
                htmlFor={method.value}
                className="form-checkbox-rounded"
              ></label>
              <label
                htmlFor={method.value}
                className="form-label fw-500 mb-0 pointer"
              >
                <h5 className="mb-0 fw-500 fs-18">{method.name}</h5>
                <small className="text-muted">{method.description}</small>
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="modal-footer d-flex align-items-center px-4">
        <button
          type="button"
          className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Back
        </button>
        <button
          type="button"
          className="btn btn-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Save
        </button>
      </div>
    </UncutModal>
  );
};
