import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as BakingHat } from "../../../../assets/icons/baking-hat.svg";
import { ReactComponent as Beverage } from "../../../../assets/icons/beverage.svg";
import { ReactComponent as Brewery } from "../../../../assets/icons/brewery.svg";
import { ReactComponent as Catering } from "../../../../assets/icons/catering.svg";
import { ReactComponent as CookingPot } from "../../../../assets/icons/cooking-pot.svg";
import { useSearchWizardContext } from "../../../../context/SearchWizardContext";
import "./GetStarted.css";

export const GetStarted = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const { searchWizard, setSearchWizard } = useSearchWizardContext();

  const [profession, setProfession] = useState("");

  const professions = [
    {
      id: 1,
      name: "Caterer / Emerging Restauranter",
      description: "For caterers and emerging restauranteurs looking to grow",
      icon: <Catering />,
    },
    {
      id: 2,
      name: "Baker/ Confectioner",
      description: "For caterers and emerging restauranteurs looking to grow",
      icon: <BakingHat />,
    },
    {
      id: 3,
      name: "Meal Prep Provider",
      description: "For caterers and emerging restauranteurs looking to grow",
      icon: <CookingPot />,
    },
    {
      id: 4,
      name: "Brewer",
      description: "For caterers and emerging restauranteurs looking to grow",
      icon: <Brewery />,
    },
    {
      id: 5,
      name: "Speciality Beverage Maker",
      description: "For caterers and emerging restauranteurs looking to grow",
      icon: <Beverage />,
    },
  ];

  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 mb-7">
      <div className="col-6 mx-auto flex-grow-1">
        <h5 className="text-center fs-36 fw-600 pt-3">
          What is your profession?
        </h5>
        <p className="text-center mb-0 fs-18">
          Choose how you want to proceed and we'll help you get started.
        </p>
        <div className="row g-0 mt-5">
          {professions.map((prof, index) => {
            return (
              <div className="col-12" key={index}>
                <label
                  className="my-2 rounded-2 d-flex justify-content-between align-items-center p-4 gap-2 space-card"
                  htmlFor={prof.name}
                >
                  <div
                    style={{
                      width: "4rem",
                      textAlign: "center",
                    }}
                  >
                    {prof.icon}
                  </div>
                  <div className="form-label fw-500 mb-0 pointer w-75">
                    <h5 className="mb-0 fw-500 fs-18">{prof.name}</h5>
                    <p className="mb-0 fs-14">{prof.description}</p>
                  </div>
                  <input
                    type="radio"
                    id={prof.name}
                    name="profession"
                    value={prof.name}
                    className="d-none"
                    onChange={(e) => setProfession(e.target.value)}
                    checked={prof.name === profession}
                  />
                  <label
                    htmlFor={prof.name}
                    className="form-checkbox-rounded"
                  ></label>
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <div className="d-flex justify-content-end mt-5 bg-white shadow w-100 started-footer">
        <div className="p-2">
          <button
            className="btn btn-primary btn-lg px-5 fw-600 py-3"
            disabled={profession === ""}
            onClick={() => {
              setSearchWizard({ ...searchWizard, profession });
              navigate("/find-space/search", { state: location.state });
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
