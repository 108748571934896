import React from "react";

export const SpaceAppliancesForm = ({
  searchWizard,
  setSearchWizard,
  getSearchResults,
  amenitiesList,
}) => {
  return (
    <>
      <h5 className="fs-24 fw-600">What are your preferred appliances?</h5>
      <p className="mb-0 text-muted">
        Tell us how long do you need the space for.
      </p>
      <div className="row g-0">
        {amenitiesList?.map((item) => (
          <div className="col-12" key={item.id}>
            <label
              className="my-3 rounded-2 d-flex align-items-center p-4 py-3 gap-2 border rounded-3 pointer"
              htmlFor={item.id}
            >
              <input
                type="checkbox"
                id={item.id}
                name={item.name}
                value={item.id}
                onChange={async (e) => {
                  if (e.target.checked) {
                    setSearchWizard({
                      ...searchWizard,
                      amenities: [
                        ...(searchWizard.amenities || []),
                        e.target.value,
                      ],
                    });
                    await getSearchResults();
                  } else {
                    setSearchWizard({
                      ...searchWizard,
                      amenities: searchWizard.amenities.filter(
                        (amenity) => amenity !== e.target.value
                      ),
                    });
                    await getSearchResults();
                  }
                }}
                className="d-none"
              />
              <label htmlFor={item.id} className="form-checkbox"></label>
              <div className="form-label fw-500 mb-0 pointer w-75">
                <h5 className="mb-0 fw-500 fs-16">{item.name}</h5>
              </div>
            </label>

            {/* // Show options if item has options and is selected */}
            {/* {item.options &&
              appliances.find((app) => app.value === item.value) && (
                <div className="col-12 row g-0 mt-2 p-3">
                  {item.options.map((option) => (
                    <div
                      className="mb-3 col-6 d-flex align-items-center"
                      key={option.id}
                    >
                      <input
                        type="checkbox"
                        id={option.value}
                        name={option.value}
                        className="d-none"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setAppliances(
                              appliances.map((app) => {
                                if (app.value === item.value) {
                                  return {
                                    ...app,
                                    options: [
                                      ...app.options,
                                      { value: e.target.value },
                                    ],
                                  };
                                }
                                return app;
                              })
                            );
                          } else {
                            setAppliances(
                              appliances.map((app) => {
                                if (app.value === item.value) {
                                  return {
                                    ...app,
                                    options: app.options.filter(
                                      (opt) => opt.value !== e.target.value
                                    ),
                                  };
                                }
                                return app;
                              })
                            );
                          }
                        }}
                      />
                      <label
                        htmlFor={option.value}
                        className="form-checkbox"
                      ></label>
                      <label
                        htmlFor={option.value}
                        className="form-label fw-500 mb-0 pointer"
                      >
                        {option.name}
                      </label>
                    </div>
                  ))}
                </div>
              )} */}
          </div>
        ))}
      </div>
    </>
  );
};
