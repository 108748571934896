import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Checkmark } from "../../../../assets/icons/checkmark-circle-lg.svg";
import { useReservationFormContext } from "../../../../context/ReservationFormContext";
import { useSpinnerContext } from "../../../../context/SpinnerContext";
import { getListing } from "../../../../services/listings.service";

export const ReservationSuccess = () => {
  const navigate = useNavigate();

  const { setSpinner } = useSpinnerContext();

  const { reservationForm } = useReservationFormContext();

  const [listing, setListing] = useState(null);

  const fetchListing = useCallback(async () => {
    try {
      setSpinner(true);
      const listingId = window.location.pathname.split("/")[3];
      const resp = await getListing(listingId);
      if (resp.status === 201 || resp.status === 200) {
        setListing(resp.result);
      }
      setSpinner(false);
    } catch (ex) {
      setSpinner(false);
      console.log(ex);
    }
  }, [setListing, setSpinner]);

  useEffect(() => {
    const init = async () => {
      await fetchListing();
    };

    init();
  }, [fetchListing]);

  return (
    <div className="border rounded-4 col-4 mx-auto my-5 p-5">
      <div className="text-center mt-3">
        <Checkmark width={100} height={100} />
      </div>
      <h3 className="text-center my-3 fw-600">Your reservation is confirmed</h3>
      <img
        src={listing?.featured_image || "https://via.placeholder.com/500x300"}
        alt="reservation"
        className="w-100 rounded-4"
        height={400}
        style={{ objectFit: "cover" }}
      />
      <h5 className="mt-3 fw-600">{listing?.title || "Space Title"}</h5>
      <p className="text-muted mt-2">
        We’ll call or text to confirm your number. Standard messaging rates
        apply.
      </p>
      <div className="my-3">
        <h6 className="fs-14 text-muted fw-500">Address</h6>
        <b className="fs-16 fw-600">
          {listing?.city && listing?.city + ","}{" "}
          {listing?.state && listing?.state + ","} {listing?.country}
        </b>
      </div>
      <div className="row g-0 gy-4 mb-4">
        <div className="col-6">
          <h6 className="fs-14 text-muted fw-500">Check-in</h6>
          <b className="fs-16 fw-600">
            {new Intl.DateTimeFormat("en-US", {
              weekday: "short",
              month: "short",
              day: "numeric",
            }).format(new Date(reservationForm?.checkin_datetime)) +
              " at " +
              new Intl.DateTimeFormat("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }).format(new Date(reservationForm?.checkin_datetime))}
          </b>
        </div>
        <div className="col-6">
          <h6 className="fs-14 text-muted fw-500">Checkout</h6>
          <b className="fs-16 fw-600">
            {new Intl.DateTimeFormat("en-US", {
              weekday: "short",
              month: "short",
              day: "numeric",
            }).format(new Date(reservationForm?.checkout_datetime)) +
              " at " +
              new Intl.DateTimeFormat("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }).format(new Date(reservationForm?.checkout_datetime))}
          </b>
        </div>
        <div className="col-6">
          <h6 className="fs-14 text-muted fw-500">Price</h6>
          <b className="fs-16 fw-600">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(listing?.price || 0)}
          </b>
        </div>
        {/* <div className="col-6">
          <h6 className="fs-14 text-muted fw-400">Reservation Code</h6>
          <b className="fs-16">20 Baron Street, Boston, MA, 90902</b>
        </div> */}
      </div>
      <button
        className="btn btn-primary w-100 fw-600 py-3 mb-4 rounded-3"
        onClick={() => navigate(`/find-space/space/${listing?.id}`)}
      >
        View Full Itineraryt
      </button>
      <button
        className="btn btn-outline-primary w-100 fw-600 py-3 rounded-3"
        onClick={() => navigate(-1)}
      >
        Change Your Reservation
      </button>
    </div>
  );
};
