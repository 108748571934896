import React from "react";

export const TaxDocuments = () => {
  return (
    <div className="p-4">
      <p className="text-muted fs-14 mb-5 fw-500" style={{ maxWidth: "50rem" }}>
        Tax documents required for filing taxes are available to review and
        download here. You can also file taxes using detailed earnings info,
        available in the{" "}
        <a href="#!" className="fw-600 text-dark">
          earnings summary.
        </a>
      </p>
      <div className="col-12 border-bottom pb-4">
        <h5 className="fw-bold text-dark fs-18 mb-0 fw-600 my-3">2023</h5>
        <p className="text-muted fs-14 mb-0 fw-500 mt-1">
          No tax document issued
        </p>
      </div>
      <div className="col-12 border-bottom pb-4">
        <h5 className="fw-bold text-dark fs-18 mb-0 fw-600 my-3">2022</h5>
        <p className="text-muted fs-14 mb-0 fw-500 mt-1">
          No tax document issued
        </p>
      </div>
      <div className="col-12 border-bottom pb-4">
        <h5 className="fw-bold text-dark fs-18 mb-0 fw-600 my-3">2021</h5>
        <p className="text-muted fs-14 mb-0 fw-500 mt-1">
          No tax document issued
        </p>
      </div>
      <div className="col-12 border-bottom pb-4">
        <h5 className="fw-bold text-dark fs-18 mb-0 fw-600 my-3">2020</h5>
        <p className="text-muted fs-14 mb-0 fw-500 mt-1">
          No tax document issued
        </p>
      </div>
      <div className="col-12 border-bottom pb-4">
        <h5 className="fw-bold text-dark fs-18 mb-0 fw-600 my-3">2019</h5>
        <p className="text-muted fs-14 mb-0 fw-500 mt-1">
          No tax document issued
        </p>
      </div>
      <div className="col-12 border-bottom pb-4">
        <h5 className="fw-bold text-dark fs-18 mb-0 fw-600 my-3">2018</h5>
        <p className="text-muted fs-14 mb-0 fw-500 mt-1">
          No tax document issued
        </p>
      </div>
      <div className="col-12 border-bottom pb-4">
        <h5 className="fw-bold text-dark fs-18 mb-0 fw-600 my-3">2017</h5>
        <p className="text-muted fs-14 mb-0 fw-500 mt-1">
          No tax document issued
        </p>
      </div>
    </div>
  );
};
