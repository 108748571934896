import React from "react";
import { ReactComponent as CalendarOutline } from "../../../../assets/icons/calendar-outline.svg";
import { ReactComponent as Document } from "../../../../assets/icons/document.svg";
import { ReactComponent as Edit } from "../../../../assets/icons/edit.svg";
import { ReactComponent as Star } from "../../../../assets/icons/star.svg";
import "./ReviewListing.css";

export const ReviewListing = ({ listingForm }) => {
  return (
    <div className="row g-0 justify-content-center mt-4">
      <div className="col-12 col-md-7">
        <h5 className="mb-2 text-center fs-36 fw-600">Review your listing</h5>
        <p className="mb-4 fw-500 fs-16 text-center text-muted">
          Here’s what we’ll show to guests. Make sure everything looks great!
        </p>
        <div className="d-flex flex-nowrap gap-3">
          <div className="card overview-card">
            <div className="card-body">
              <img
                src={
                  listingForm?.images?.[0] || "https://via.placeholder.com/150"
                }
                alt=""
                className="rounded-3"
                style={{ objectFit: "cover" }}
              />
              <h5 className="card-title mt-3 mb-2 fs-20 fw-600">
                {listingForm?.title}
              </h5>
              <p className="card-text mb-0 fw-500 fs-14 text-muted">
                {listingForm?.country && `${listingForm?.country},`}
                {listingForm?.state && `${listingForm?.state},`}
                {listingForm?.street && `${listingForm?.street},`}
                {listingForm?.city}
              </p>
              <p className="card-text mb-0 fw-500 fs-14 text-muted">
                New <Star className="ms-1" />
              </p>
            </div>
          </div>
          <div className="p-3 col-12 col-md-7">
            <h5 className="mb-5 mt-3 fw-600">What’s next?</h5>
            <div className="d-flex align-items-center gap-3 mb-4">
              <Document className="me-2" />
              <div>
                <h6 className="mb-0 fw-600">
                  Confirm a few details and publish
                </h6>
                <p className="mb-0 fw-500 text-muted">
                  We’ll let you know if you need to verify your identity or
                  register with the local government.
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center gap-3 mb-4">
              <CalendarOutline className="me-2" />
              <div>
                <h6 className="mb-0 fw-600">Set up your calendar</h6>
                <p className="mb-0 fw-500 text-muted">
                  Choose which dates your listing is available. It will be
                  visible 24 hours after you publish.
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center gap-3 mb-4">
              <Edit className="me-2" />
              <div>
                <h6 className="mb-0 fw-600">Adjust your settings</h6>
                <p className="mb-0 fw-500 text-muted">
                  Set house rules, select a cancellation policy, choose how
                  guests book, and more.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
