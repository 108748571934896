import React from "react";
import "./SpaceLastStep.css";

export const SpaceLastStep = ({ listingForm, setListingForm }) => {
  const amenitiesList = [
    {
      id: 1,
      name: "Security camera(s)",
      value: "security_cameras",
      checked: false,
    },
    {
      id: 2,
      name: "Weapons",
      value: "weapons",
      checked: false,
    },
    {
      id: 3,
      name: "Dangerous animals",
      value: "dangerous_animals",
      checked: false,
    },
  ];

  return (
    <div className="row g-0 justify-content-center mt-4">
      <div className="col-12 col-md-4">
        <h5 className="mb-2 text-center fs-36 fw-600">Just one last step!</h5>
        <p className="mb-5 fw-500 fs-16 text-center">
          Help your space stand out to get booked faster and earn your first
          reviews.
        </p>
        <h5 className="mb-2 fs-26 fw-500">
          Does your space have any of these?
        </h5>
        {amenitiesList?.map((item) => (
          <div className="col-12" key={item.id}>
            <label
              className="my-3 d-flex justify-content-between align-items-center py-3 gap-2"
              htmlFor={item.id}
            >
              <div className="form-label fw-500 mb-0 pointer w-75">
                <h5 className="mb-0 fw-500 fs-16">{item.name}</h5>
              </div>
              <input
                type="checkbox"
                id={item.id}
                name={item.name}
                checked={listingForm?.[item.value] ?? false}
                onChange={(e) => {
                  setListingForm((prevForm) => ({
                    ...prevForm,
                    [item.value]: e.target.checked,
                  }));
                }}
                className="d-none"
              />
              <label htmlFor={item.id} className="form-checkbox"></label>
            </label>
          </div>
        ))}
        <p className="mb-2 mt-5 fw-600 fs-16 text-center">
          Important things to know
        </p>
        <p className="mb-5 fs-14 text-center w-75 mx-auto">
          Be sure to comply with your local laws and review Spacegeni’s
          non-discrimination policy and guest and host fees.
        </p>
      </div>
    </div>
  );
};
