import React from "react";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook-circle.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/icons/instagram-circle.svg";
import { ReactComponent as LinkedInIcon } from "../../../assets/icons/linkedin-circle.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/icons/twitter-circle.svg";
import { ReactComponent as Logo } from "../../../assets/images/dark-logo.svg";
import "./main-footer.css";

export const MainFooter = () => {
  const socialLinks = [
    {
      id: 1,
      name: "twitter",
      link: "https://twitter.com/",
      icon: <TwitterIcon width={30} height={30} />,
    },
    {
      id: 2,
      name: "facebook",
      link: "https://facebook.com/",
      icon: <FacebookIcon width={30} height={30} />,
    },
    {
      id: 3,
      name: "instagram",
      link: "https://instagram.com/",
      icon: <InstagramIcon width={30} height={30} />,
    },
    {
      id: 4,
      name: "linkedin",
      link: "https://linkedin.com/",
      icon: <LinkedInIcon width={30} height={30} />,
    },
  ];

  const footerLinks = [
    {
      id: 1,
      title: "Company",
      links: [
        {
          id: 1,
          name: "About Us",
          link: "/",
        },
        {
          id: 2,
          name: "Careers",
          link: "/",
        },
        {
          id: 3,
          name: "Privacy Policy",
          link: "/",
        },
        {
          id: 4,
          name: "Terms of Service",
          link: "/",
        },
        {
          id: 5,
          name: "Legal",
          link: "/",
        },
      ],
    },
    {
      id: 2,
      title: "Hosting",
      links: [
        {
          id: 1,
          name: "List your space",
          link: "/",
        },
        {
          id: 2,
          name: "Hosting Resources",
          link: "/",
        },
        {
          id: 3,
          name: "Community Forum",
          link: "/",
        },
        {
          id: 4,
          name: "Cancellation Poilcy",
          link: "/",
        },
      ],
    },
    {
      id: 3,
      title: "Support",
      links: [
        {
          id: 1,
          name: "Contact Us",
          link: "/",
        },
        {
          id: 2,
          name: "FAQs",
          link: "/",
        },
        {
          id: 3,
          name: "Help Center",
          link: "/",
        },
        {
          id: 4,
          name: "Blog",
          link: "/",
        },
      ],
    },
  ];

  return (
    <div className="footer-container pb-5">
      <div className="container">
        <div className="row g-0 gx-4">
          <div className="col-12 col-md">
            <div className="footer-logo mt-5">
              <Logo width="10rem" height="5rem" />
            </div>
            <p className="mb-0 fw-500 fs-16">
              Find production spaces for business needs <br></br> like never
              before!
            </p>
            <div
              className="d-flex align-items-center gap-2"
              style={{ marginTop: "8rem" }}
            >
              {socialLinks.map((socialLink) => (
                <a
                  href={socialLink.link}
                  target="_blank"
                  className="social-link"
                  rel="noreferrer"
                  key={socialLink.id}
                >
                  {socialLink.icon}
                </a>
              ))}
            </div>
          </div>
          {footerLinks.map((footerLink) => (
            <div className="col col-md-2" key={footerLink.id}>
              <div
                className="d-flex flex-column gap-3"
                style={{ marginTop: "4.2rem" }}
              >
                <h4 className="mb-0 fw-600" style={{ fontSize: "1.2rem" }}>
                  {footerLink.title}
                </h4>
                {footerLink.links.map((link) => (
                  <a
                    href={link.link}
                    className="text-decoration-none text-dark fw-500 text-muted footer-link"
                    key={link.id}
                  >
                    {link.name}
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
        <p className="text-center text-muted mt-5 mb-0 fw-500">
          Copyright © {new Date().getFullYear()} - Spacegeni. All Rights
          Reserved.
        </p>
      </div>
    </div>
  );
};
