import React from "react";
import "./modal.css";

export const UncutModal = ({
  id,
  size,
  children,
  right = false,
  backdrop = true,
  backdropColor,
  keyboard = true,
  centered = true,
  scrollable = false,
}) => {
  return (
    <div
      className={`modal fade ${right ? "right" : ""}`}  
      style={{ backgroundColor: backdropColor || "initial" }}
      id={id}
      tabIndex="-1"
      aria-labelledby={id + "Label"}
      aria-hidden="true"
      data-bs-backdrop={backdrop}
      data-bs-keyboard={keyboard}
    >
      <div
        className={`modal-dialog modal-dialog-centered ${
          size === "lg"
            ? "modal-lg"
            : size === "sm"
            ? "modal-sm"
            : size === "xl"
            ? "modal-xl"
            : ""
        } ${centered ? "modal-dialog-centered" : ""} ${
          scrollable ? "modal-dialog-scrollable" : ""
        }`}
      >
        <div
          className={`modal-content border-0 ${
            backdropColor ? "shadow-lg" : ""
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
