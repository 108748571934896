import { Country } from "country-state-city";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron.svg";
import { ReactComponent as Ellipsis } from "../../../assets/icons/ellipsis.svg";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { NoData } from "../../../components/application_components/NoData/NoData";
import { CreateListingsModal } from "../../../components/modals/CreateListingsModal/CreateListingsModal";
import { Amenities } from "../../../components/modals/EditListingsModal/Amenities/Amenities";
import { BookingDuration } from "../../../components/modals/EditListingsModal/BookingDuration/BookingDuration";
import { CancellationPolicy } from "../../../components/modals/EditListingsModal/CancellationPolicy/CancellationPolicy";
import { CheckInMethod } from "../../../components/modals/EditListingsModal/CheckInMethod/CheckInMethod";
import { EditListingsModal } from "../../../components/modals/EditListingsModal/EditListingsModal";
import { FeesAndCharges } from "../../../components/modals/EditListingsModal/FeesAndCharges/FeesAndCharges";
import { LengthOfStay } from "../../../components/modals/EditListingsModal/LengthOfStay/LengthOfStay";
import { ListingStatus } from "../../../components/modals/EditListingsModal/ListingStatus/ListingStatus";
import { ListingSyncSettings } from "../../../components/modals/EditListingsModal/ListingSyncSettings/ListingSyncSettings";
import { Location } from "../../../components/modals/EditListingsModal/Location/Location";
import { PropertyType } from "../../../components/modals/EditListingsModal/PropertyType/PropertyType";
import { Rules } from "../../../components/modals/EditListingsModal/Rules/Rules";
import { ListingFormProvider } from "../../../context/ListingFormContext";
import { useSpinnerContext } from "../../../context/SpinnerContext";
import { SearchSpinner } from "../../../lib/spinner.service";
import { toastService } from "../../../lib/toast.service";
import {
  filterListings,
  getAmenities,
  getListings,
  getListingsCategories,
  getMyAmenities,
  removeListing,
  updateAmenities,
} from "../../../services/listings.service";
import "./Listings.css";

export const Listings = () => {
  const { setSpinner } = useSpinnerContext();

  const navigate = useNavigate();

  const listingStatus = [
    {
      id: 1,
      name: "Active",
      value: "Active",
    },
    {
      id: 2,
      name: "Inactive",
      value: "Inactive",
    },
  ];

  // const [filterFormActive, setFilterFormActive] = useState(false);

  const [listingStatusActive, setListingStatusActive] = useState(false);

  const [amenitiesActive, setAmenitiesActive] = useState(false);

  const [searching, setSearching] = useState(false);

  const [listings, setListings] = useState([]);

  const [categories, setCategories] = useState([]);

  const [amenitiesList, setAmenitiesList] = useState([]);

  const [myAmenitiesList, setMyAmenitiesList] = useState([]);

  const [listing, setListing] = useState({});

  const [filters, setFilters] = useState({
    search_text: "",
    filter_amenities: [],
    filter_status: "",
  });

  const fetchListings = useCallback(async () => {
    const resp = await getListings();
    setListings(resp.results);
  }, []);

  const fetchListingsCategories = useCallback(async () => {
    const resp = await getListingsCategories();
    setCategories(resp.result);
  }, []);

  const fetchAmenities = useCallback(async () => {
    const resp = await getAmenities();
    setAmenitiesList(resp.result);
  }, []);

  const fetchMyAmenities = useCallback(async () => {
    const resp = await getMyAmenities();
    setMyAmenitiesList(resp.result);
  }, []);

  const updateListingAmenities = async (amenities) => {
    setSpinner(true);
    const resp = await updateAmenities({ space_id: listing.id, amenities });
    toastService.success(resp.message);
    await fetchListings();
    setSpinner(false);
  };

  const deleteListing = async (id) => {
    if (!window.confirm("Are you sure you want to delete this listing?")) {
      return;
    }
    setSpinner(true);
    const resp = await removeListing(id);
    toastService.success(resp.message);
    await fetchListings();
    setSpinner(false);
  };

  useEffect(() => {
    const init = async () => {
      setSpinner(true);
      await fetchListingsCategories();
      await fetchListings();
      await fetchAmenities();
      await fetchMyAmenities();
      setSpinner(false);
    };

    init();
  }, [
    fetchListingsCategories,
    fetchListings,
    fetchAmenities,
    fetchMyAmenities,
    setSpinner,
  ]);

  const getCountry = (country) => {
    const countries = Country.getAllCountries();
    const countryObj = countries.find(
      (item) => item.name.toLowerCase() === country.toLowerCase()
    );
    return countryObj ? countryObj.isoCode : "";
  };

  const getFilteredListings = async (
    search_text,
    filter_amenities,
    filter_status
  ) => {
    const resp = await filterListings({
      search_text,
      filter_amenities,
      filter_status,
    });
    setListings(resp.result);
  };

  return (
    <div className="h-100">
      <div className="d-flex justify-content-between gap-2 border-bottom pb-3 px-4 px-md-3">
        <h4 className="fw-600 text-dark fs-28 mb-0">Listings</h4>
        <div className="d-flex align-items-center gap-3">
          <button
            className="btn btn-primary fw-600 p-3 py-2 ps-2"
            data-bs-toggle="modal"
            data-bs-target="#createListingModal"
          >
            <Plus width={20} height={20} /> Create listing
          </button>
        </div>
      </div>
      <>
        <div className="d-flex align-items-center justify-content-between gap-2 px-4 px-md-3 my-3 flex-wrap">
          <div className="d-flex align-items-center gap-2 flex-grow-1">
            <div className="d-flex align-items-center gap-2 search-input">
              <Search />
              <input
                type="text"
                placeholder="Search Listings"
                id="search"
                value={filters.search_text}
                onChange={async (e) => {
                  setFilters({ ...filters, search_text: e.target.value });
                  if (filters.search_text === "") {
                    setSearching(true);
                    setTimeout(async () => {
                      await fetchListings();
                      setSearching(false);
                    }, 1000);
                  } else {
                    setSearching(true);
                    setTimeout(async () => {
                      await getFilteredListings(
                        filters.search_text,
                        filters.filter_amenities,
                        filters.filter_status
                      );
                      setSearching(false);
                    }, 1000);
                  }
                }}
              />
            </div>
            <SearchSpinner loading={searching} />
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className="position-relative">
              <button
                className={`btn btn-secondary px-3 text-dark fw-600 ${
                  amenitiesActive ? "active" : ""
                }`}
                onClick={() => {
                  setAmenitiesActive(!amenitiesActive);
                  window.addEventListener("mousedown", (e) => {
                    // check if parent of target is amenitiesContainer else close amenitiesContainer
                    if (!e.target.closest("#amenitiesContainer")) {
                      setAmenitiesActive(false);
                    }
                  });
                }}
                style={{ whiteSpace: "nowrap" }}
              >
                Amenities <Chevron className="ms-2" />
              </button>
              <div
                className={`filter-container more ${
                  amenitiesActive ? "active" : ""
                }`}
                id="amenitiesContainer"
              >
                <div className="modal-body px-4">
                  <div className="col-12 row g-0 mt-2 p-3">
                    {myAmenitiesList?.map((item) => (
                      <div
                        className="mb-3 col-6 d-flex align-items-center"
                        key={item.id}
                      >
                        <input
                          type="checkbox"
                          id={item.id}
                          name={item.name}
                          className="d-none"
                          checked={filters.filter_amenities.includes(item.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFilters({
                                ...filters,
                                filter_amenities: [
                                  ...filters.filter_amenities,
                                  item.id,
                                ],
                              });
                            } else {
                              setFilters({
                                ...filters,
                                filter_amenities:
                                  filters.filter_amenities.filter(
                                    (amenity) => amenity !== item.id
                                  ),
                              });
                            }
                          }}
                        />
                        <label
                          htmlFor={item.id}
                          className="form-checkbox"
                        ></label>
                        <label
                          htmlFor={item.id}
                          className="form-label fw-500 mb-0 pointer"
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}
                  </div>

                  <div className="d-flex align-items-center justify-content-between border-top mt-2 py-3">
                    <button className="btn btn-link text-decoration-none text-muted fw-600 fs-18">
                      Clear
                    </button>
                    <button
                      className="btn btn-primary fw-600 fs-18 px-5 py-2"
                      onClick={async () => {
                        setAmenitiesActive(false);
                        await getFilteredListings(
                          filters.search_text,
                          filters.filter_amenities,
                          filters.filter_status
                        );
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="position-relative">
              <button
                className={`btn btn-secondary px-3 text-dark fw-600 ${
                  listingStatusActive ? "active" : ""
                }`}
                onClick={() => {
                  setListingStatusActive(!listingStatusActive);
                  window.addEventListener("mousedown", (e) => {
                    // check if parent of target is filterContainer else close filterContainer
                    if (!e.target.closest("#listingsContainer")) {
                      setListingStatusActive(false);
                    }
                  });
                }}
                style={{ whiteSpace: "nowrap" }}
              >
                Listing Status <Chevron className="ms-2" />
              </button>
              <div
                className={`filter-container more ${
                  listingStatusActive ? "active" : ""
                }`}
                id="listingsContainer"
              >
                <div className="modal-body px-4">
                  <div className="col-12 mt-2 p-3">
                    {listingStatus.map((item) => (
                      <div
                        className="mb-3 d-flex align-items-center"
                        key={item.id}
                      >
                        <input
                          type="checkbox"
                          id={item.value}
                          name={item.value}
                          className="d-none"
                          onChange={(e) => {
                            setFilters({
                              ...filters,
                              filter_status: e.target.checked ? item.value : "",
                            });
                          }}
                        />
                        <label
                          htmlFor={item.value}
                          className="form-checkbox"
                        ></label>
                        <label
                          htmlFor={item.value}
                          className="form-label fw-500 mb-0 pointer"
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}
                  </div>

                  <div className="d-flex align-items-center justify-content-between border-top mt-2 py-3">
                    <button className="btn btn-link text-decoration-none text-muted fw-600 fs-18">
                      Clear
                    </button>
                    <button
                      className="btn btn-primary fw-600 fs-18 px-5 py-2"
                      onClick={async () => {
                        setListingStatusActive(false);
                        await getFilteredListings(
                          filters.search_text,
                          filters.filter_amenities,
                          filters.filter_status
                        );
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {listings.length === 0 && (
          <div className="h-75 d-flex align-items-center justify-content-center">
            <NoData title="Listings" />
          </div>
        )}
        {listings.length > 0 && (
          <div className="table-responsive h-100 px-4 px-md-3">
            <table className="booking-table">
              <tbody>
                {listings.map((listing, index) => (
                  <tr className="booking-row" key={index}>
                    <td className="booking-cell">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={
                            listing.featured_image ||
                            "https://via.placeholder.com/50"
                          }
                          alt=""
                          className="avatar"
                        />
                        <div>
                          <p
                            className="fw-600 mb-0 truncate"
                            style={{ maxWidth: "200px" }}
                          >
                            {listing.title}
                          </p>
                          <p className="mb-0 text-muted">{listing.street}</p>
                        </div>
                      </div>
                    </td>
                    <td className="booking-cell">
                      <p className="mb-0 text-muted">Pricing</p>
                      <a href="#!" className="fw-600 text-primary">
                        ${listing.price}
                      </a>
                    </td>
                    <td className="booking-cell">
                      <p className="mb-0 text-muted">Status</p>
                      <b>{listing.status || "N/A"}</b>
                    </td>
                    <td className="booking-cell">
                      <p className="mb-0 text-muted">Stay Duration</p>
                      <b>{listing.stay_duration || "N/A"}</b>
                    </td>
                    <td className="booking-cell">
                      <p className="mb-0 text-muted">Type</p>
                      <b>{listing.type}</b>
                    </td>
                    <td className="booking-cell">
                      <p className="mb-0 text-muted">Country</p>
                      <b>
                        {listing.country}{" "}
                        <span className="text-muted">
                          ({getCountry(listing.country)})
                        </span>
                      </b>
                    </td>
                    <td className="booking-cell">
                      <p className="mb-0 text-muted">Location</p>
                      <b className="truncate" style={{ maxWidth: "200px" }}>
                        {listing.street}
                      </b>
                    </td>
                    <td className="booking-cell">
                      <div
                        className="btn btn-link p-0 position-relative"
                        onClick={(e) => {
                          let dropdownMenu =
                            e.target.parentElement.querySelector(
                              ".res-dropdown-menu"
                            );
                          dropdownMenu &&
                            (dropdownMenu.style.display = "block");

                          window.addEventListener("mousedown", (e) => {
                            if (
                              dropdownMenu &&
                              !e.target.closest(".res-dropdown-menu")
                            ) {
                              dropdownMenu.style.display = "none";
                            }
                          });
                        }}
                      >
                        <Ellipsis width={25} height={25} />
                        <div className="res-dropdown-menu listing-dropdown">
                          <button
                            className="custom-dropdown-item"
                            onClick={() => {
                              navigate(`/find-space/space/${listing.id}`);
                            }}
                          >
                            <div className="d-flex align-items-center gap-3">
                              <span>View Details</span>
                            </div>
                          </button>
                          <button
                            className="custom-dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#listingModal"
                            onClick={() => setListing(listing)}
                          >
                            <div className="d-flex align-items-center gap-3">
                              <span>Edit</span>
                            </div>
                          </button>
                          <button
                            className="custom-dropdown-item"
                            onClick={() => deleteListing(listing.id)}
                          >
                            <div className="d-flex align-items-center gap-3">
                              <span>Delete</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <EditListingsModal
          id="listingModal"
          feesandchargesId="feesModal"
          lengthofstayId="lengthOfStayModal"
          amenitiesId="amenitiesModal"
          locationId="locationModal"
          propertytypeId="propertyTypeModal"
          checkinmethodId="checkInMethodModal"
          listingSyncSettingsId="listingSyncSettingsModal"
          listingStatusId="listingStatusModal"
          cancellationPolicyId="cancellationPolicyModal"
          rulesId="rulesModal"
          bookingDurationId="bookingDurationModal"
        />
        <FeesAndCharges
          id="feesModal"
          listingsId="listingModal"
          listing={listing}
          setListing={setListing}
        />
        <LengthOfStay id="lengthOfStayModal" listingsId="listingModal" />
        <Amenities
          id="amenitiesModal"
          listingsId="listingModal"
          amenities={amenitiesList}
          listingAmenities={listing.amenities}
          updateListingAmenities={updateListingAmenities}
        />
        <Location id="locationModal" listingsId="listingModal" />
        <PropertyType id="propertyTypeModal" listingsId="listingModal" />
        <CheckInMethod id="checkInMethodModal" listingsId="listingModal" />
        <ListingSyncSettings
          id="listingSyncSettingsModal"
          listingsId="listingModal"
        />
        <ListingStatus id="listingStatusModal" listingsId="listingModal" />
        <CancellationPolicy
          id="cancellationPolicyModal"
          listingsId="listingModal"
        />
        <Rules id="rulesModal" listingsId="listingModal" />
        <BookingDuration id="bookingDurationModal" listingsId="listingModal" />
        {/* Create Listing Modal */}
        <ListingFormProvider>
          <CreateListingsModal
            id="createListingModal"
            categories={categories}
            amenities={amenitiesList}
            fetchListings={fetchListings}
          />
        </ListingFormProvider>
      </>
    </div>
  );
};
