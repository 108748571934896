// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="checkbox"] {
  display: none;
}

.form-toggle-input {
  border-radius: 3rem;
  background: #d8d8d8;
  width: 3.22919rem;
  height: 1.9375rem;
  margin-right: 0.7rem;
  position: relative;
  transition: 0.3s;
}

.form-toggle-input:before {
  content: "";
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.5625rem;
  height: 1.5625rem;
  border-radius: 50%;
  background: #fff;
  transition: 0.3s;
  cursor: pointer;
}

input[type="checkbox"]:checked + .form-toggle-input {
  background: var(--primary-color);
}

input[type="checkbox"]:checked + .form-toggle-input:before {
  left: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/LoginForm/LoginForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,YAAY;AACd","sourcesContent":["input[type=\"checkbox\"] {\n  display: none;\n}\n\n.form-toggle-input {\n  border-radius: 3rem;\n  background: #d8d8d8;\n  width: 3.22919rem;\n  height: 1.9375rem;\n  margin-right: 0.7rem;\n  position: relative;\n  transition: 0.3s;\n}\n\n.form-toggle-input:before {\n  content: \"\";\n  position: absolute;\n  top: 0.1875rem;\n  left: 0.1875rem;\n  width: 1.5625rem;\n  height: 1.5625rem;\n  border-radius: 50%;\n  background: #fff;\n  transition: 0.3s;\n  cursor: pointer;\n}\n\ninput[type=\"checkbox\"]:checked + .form-toggle-input {\n  background: var(--primary-color);\n}\n\ninput[type=\"checkbox\"]:checked + .form-toggle-input:before {\n  left: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
