import React from "react";
import { ReactComponent as PlusCircle } from "../../../assets/icons/plus-circle.svg";
import "./MessageForm.css";

export const MessageForm = ({ message, onEnter, onChange }) => {
  return (
    <div className="d-flex align-items-center px-3">
      <PlusCircle />
      <input
        type="text"
        placeholder="Type a message"
        className="message-input form-control ms-3"
        value={message}
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEnter();
          }
        }}
      />
    </div>
  );
};
