import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { ReactComponent as ArrowUpSuccess } from "../../../assets/icons/arrow-up-success.svg";
import { ReactComponent as ChartEclipse } from "../../../assets/icons/chart-eclipse.svg";
import { ReactComponent as ChatBox } from "../../../assets/icons/chatbox-sm.svg";
import { ReactComponent as CheckmarkEclipse } from "../../../assets/icons/checkmark-eclipse.svg";
import { ReactComponent as ClosePrimary } from "../../../assets/icons/close-primary.svg";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { ReactComponent as Ellipsis } from "../../../assets/icons/ellipsis.svg";
import { ReactComponent as Email } from "../../../assets/icons/email.svg";
import { ReactComponent as EyeEclipse } from "../../../assets/icons/eye-eclipse.svg";
import { ReactComponent as GridEclipse } from "../../../assets/icons/grid-eclipse.svg";
import { ReactComponent as HeartEclipse } from "../../../assets/icons/heart-eclipse.svg";
import { ReactComponent as HourGlassEclipse } from "../../../assets/icons/hourglass-eclipse.svg";
import { ReactComponent as SendEclipse } from "../../../assets/icons/send-eclipse.svg";
import { ReactComponent as StarEclipse } from "../../../assets/icons/star-eclipse.svg";
import { ReactComponent as TrophyEclipse } from "../../../assets/icons/trophy-eclipse.svg";
import { UncutModal } from "../../../components/ui/modal/modal";
import { useSpinnerContext } from "../../../context/SpinnerContext";
import { useUserContext } from "../../../context/UserContext";
import { getReservations } from "../../../services/reservations.service";
import "./Dashboard.css";

export const Dashboard = () => {
  const totals = [
    {
      title: "Total Listings",
      value: 0,
      icon: <GridEclipse />,
    },
    {
      title: "Visitors",
      value: 0,
      icon: <EyeEclipse />,
    },
    {
      title: "Overall Bookings",
      value: 0,
      icon: <CheckmarkEclipse />,
    },
  ];

  const reviews = [
    {
      title: "Average Rating",
      value: 0,
      icon: <TrophyEclipse />,
    },
    {
      title: "Total Reviews",
      value: 0,
      icon: <StarEclipse />,
    },
    {
      title: "Pending Reviews",
      value: 0,
      icon: <HourGlassEclipse />,
    },
    {
      title: "Reviews in last 30 days",
      value: 0,
      icon: <StarEclipse />,
    },
  ];

  const dropdownOptions = [
    {
      label: "Message Guest",
      onClick: (e, reservation) => {},
      icon: <ChatBox />,
    },
    {
      label: "Email Guest",
      onClick: (e, reservation) => {
        window.location.href = `mailto:${reservation.owner.email}`;
      },
      icon: <Email />,
    },
    {
      label: "Change or Cancel",
      onClick: () => {},
      icon: <Close />,
    },
  ];

  const { user } = useUserContext();

  const [reservations, setReservations] = useState([]);

  const [reservationDetails, setReservationDetails] = useState(null);

  const { setSpinner } = useSpinnerContext();

  useEffect(() => {
    const fetchReservations = async () => {
      setSpinner(true);
      const resp = await getReservations();
      setReservations(resp.result);
      setSpinner(false);
    };
    fetchReservations();
  }, [setSpinner]);

  return (
    <div className="ps-3 pe-4 h-100">
      <div className="d-flex justify-content-between align-items-center bg-primary p-4 rounded-4 mb-4 w-100">
        <div>
          <h1 className="mb-2 text-white fs-28 fw-600">
            Welcome, {user?.first_name}
          </h1>
          <p className="mb-0 text-white fw-500">
            You hope you have a great and fruitful
          </p>
        </div>
        <HeartEclipse />
      </div>
      <div className="row g-0 g-4">
        {totals.map((total, index) => (
          <div className="col-12 col-md" key={index}>
            <div className="rounded-4 p-4 shadow d-flex justify-content-between bg-white">
              <div>
                <h1 className="fs-38 fw-600">{total.value}</h1>
                <p className="mb-0 text-muted fw-500">{total.title}</p>
              </div>
              {total.icon}
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-between align-items-center mt-5 mb-2">
        <h1 className="fs-28 fw-600">Earnings</h1>
        <a href="#!" className="text-decoration-none text-primary fw-500 fs-16">
          View More
        </a>
      </div>
      <div className="row g-0 g-4">
        <div className="col-12 col-md">
          <div className="rounded-4 p-4 shadow bg-white">
            <div className="d-flex justify-content-between mb-5">
              <div>
                <h1 className="fs-34 fw-600">130,000</h1>
                <p className="mb-0 text-muted fw-500">Total Earnings</p>
              </div>
              <ChartEclipse />
            </div>
            <p className="mb-0 text-muted fw-500">Since Oct, 2019</p>
          </div>
        </div>
        <div className="col-12 col-md">
          <div className="rounded-4 p-4 shadow bg-white">
            <div className="d-flex justify-content-between mb-5">
              <div>
                <h1 className="fs-34 fw-600">
                  130,000
                  <ArrowUpSuccess />
                </h1>
                <p className="mb-0 text-muted fw-500">Earnings this month</p>
              </div>
              <ChartEclipse />
            </div>
            <a
              href="#!"
              className="text-decoration-none text-primary fw-500 fs-16"
            >
              View transaction
            </a>
          </div>
        </div>
        <div className="col-12 col-md">
          <div className="rounded-4 p-4 shadow bg-white">
            <div className="d-flex justify-content-between mb-5">
              <div>
                <h1 className="fs-34 fw-600">130,000</h1>
                <p className="mb-0 text-muted fw-500">Earnings to be paid</p>
              </div>
              <SendEclipse />
            </div>
            <p className="mb-0 text-muted fw-500">
              Expected payout: Oct 19, 2023
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-5 mb-2">
        <h1 className="fs-28 fw-600">Reviews</h1>
        <a href="#!" className="text-decoration-none text-primary fw-500 fs-16">
          View More
        </a>
      </div>
      <div className="row g-0 g-4">
        {reviews.map((total, index) => (
          <div className="col-12 col-md" key={index}>
            <div className="rounded-4 p-4 shadow d-flex justify-content-between bg-white">
              <div>
                <h1 className="fs-34 fw-600">{total.value}</h1>
                <p className="mb-0 text-muted fw-500">{total.title}</p>
              </div>
              {total.icon}
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-between align-items-center mt-5 mb-2">
        <h1 className="fs-28 fw-600">Bookings</h1>
        <a href="#!" className="text-decoration-none text-primary fw-500 fs-16">
          View More
        </a>
      </div>
      <div className="table-responsive">
        <table className="booking-table">
          <tbody>
            {reservations.slice(0, 3).map((reservation, index) => (
              <tr className="booking-row" key={index}>
                <td className="booking-cell">
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={
                        reservation.space.featured_image ||
                        "https://via.placeholder.com/50"
                      }
                      alt=""
                      className="avatar"
                    />
                    <div>
                      <p className="mb-0 text-muted">Guest Name</p>
                      <b>
                        {reservation.owner.first_name +
                          " " +
                          reservation.owner.last_name}
                      </b>
                    </div>
                  </div>
                </td>
                <td className="booking-cell">
                  <p className="mb-0 text-muted">Listing</p>
                  <p className="fw-600 mb-0">{reservation.space.title}</p>
                </td>
                <td className="booking-cell">
                  <p className="mb-0 text-muted">Booked</p>
                  <b>
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    }).format(new Date(reservation.checkin_date)) + " - "}{" "}
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    }).format(new Date(reservation.checkout_date))}
                  </b>
                </td>
                <td className="booking-cell">
                  <p className="mb-0 text-muted">Status</p>
                  <b>{reservation.status}</b>
                </td>
                <td className="booking-cell">
                  <p className="mb-0 text-muted">Date</p>
                  <b>
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                    }).format(new Date(reservation.created))}
                  </b>
                </td>
                <td className="booking-cell">
                  <p className="mb-0 text-muted">Price</p>
                  <b>{"$" + reservation.price}</b>
                </td>
                <td className="booking-cell">
                  <button
                    className="btn btn-primary-outline fw-600"
                    data-bs-toggle="modal"
                    data-bs-target="#reservationModal"
                    onClick={() => setReservationDetails(reservation)}
                  >
                    Details
                  </button>
                </td>
                <td className="booking-cell">
                  <div
                    className="btn btn-link p-0 position-relative"
                    onClick={(e) => {
                      let dropdownMenu =
                        e.target.parentElement.querySelector(
                          ".res-dropdown-menu"
                        );
                      dropdownMenu && (dropdownMenu.style.display = "block");

                      window.addEventListener("mousedown", (e) => {
                        if (
                          dropdownMenu &&
                          !e.target.closest(".res-dropdown-menu")
                        ) {
                          dropdownMenu.style.display = "none";
                        }
                      });
                    }}
                  >
                    <Ellipsis width={25} height={25} />
                    <div className="res-dropdown-menu">
                      {dropdownOptions.map((option, index) => (
                        <button
                          key={index}
                          onClick={(e) => option.onClick(e, reservation)}
                          className="custom-dropdown-item"
                        >
                          <div className="d-flex align-items-center gap-3">
                            {option.icon}
                            <span>{option.label}</span>
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <UncutModal id="reservationModal" scrollable={true} right={true}>
        <div className="modal-header">
          <h5 className="modal-title fs-22 fw-600">Reservation Details</h5>
          <button
            type="button"
            className="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <ClosePrimary />
          </button>
        </div>
        <div className="modal-body px-4">
          <div className="col-12 text-center mt-3">
            <img
              src={
                reservationDetails?.space?.featured_image ||
                "https://via.placeholder.com/200"
              }
              alt="avatar"
              style={{
                objectFit: "cover",
                borderRadius: "50%",
                width: 80,
                height: 80,
                aspectRatio: 1 / 1,
              }}
              className="img-fluid mb-3"
            />
            <h5 className="fw-600 fs-20">
              {reservationDetails?.owner?.first_name +
                " " +
                reservationDetails?.owner?.last_name}
            </h5>
            <div className="d-flex align-items-center gap-2 justify-content-center">
              <Rating
                initialValue={4.5}
                allowFraction={true}
                fillColor="#4c35de"
                allowHover={false}
                size={25}
              />
              <p className="mb-0 text-dark fs-14">(15)</p>
            </div>
            <p className="mb-0 text-muted fs-14">Member since: 2019</p>
          </div>
          <div className="row g-0 gy-4 mt-4 border rounded-4 p-5 py-4">
            <div className="col-12 mt-0">
              <p className="mb-2 text-muted fs-14 fw-500">Confirmation #</p>
              <p className="mb-0 text-dark fw-600">
                #{reservationDetails?.id?.slice(-6)}
              </p>
            </div>
            <div className="col-12">
              <p className="mb-2 text-muted fs-14 fw-500">Book</p>
              <p className="mb-0 text-dark fw-600">
                {new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                }).format(
                  new Date(reservationDetails?.checkin_date || Date.now())
                ) + " - "}{" "}
                {new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                }).format(
                  new Date(reservationDetails?.checkout_date || Date.now())
                )}
              </p>
            </div>
            <div className="col-12">
              <p className="mb-2 text-muted fs-14 fw-500">Status</p>
              <p className="mb-0 text-dark fw-600">
                {reservationDetails?.status}
              </p>
            </div>
            <div className="col-12">
              <p className="mb-2 text-muted fs-14 fw-500">Date</p>
              <p className="mb-0 text-dark fw-600">
                {new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                }).format(new Date(reservationDetails?.created || Date.now()))}
              </p>
            </div>
            <div className="col-12">
              <p className="mb-2 text-muted fs-14 fw-500">Price</p>
              <p className="mb-0 text-dark fw-600">{`$${reservationDetails?.price}`}</p>
            </div>
          </div>
          <h5 className="fw-600 fs-20 mt-4">Listing</h5>
          <div className="row g-0 mt-3 border rounded-4 p-3">
            <div className="col-12 d-flex align-items-center gap-4">
              <img
                src="https://via.placeholder.com/200"
                alt="listing"
                style={{ width: "7.125rem", height: "7.125rem" }}
                className="rounded-2"
              />
              <div>
                <p className="mb-2 text-dark fw-600">
                  Well furnished Kitchen in Boston on Main Street.
                </p>
                <p className="mb-2 text-muted fs-16 fw-500">
                  Replace this text with space features later.
                </p>
              </div>
            </div>
          </div>
        </div>
      </UncutModal>
    </div>
  );
};
