import React, { useCallback, useEffect, useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Options } from "../../../../assets/icons/options-sm.svg";
import { ListingCard } from "../../../../components/application_components/ListingCard/ListingCard";
import { NoData } from "../../../../components/application_components/NoData/NoData";
import { SpaceAppliancesForm } from "../../../../components/forms/SpaceAppliancesForm/SpaceAppliancesForm";
import { SpaceDurationForm } from "../../../../components/forms/SpaceDurationForm/SpaceDurationForm";
import { SpaceLocationForm } from "../../../../components/forms/SpaceLocationForm/SpaceLocationForm";
import { SpaceSizeForm } from "../../../../components/forms/SpaceSizeForm/SpaceSizeForm";
import { SearchFilterModal } from "../../../../components/modals/SearchFilterModal/SearchFilterModal";
import { useSearchWizardContext } from "../../../../context/SearchWizardContext";
import {
  getAmenities,
  getSearchWizard,
} from "../../../../services/listings.service";
import "./Search.css";

export const Search = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const { searchWizard, setSearchWizard } = useSearchWizardContext();

  const [searchResults, setSearchResults] = useState([]);

  const [address, setAddress] = useState("");

  const [amenitiesList, setAmenitiesList] = useState([]);

  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState(1);

  const [view, setView] = useState("list");

  const getSearchResults = useCallback(async () => {
    if (!searchWizard) return;
    setLoading(true);
    setSearchResults([]);
    const resp = await getSearchWizard(searchWizard);
    setSearchResults(resp.result);
    setLoading(false);
  }, [searchWizard]);

  const fetchAmenities = useCallback(async () => {
    const resp = await getAmenities();
    setAmenitiesList(resp.result);
  }, []);

  const handleSelect = useCallback(
    async (address) => {
      const result = await geocodeByAddress(address);
      const latLng = await getLatLng(result[0]);
      setAddress(address);
      setSearchWizard({
        ...searchWizard,
        location: `${latLng.lat},${latLng.lng}`,
      });
      await getSearchResults();
    },
    [searchWizard, setSearchWizard, getSearchResults]
  );

  useEffect(() => {
    const initializeSearch = async () => {
      await fetchAmenities();

      if (!location.state?.location) return;

      const result = await geocodeByAddress(location.state?.location);
      const latLng = await getLatLng(result[0]);

      // Check if address has changed before updating
      if (address !== location.state?.location) {
        setAddress(location.state?.location);
      }

      // Check if location has changed before updating
      const newLocation = `${latLng.lat},${latLng.lng}`;
      if (searchWizard?.location !== newLocation) {
        setSearchWizard({
          ...searchWizard,
          location: newLocation,
        });
      }

      await getSearchResults();
    };

    initializeSearch();
  }, [
    location.state?.location,
    searchWizard,
    setSearchWizard,
    fetchAmenities,
    address,
    getSearchResults,
  ]);

  return (
    <div className="row g-0 flex-nowrap container-height">
      <div
        className={`bg-white pt-4 d-flex flex-column justify-content-between border-end scroll-y ${
          step === 5 ? "d-none" : "col"
        }`}
      >
        <div className="px-5">
          <p className="mb-3 text-muted fw-500">Step {step}/4</p>
          {step === 1 && (
            <SpaceLocationForm
              address={address}
              handleSelect={handleSelect}
              setAddress={setAddress}
            />
          )}
          {step === 2 && (
            <SpaceDurationForm
              searchWizard={searchWizard}
              setSearchWizard={setSearchWizard}
              getSearchResults={getSearchResults}
            />
          )}
          {step === 3 && (
            <SpaceSizeForm
              searchWizard={searchWizard}
              setSearchWizard={setSearchWizard}
              getSearchResults={getSearchResults}
            />
          )}
          {step === 4 && (
            <SpaceAppliancesForm
              searchWizard={searchWizard}
              setSearchWizard={setSearchWizard}
              getSearchResults={getSearchResults}
              amenitiesList={amenitiesList}
            />
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between gap-2 p-3 px-4 border-top sticky">
          <button
            className="btn btn-link text-primary text-decoration-none fw-600"
            onClick={() => {
              if (step > 1) return setStep(step - 1);
              navigate("/find-space");
            }}
          >
            Back
          </button>
          <button
            className="btn btn-primary btn-lg px-5 fw-600 py-3"
            onClick={() => {
              if (step < 5) setStep(step + 1);
            }}
          >
            Next
          </button>
        </div>
      </div>
      <div className={step === 5 ? "col-12" : "col-8"}>
        <div className="row g-0 border-bottom bg-white px-4 p-3">
          <div className="col-6 d-flex align-items-center">
            <h5 className="mb-0 fw-600">Search Results</h5>
          </div>
          <div className="col-6">
            <div className="d-flex align-items-center justify-content-end gap-3">
              <div className="d-flex align-items-center justify-content-center view-filters">
                <a
                  href="#!"
                  className={`text-decoration-none fw-600 ${
                    view === "list" ? "active" : ""
                  }`}
                  onClick={() => setView("list")}
                >
                  List View
                </a>
                <a
                  href="#!"
                  className={`text-decoration-none fw-600 ${
                    view === "map" ? "active" : ""
                  }`}
                  onClick={() => setView("map")}
                >
                  Map View
                </a>
              </div>
              <button
                className="filterBtn fw-600"
                data-bs-toggle="modal"
                data-bs-target="#search-filter-modal"
              >
                <Options />
                <p className="mb-0">Filters</p>
              </button>
            </div>
          </div>
        </div>
        <div className="search-results-container">
          {loading && (
            <div className="d-flex align-items-center justify-content-center h-100">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {searchResults.length > 0 &&
            searchResults.map((listing) => (
              <ListingCard
                key={listing.id}
                {...listing}
                maxWidth={searchResults.length < 2 ? "20rem" : "100%"}
              />
            ))}
          {!loading && searchResults.length === 0 && (
            <div className="d-flex align-items-center justify-content-center h-100">
              <NoData title={"Results Found"} text={"Try changing filters"} />
            </div>
          )}
        </div>
      </div>
      <SearchFilterModal id="search-filter-modal" />
    </div>
  );
};
