import { Country, State } from "country-state-city";
import React, { useMemo, useState } from "react";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron.svg";
import { ReactComponent as ClosePrimary } from "../../../assets/icons/close-primary.svg";
import { FormInput } from "../../ui/form-input/FormInput";
import { UncutModal } from "../../ui/modal/modal";

export const EditAddressModal = ({ id, updateAddressInfo }) => {
  const [address, setAddress] = useState({
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    isoCode: "",
    apt: "",
  });

  const [error, setError] = useState({
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  const [dropdown, setDropdown] = useState(false);

  const [dropdown2, setDropdown2] = useState(false);

  const options = useMemo(() => Country.getAllCountries(), []);

  const stateOptions = useMemo(() => {
    if (address.isoCode) {
      return State.getStatesOfCountry(address.isoCode);
    } else {
      return [];
    }
  }, [address.isoCode]);

  const handleAddressChange = (e, type) => {
    let value = e.target.value;

    if (value === "") {
      setError({
        ...error,
        [type]: true,
        message: `${type} is required`,
      });
    } else {
      setError({
        ...error,
        [type]: false,
        message: "",
      });
    }

    setAddress(value);
  };

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Address</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <p className="mb-0 mt-2 mb-4" style={{ width: "25em" }}>
          Use a permanent address where you can receive mail.
        </p>
        <div className="custom-form-select my-3">
          <div
            className="form-select-btn"
            onClick={() => {
              setDropdown(!dropdown);
            }}
          >
            <span className="fw-500 me-2" style={{ whiteSpace: "nowrap" }}>
              {address.country ? address.country : "Country"}
            </span>
            <Chevron />
          </div>
          <div className={`form-select-content ${dropdown ? "show" : ""}`}>
            {options.map((country, index) => {
              return (
                <div
                  className="form-select-item"
                  key={index}
                  onClick={() => {
                    setAddress({
                      ...address,
                      country: country.name,
                      isoCode: country.isoCode,
                    });
                    setDropdown(false);
                  }}
                >
                  <label>{country.name}</label>
                </div>
              );
            })}
          </div>
          {error.country && (
            <p className="text-danger fs-14 fw-500">{error.country}</p>
          )}
        </div>
        <div className="my-4">
          <FormInput
            name="street"
            placeholder="Street address"
            value={address.street}
            required
            type="text"
            onChange={(e) => handleAddressChange(e, "street")}
          />
          {error.street && (
            <p className="text-danger fs-14 fw-500">{error.street}</p>
          )}
        </div>
        <div className="my-4">
          <FormInput
            name="apt"
            placeholder="Apt, suite, (optional)"
            value={address.apt}
            type="text"
            onChange={(e) => handleAddressChange(e, "apt")}
          />
        </div>
        <div className="my-4">
          <FormInput
            name="city"
            placeholder="City"
            value={address.city}
            required
            type="text"
            onChange={(e) => handleAddressChange(e, "city")}
          />
          {error.city && (
            <p className="text-danger fs-14 fw-500">{error.city}</p>
          )}
        </div>
        <div className="custom-form-select my-3">
          <div
            className="form-select-btn"
            onClick={() => {
              setDropdown2(!dropdown2);
            }}
          >
            <span className="fw-500 me-2" style={{ whiteSpace: "nowrap" }}>
              {address.state ? address.state : "State"}
            </span>
            <Chevron />
          </div>
          <div className={`form-select-content ${dropdown2 ? "show" : ""}`}>
            {stateOptions.map((state, index) => {
              return (
                <div
                  className="form-select-item"
                  key={index}
                  onClick={() => {
                    setAddress({ ...address, state: state.name });
                    setDropdown2(false);
                  }}
                >
                  <label>{state.name}</label>
                </div>
              );
            })}
          </div>
          {error.state && (
            <p className="text-danger fs-14 fw-500">{error.state}</p>
          )}
        </div>
        <div className="my-4">
          <FormInput
            name="zip"
            placeholder="ZIP code"
            value={address.zip}
            required
            type="text"
            onChange={(e) => handleAddressChange(e, "zip")}
          />
          {error.zip && <p className="text-danger fs-14 fw-500">{error.zip}</p>}
        </div>
      </div>
      <div className="modal-footer d-flex align-items-center px-4">
        <button
          type="button"
          className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary fw-600 flex-grow-1 fs-20"
          onClick={async () => {
            delete address.isoCode;
            await updateAddressInfo(address);
            setAddress({
              street: "",
              city: "",
              state: "",
              zip: "",
              country: "",
              isoCode: "",
              apt: "",
            });
          }}
        >
          Save
        </button>
      </div>
    </UncutModal>
  );
};
