// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-input {
  border-radius: 0.625rem;
  border: 2px solid #d5d6d9;
  background: #fff;
  padding: 1rem;
  width: 100%;
  outline: none;
  font-size: 1rem;
  color: #333;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/AddPaymentMethodModal/AddPaymentMethodModal.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,WAAW;EACX,aAAa;EACb,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".card-input {\n  border-radius: 0.625rem;\n  border: 2px solid #d5d6d9;\n  background: #fff;\n  padding: 1rem;\n  width: 100%;\n  outline: none;\n  font-size: 1rem;\n  color: #333;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
