import React from "react";
import { useNavigate } from "react-router-dom";
// import { ReactComponent as Calendar } from "../../../assets/icons/calendar.svg";
// import { ReactComponent as Chart } from "../../../assets/icons/chart.svg";
import { ReactComponent as CheckBox } from "../../../assets/icons/checkbox.svg";
// import { ReactComponent as HelpOutline } from "../../../assets/icons/help-outline.svg";
import { ReactComponent as Layers } from "../../../assets/icons/layers.svg";
import { ReactComponent as Settings } from "../../../assets/icons/settings.svg";
import { ReactComponent as Speedometer2 } from "../../../assets/icons/speedometer2.svg";
import { useUserContext } from "../../../context/UserContext";
import "./Sidebar.css";

export const Sidebar = ({ sidebar, setSidebar }) => {
  const navigate = useNavigate();

  const navigations = [
    {
      name: "Dashboard",
      path: "/main/dashboard",
      icon: <Speedometer2 style={{ width: "20px", height: "20px" }} />,
    },
    {
      name: "Reservations",
      path: "/main/reservations",
      icon: <CheckBox style={{ width: "20px", height: "20px" }} />,
    },
    // {
    //   name: "Calendar",
    //   path: "/main/calendar",
    //   icon: <Calendar />,
    // },
    {
      name: "Listings",
      path: "/main/listings",
      icon: <Layers style={{ width: "20px", height: "20px" }} />,
    },
    // {
    //   name: "Inbox",
    //   path: "/main/inbox",
    //   icon: <ChatBox />,
    // },
    // {
    //   name: "Transaction History",
    //   path: "/main/transaction-history",
    //   icon: <Chart style={{ width: "20px", height: "20px" }} />,
    // },
    // {
    //   name: "Resources",
    //   path: "/main/resources",
    //   icon: <HelpOutline style={{ width: "20px", height: "20px" }} />,
    // },
  ];

  const { user } = useUserContext();

  return (
    <nav
      className={`sidebar row g-0 justify-content-between ${
        sidebar ? "active" : ""
      }`}
    >
      <div className="col-12">
        <div className="border rounded-3 p-3 d-flex align-items-center gap-3 m-3">
          <img
            src={
              user?.profile_picture ||
              "https://ui-avatars.com/api/?name=" +
                user?.first_name +
                "+" +
                user?.last_name
            }
            alt="avatar"
            className="avatar"
          />
          <div className="truncate">
            <h5 className="card-title fw-600 fs-16 truncate">
              {user?.first_name} {user?.last_name}
            </h5>
            <p className="card-text fw-500 fs-12">Hosting</p>
          </div>
        </div>
        <ul className="col-12 mt-4">
          {navigations.map((navigation, index) => (
            <li key={index} className="nav-item">
              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(navigation.path);
                  setSidebar(false);
                }}
                className={`nav-link d-flex align-items-center gap-2 ${
                  navigation.path === window.location.pathname ? "active" : ""
                }`}
              >
                <span
                  className="d-flex align-items-center"
                  style={{ width: "25px", height: "25px" }}
                >
                  {navigation.icon}
                </span>
                <span className="fs-14 fw-500 truncate">{navigation.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="col-12 border-top mt-auto">
        <li className="nav-item">
          <a
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              navigate("/main/settings");
            }}
            className="nav-link d-flex align-items-center gap-2"
          >
            <span style={{ width: "25px", height: "25px" }}>
              <Settings style={{ width: "20px", height: "20px" }} />
            </span>
            <span className="fs-14 fw-500 truncate">Account Settings</span>
          </a>
        </li>
      </div>
    </nav>
  );
};
