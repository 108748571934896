// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-container .nav-link {
  border-radius: 3.125rem;
  border: none;
  padding: 0.625rem 2rem;
  min-height: auto;
  text-align: center;
  font-weight: 500;
  color: #333;
  background: rgba(167, 167, 167, 0.2);
}

.payment-container .nav-link.active {
  background: var(--primary-color);
  color: #fff;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Main/AccountSettings/PaymentAndSecurity/PaymentAndSecurity.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,oCAAoC;AACtC;;AAEA;EACE,gCAAgC;EAChC,WAAW;EACX,YAAY;AACd","sourcesContent":[".payment-container .nav-link {\n  border-radius: 3.125rem;\n  border: none;\n  padding: 0.625rem 2rem;\n  min-height: auto;\n  text-align: center;\n  font-weight: 500;\n  color: #333;\n  background: rgba(167, 167, 167, 0.2);\n}\n\n.payment-container .nav-link.active {\n  background: var(--primary-color);\n  color: #fff;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
