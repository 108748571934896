// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overview-card {
  border-radius: 0.8125rem;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 17px 2px rgba(192, 192, 192, 0.17);
  width: 25rem;
}


.overview-card img {
  width: 100%;
  height: 20em;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/CreateListingsModal/ReviewListing/ReviewListing.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,yBAAyB;EACzB,sDAAsD;EACtD,YAAY;AACd;;;AAGA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".overview-card {\n  border-radius: 0.8125rem;\n  border: 1px solid #e5e5e5;\n  box-shadow: 0px 2px 17px 2px rgba(192, 192, 192, 0.17);\n  width: 25rem;\n}\n\n\n.overview-card img {\n  width: 100%;\n  height: 20em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
