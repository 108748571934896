// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-input.form-control:focus {
  box-shadow: none;
  border-color: #d5d6d9;
}

.message-input.form-control {
  border-radius: 3.125rem;
  border: 2px solid #d5d6d9;
  background: #fff;
  height: 2rem;
  color: #676d7c;
  font-size: 0.875rem;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/MessageForm/MessageForm.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".message-input.form-control:focus {\n  box-shadow: none;\n  border-color: #d5d6d9;\n}\n\n.message-input.form-control {\n  border-radius: 3.125rem;\n  border: 2px solid #d5d6d9;\n  background: #fff;\n  height: 2rem;\n  color: #676d7c;\n  font-size: 0.875rem;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
