const CryptoJs = require("crypto-js");
const secureStorage = require("secure-web-storage");
const {
  ACCESS_TOKEN,
  USER_DETAILS,
  REMEMBER_ME,
  LISTING_FORM,
  LISTING,
  SEARCH_WIZARD,
  RESERVATION_FORM,
} = require("../utils/variables");
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const secureStorageInstance = new secureStorage(localStorage, {
  hash: (key) => {
    key = CryptoJs.SHA256(key, SECRET_KEY);
    return key.toString();
  },
  encrypt: function encrypt(data) {
    data = CryptoJs.AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  },
  decrypt: function decrypt(data) {
    data = CryptoJs.AES.decrypt(data, SECRET_KEY);
    if (data) {
      try {
        data = data.toString(CryptoJs.enc.Utf8);
        if (data) {
          return data;
        }
      } catch (e) {
        return null;
      }
    }
    return null;
  },
});

const set = (key, value) => {
  try {
    secureStorageInstance.setItem(key, value);
  } catch (error) {
    console.error(error);
  }
};

const get = (key) => {
  try {
    return secureStorageInstance.getItem(key);
  } catch (error) {
    console.error(error);
  }
};

const remove = (key) => {
  try {
    secureStorageInstance.removeItem(key);
  } catch (error) {
    console.error(error);
  }
};

const clear = () => {
  try {
    const user = get(USER_DETAILS);
    const rememberMe = get(REMEMBER_ME);
    secureStorageInstance.clear();
    set(USER_DETAILS, {
      first_name: user.first_name,
      last_name: user.last_name,
    });
    set(REMEMBER_ME, rememberMe);
  } catch (error) {
    console.error(error);
  }
};

const setToken = (token) => {
  set(ACCESS_TOKEN, token);
};

const getToken = () => {
  return get(ACCESS_TOKEN);
};

const removeToken = () => {
  remove(ACCESS_TOKEN);
};

const setUserDetails = (user) => {
  set(USER_DETAILS, user);
};

const getUserDetails = () => {
  return get(USER_DETAILS);
};

const removeUserDetails = () => {
  remove(USER_DETAILS);
};

const setRememberMe = (rememberMe) => {
  set(REMEMBER_ME, rememberMe);
};

const getRememberMe = () => {
  return get(REMEMBER_ME);
};

const removeRememberMe = () => {
  remove(REMEMBER_ME);
};

const setListingForm = (listingForm) => {
  set(LISTING_FORM, listingForm);
};

const getListingForm = () => {
  return get(LISTING_FORM);
};

const removeListingForm = () => {
  remove(LISTING_FORM);
};

const setListing = (listing) => {
  set(LISTING, listing);
};

const getListing = () => {
  return get(LISTING);
};

const removeListing = () => {
  remove(LISTING);
};

const setSearchWizard = (searchWizard) => {
  set(SEARCH_WIZARD, searchWizard);
};

const getSearchWizard = () => {
  return get(SEARCH_WIZARD);
};

const removeSearchWizard = () => {
  remove(SEARCH_WIZARD);
};

const setReservationForm = (reservationForm) => {
  set(RESERVATION_FORM, reservationForm);
};

const getReservationForm = () => {
  return get(RESERVATION_FORM);
};

const removeReservationForm = () => {
  remove(RESERVATION_FORM);
};

export const storageService = {
  set,
  get,
  remove,
  clear,
  setToken,
  getToken,
  removeToken,
  setUserDetails,
  getUserDetails,
  removeUserDetails,
  setRememberMe,
  getRememberMe,
  removeRememberMe,
  setListingForm,
  getListingForm,
  removeListingForm,
  setSearchWizard,
  getSearchWizard,
  removeSearchWizard,
  setReservationForm,
  getReservationForm,
  removeReservationForm,
  setListing,
  getListing,
  removeListing,
};
