// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.space-card {
  border-radius: 0.875rem;
  background: #fff;
  box-shadow: 0px 2px 17px 2px rgba(192, 192, 192, 0.17);
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/CreateListingsModal/SpaceProfession/SpaceProfession.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,sDAAsD;AACxD","sourcesContent":[".space-card {\n  border-radius: 0.875rem;\n  background: #fff;\n  box-shadow: 0px 2px 17px 2px rgba(192, 192, 192, 0.17);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
