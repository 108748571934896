import React, { useState } from "react";
import { useSpinnerContext } from "../../../context/SpinnerContext";
import { forgotPassword } from "../../../services/auth.service";
import { emailValidation } from "../../../utils/validation";
import { FormInput } from "../../ui/form-input/FormInput";
import "./ForgotPasswordForm.css";
import { toastService } from "../../../lib/toast.service";

export const ForgotPasswordForm = () => {
  const [form, setForm] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({
    email: "",
  });

  const { setSpinner } = useSpinnerContext();

  const handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    handleErrors(e);
  };

  const handleErrors = (e) => {
    if (e.target.name === "email") {
      setErrors((prev) => ({
        ...prev,
        email: emailValidation(e.target.value),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    const resp = await forgotPassword(form);
    setSpinner(false);
    if (resp.status === 200 || resp.status === 201) {
      toastService.success(resp.message);
      setForm({
        email: "",
      });
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="mb-4">
        <FormInput
          name="email"
          type="email"
          placeholder="Email Address"
          value={form.email}
          required={true}
          onChange={handleChange}
        />
        {errors.email && (
          <div className="text-danger fw-500 ms-3">
            <small className="ps-1">{errors.email}</small>
          </div>
        )}
      </div>
      <div className="d-grid">
        <button
          className="btn btn-primary rounded fw-500 fs-18"
          type="submit"
          disabled={errors.email || !form.email}
        >
          Send Reset Request
        </button>
      </div>
    </form>
  );
};
