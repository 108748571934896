import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSpinnerContext } from "../../../context/SpinnerContext";
import { useUserContext } from "../../../context/UserContext";
import { storageService } from "../../../lib/storage.service";
import { login } from "../../../services/auth.service";
import { emailValidation } from "../../../utils/validation";
import { FormInput } from "../../ui/form-input/FormInput";
import "./LoginForm.css";

export const LoginForm = () => {
  const navigate = useNavigate();
  const { setSpinner } = useSpinnerContext();
  const { setUser } = useUserContext();

  const [form, setForm] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    handleErrors(e);
  };

  const handleErrors = (e) => {
    if (e.target.name === "email") {
      setErrors((prev) => ({
        ...prev,
        email: emailValidation(e.target.value),
      }));
    }

    if (e.target.name === "password") {
      setErrors((prev) => ({
        ...prev,
        password: e.target.value === "" ? "Password is required" : "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    if (form.rememberMe) {
      storageService.setRememberMe(form);
    } else {
      storageService.removeRememberMe();
    }
    const resp = await login(form);
    setSpinner(false);
    if (resp.status === 200 || resp.status === 201) {
      // toastService.success(resp.message);
      setForm({
        email: "",
        password: "",
      });
      storageService.setToken(resp.result.auth_token);
      delete resp.result.auth_token;
      storageService.setUserDetails(resp.result);
      setUser(resp.result);
      navigate("/main/dashboard");
    }
  };

  useEffect(() => {
    const rememberMe = storageService.getRememberMe();
    if (rememberMe) {
      setForm((prev) => ({ ...prev, ...rememberMe }));
    }
  }, []);

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="mb-4">
        <FormInput
          name="email"
          type="email"
          placeholder="Email Address"
          required={true}
          onChange={handleChange}
        />
        {errors.email && (
          <div className="text-danger fw-500 ms-3">
            <small className="ps-1">{errors.email}</small>
          </div>
        )}
      </div>
      <div className="mb-4">
        <FormInput
          name="password"
          type="password"
          placeholder="Password"
          required={true}
          onChange={handleChange}
        />
        {errors.password && (
          <div className="text-danger fw-500 ms-3">
            <small className="ps-1">{errors.password}</small>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center">
          <input
            className="d-none"
            type="checkbox"
            id="rememberMe"
            onChange={(e) =>
              setForm((prev) => ({ ...prev, rememberMe: e.target.checked }))
            }
          />
          <label className="form-toggle-input" htmlFor="rememberMe"></label>
          <label className="fs-16 fw-500" htmlFor="rememberMe">
            Remember me
          </label>
        </div>
        <a
          href="#!"
          onClick={(e) => {
            e.preventDefault();
            navigate("/auth/forgot-password");
          }}
          className="text-primary text-decoration-none fs-16 fw-500"
        >
          Forgot Password?
        </a>
      </div>
      <div className="d-grid">
        <button
          className="btn btn-primary rounded fw-500 fs-18 py-2"
          type="submit"
        >
          Sign In
        </button>
      </div>
    </form>
  );
};
