import React from "react";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import ClipLoader from "react-spinners/ClipLoader";

export const Spinner = ({ loading = false, color = "#4c35de", size = 20 }) => {
  const cssOverride = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    width: "100%",
    height: "100%",
    zIndex: "999999",
    transition: "all 0.3s ease-in-out",
    opacity: loading ? 1 : 0,
    visibility: loading ? "visible" : "hidden",
  };

  return (
    <ClimbingBoxLoader
      color={color}
      size={size}
      loading="true"
      cssOverride={cssOverride}
    />
  );
};

export const SearchSpinner = ({
  loading = false,
  color = "#4c35de",
  size = 20,
}) => {
  const cssOverride = {
    opacity: loading ? 1 : 0,
    visibility: loading ? "visible" : "hidden",
  };

  return (
    <ClipLoader
      color={color}
      size={size}
      loading="true"
      cssOverride={cssOverride}
    />
  );
};
