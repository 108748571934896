import { useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import { SpinnerContext } from "./context/SpinnerContext";
import { UserProvider } from "./context/UserContext";
import { Spinner } from "./lib/spinner.service";
import { Auth } from "./pages/Auth/Auth";
import { EmailVerified } from "./pages/Auth/EmailVerified/EmailVerified";
import { ForgotPassword } from "./pages/Auth/ForgotPassword/ForgotPassword";
import { Login } from "./pages/Auth/Login/Login";
import { Register } from "./pages/Auth/Register/Register";
import { VerifyEmail } from "./pages/Auth/VerifyEmail/VerifyEmail";
import { AccountSettings } from "./pages/Main/AccountSettings/AccountSettings";
import { Calendar } from "./pages/Main/Calendar/Calendar";
import { Dashboard } from "./pages/Main/Dashboard/Dashboard";
import { Inbox } from "./pages/Main/Inbox/Inbox";
import { Listings } from "./pages/Main/Listings/Listings";
import { Main } from "./pages/Main/Main";
import { Reservations } from "./pages/Main/Reservations/Reservations";
import { Resources } from "./pages/Main/Resources/Resources";
import { TransactionHistory } from "./pages/Main/TransactionHistory/TransactionHistory";
import { ConfirmAndPay } from "./pages/Website/FindSpace/ConfirmAndPay/ConfirmAndPay";
import { FindSpace } from "./pages/Website/FindSpace/FindSpace";
import { GetStarted } from "./pages/Website/FindSpace/GetStarted/GetStarted";
import { Search } from "./pages/Website/FindSpace/Search/Search";
import { SpaceDetail } from "./pages/Website/FindSpace/SpaceDetail/SpaceDetail";
import { Home } from "./pages/Website/Home/Home";
import { ResetPassword } from "./pages/Auth/ResetPassword/ResetPassword";
import { ReservationSuccess } from "./pages/Website/FindSpace/ReservationSuccess/ReservationSuccess";

function App() {
  const navigations = [
    {
      path: "/",
      component: <Home />,
    },
    {
      path: "/auth",
      component: <Auth />,
      subPages: [
        {
          path: "",
          component: <Navigate to="/auth/login" />,
        },
        {
          path: "login",
          component: <Login />,
        },
        {
          path: "register",
          component: <Register />,
        },
        {
          path: "forgot-password",
          component: <ForgotPassword />,
        },
        {
          path: "reset-password",
          component: <ResetPassword />,
        },
        {
          path: "verify-email",
          component: <VerifyEmail />,
        },
        {
          path: "email-verified/:user_id/:email_verification_string/:email",
          component: <EmailVerified />,
        },
        {
          path: "*",
          component: <Navigate to="/auth/login" />,
        },
      ],
    },
    {
      path: "/find-space",
      component: <FindSpace />,
      subPages: [
        {
          path: "",
          component: <Navigate to="/find-space/get-started" />,
        },
        {
          path: "get-started",
          component: <GetStarted />,
        },
        {
          path: "search",
          component: <Search />,
        },
        {
          path: "space/:id",
          component: <SpaceDetail />,
        },
        {
          path: "confirm-and-pay",
          component: <ConfirmAndPay />,
        },
        {
          path: "reservation-success/:id",
          component: <ReservationSuccess />,
        },
        {
          path: "*",
          component: <Navigate to="/find-space/get-started" />,
        },
      ],
    },
    {
      path: "/main",
      component: <Main />,
      subPages: [
        {
          path: "",
          component: <Navigate to="/main/dashboard" />,
        },
        {
          path: "dashboard",
          component: <Dashboard />,
        },
        {
          path: "reservations",
          component: <Reservations />,
        },
        {
          path: "calendar",
          component: <Calendar />,
        },
        {
          path: "listings",
          component: <Listings />,
        },
        {
          path: "inbox",
          component: <Inbox />,
        },
        {
          path: "transaction-history",
          component: <TransactionHistory />,
        },
        {
          path: "resources",
          component: <Resources />,
        },
        {
          path: "settings",
          component: <AccountSettings />,
        },
        {
          path: "*",
          component: <Navigate to="/main/dashboard" />,
        },
      ],
    },
    {
      path: "*",
      component: <Navigate to="/" />,
    },
  ];

  const [spinner, setSpinner] = useState(false);

  const [user, setUser] = useState(null);

  return (
    <div className="App">
      <UserProvider value={{ user, setUser }}>
        <SpinnerContext.Provider value={{ spinner, setSpinner }}>
          <Router>
            <Routes>
              {navigations.map((navigation, index) => {
                return (
                  <Route
                    key={index}
                    path={navigation.path}
                    index={navigation.index && true}
                    element={navigation.component}
                  >
                    {navigation.subPages &&
                      navigation.subPages.map((subPage, subIndex) => (
                        <Route
                          key={subIndex}
                          path={subPage.path}
                          element={subPage.component}
                        >
                          {subPage.subPages &&
                            subPage.subPages.map(
                              (nestedSubPage, nestedIndex) => (
                                <Route
                                  key={nestedIndex}
                                  path={nestedSubPage.path}
                                  element={nestedSubPage.component}
                                />
                              )
                            )}
                        </Route>
                      ))}
                  </Route>
                );
              })}
            </Routes>
          </Router>
          <ToastContainer />
          <Spinner loading={spinner} />
        </SpinnerContext.Provider>
      </UserProvider>
    </div>
  );
}

export default App;
