import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EmailOpen from "../../../assets/images/email-open.svg";
import { useSpinnerContext } from "../../../context/SpinnerContext";
import { toastService } from "../../../lib/toast.service";
import { confirmEmail } from "../../../services/auth.service";
import "./EmailVerified.css";

export const EmailVerified = () => {
  const navigate = useNavigate();

  const { setSpinner } = useSpinnerContext();

  const location = useLocation();

  useEffect(() => {
    const init = async () => {
      if (!location.pathname.includes("email-verified")) return;
      const urlParams = location.pathname.split("/");
      const user_id = urlParams[urlParams.length - 4];
      const email_verification_string = urlParams[urlParams.length - 3];
      const email = urlParams[urlParams.length - 2];

      setSpinner(true);
      const resp = await confirmEmail({
        user_id,
        email_verification_string,
        email,
      });
      setSpinner(false);
      if (resp.status === 200 || resp.status === 201) {
        toastService.success(resp.message);
        navigate("/auth/login");
      }
    };

    return () => {
      init();
    };
  }, [location.pathname, setSpinner, navigate]);  

  return (
    <div className="row g-0 align-items-start justify-content-center mt-5 pt-5">
      <div className="col-4 p-4 mt-5 card rounded-4 text-center">
        <img src={EmailOpen} alt="open-email-icon" className="mb-3 mx-auto" />
        <h3 className="mb-3 fw-600 fs-24">Email verified.</h3>
        <p className="mb-3 fw-500">
          Thanks for verifying your email address. We hope you enjoy our
          platform and it helps your business grow. <br />
          <br />
          In the mean time, contact our{" "}
          <span className="text-primary fw-600 fs-18 pointer">support</span> if
          you have any questions.
        </p>
      </div>
    </div>
  );
};
