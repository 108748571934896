import React, { useState } from "react";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";
import "./SpacePrice.css";

export const SpacePrice = ({ listingForm, setListingForm }) => {
  const [dropdown, setDropdown] = useState(false);

  const durations = ["Hourly", "Daily", "Week"];

  return (
    <div className="row g-0 justify-content-center mt-4">
      <div className="col-12 col-md-5">
        <h5 className="mb-2 text-center fs-36 fw-600">
          Now, set a price for your space
        </h5>
        <p className="mb-0 fw-500 fs-16 text-center text-muted">
          Don’t worry! you can update it anytime.
        </p>
        <div className="d-flex align-items-center flex-column justify-content-center flex-wrap gap-4 my-4">
          <input
            type="number"
            className="form-control-input mb-3"
            placeholder="Price"
            value={listingForm?.price?.amount || ""}
            onChange={(e) => {
              setListingForm((prev) => {
                return {
                  ...prev,
                  price: {
                    ...prev?.price,
                    amount: e.target.value,
                  },
                };
              });
            }}
          />
          <div className="custom-form-select mb-3">
            <div
              className="form-select-btn"
              onClick={() => {
                setDropdown(!dropdown);
              }}
            >
              <span className="fw-500 me-2" style={{ whiteSpace: "nowrap" }}>
                {listingForm?.price?.duration
                  ? listingForm?.price?.duration
                  : "Duration"}
              </span>
              <Chevron />
            </div>
            <div className={`form-select-content ${dropdown ? "show" : ""}`}>
              {durations.map((duration, index) => {
                return (
                  <div
                    className="form-select-item"
                    key={index}
                    onClick={() => {
                      setListingForm({
                        ...listingForm,
                        price: { ...listingForm?.price, duration: duration },
                      });
                      setDropdown(false);
                    }}
                  >
                    <label>{duration}</label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <p className="mb-5 fw-500 fs-16 text-center text-muted">
          Guest price before taxes $57
        </p>{" "}
        <h5 className="mb-2 text-center fs-36 fw-600">Fees and Charges</h5>
        <p className="mb-0 fw-500 fs-16 text-center text-muted">
          Fees and charges are added to the total price for guests.
        </p>
        <div className="d-flex align-items-end justify-content-center gap-4 my-4">
          <input
            type="number"
            className="form-control-input mb-1"
            placeholder="Fee"
            value={listingForm?.fee_and_charges || ""}
            onChange={(e) => {
              setListingForm((prev) => {
                return {
                  ...prev,
                  fee_and_charges: e.target.value,
                };
              });
            }}
          />
        </div>
        <p className="mb-0 fw-500 fs-16 text-center text-muted">
          This fee will apply to every reservation.
        </p>
      </div>
    </div>
  );
};
