import { Country } from "country-state-city";
import React, { useMemo, useState } from "react";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron.svg";
import { ReactComponent as ClosePrimary } from "../../../assets/icons/close-primary.svg";
import { FormInput } from "../../ui/form-input/FormInput";
import { UncutModal } from "../../ui/modal/modal";
const languageData = require("language-list")();

export const EditEmergencyContact = ({ id }) => {
  const [emergencyContact, setEmergencyContact] = useState({
    name: "",
    relationship: "",
    preferred_language: "",
    email: "",
    phone: "",
    country_code: "",
  });

  const [error, setError] = useState({
    name: "",
    relationship: "",
    preferred_language: "",
    email: "",
    phone: "",
    country_code: "",
  });

  const [dropdown, setDropdown] = useState(false);

  const [dropdown2, setDropdown2] = useState(false);

  const options = useMemo(() => Country.getAllCountries(), []);

  const languages = languageData.getData();

  const handleEmergencyContactChange = (e, type) => {
    let value = e.target.value;

    if (value === "") {
      setError({
        ...error,
        [type]: true,
        message: `${type} is required`,
      });
    } else {
      setError({
        ...error,
        [type]: false,
        message: "",
      });
    }

    setEmergencyContact(value);
  };

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Emergency Contact</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <p className="mb-0 mt-2 mb-4 text-muted" style={{ width: "25em" }}>
          A trusted contact we can alert in an urgent situation.
        </p>
        <div className="my-4">
          <FormInput
            name="name"
            placeholder="Name"
            value={emergencyContact.name}
            required
            type="text"
            onChange={(e) => handleEmergencyContactChange(e, "name")}
          />
          {error.name && (
            <p className="text-danger fs-14 fw-500">{error.name}</p>
          )}
        </div>
        <div className="my-4">
          <FormInput
            name="relationship"
            placeholder="Relationship"
            value={emergencyContact.relationship}
            type="text"
            onChange={(e) => handleEmergencyContactChange(e, "relationship")}
          />
        </div>
        <div className="custom-form-select my-3">
          <div
            className="form-select-btn"
            onClick={() => {
              setDropdown(!dropdown);
            }}
          >
            <span className="fw-500 me-2" style={{ whiteSpace: "nowrap" }}>
              {emergencyContact.preferred_language
                ? emergencyContact.preferred_language
                : "Preferred Language"}
            </span>
            <Chevron />
          </div>
          <div className={`form-select-content ${dropdown ? "show" : ""}`}>
            {languages.map((language, index) => {
              return (
                <div
                  className="form-select-item"
                  key={index}
                  onClick={() => {
                    setEmergencyContact({
                      ...emergencyContact,
                      preferred_language: language.language,
                    });
                    setDropdown(false);
                  }}
                >
                  <label>{language.language}</label>
                </div>
              );
            })}
          </div>
          {error.preferred_language && (
            <p className="text-danger fs-14 fw-500">
              {error.preferred_language}
            </p>
          )}
        </div>
        <div className="my-4">
          <FormInput
            name="email"
            placeholder="Email"
            value={emergencyContact.email}
            required
            type="email"
            onChange={(e) => handleEmergencyContactChange(e, "email")}
          />
          {error.email && (
            <p className="text-danger fs-14 fw-500">{error.email}</p>
          )}
        </div>
        <div className="row g-0 gx-2">
          <div className="col-md">
            <div className="custom-form-select">
              <div
                className="form-select-btn"
                onClick={() => {
                  setDropdown2(!dropdown2);
                }}
              >
                <span className="fw-500 me-2" style={{ whiteSpace: "nowrap" }}>
                  {emergencyContact.country_code
                    ? emergencyContact.country_code
                    : "Country Code"}
                </span>
                <Chevron />
              </div>
              <div className={`form-select-content ${dropdown2 ? "show" : ""}`}>
                {options.map((code, index) => {
                  return (
                    <div
                      className="form-select-item"
                      key={index}
                      onClick={() => {
                        setEmergencyContact({
                          ...emergencyContact,
                          country_code: code.isoCode,
                        });
                        setDropdown2(false);
                      }}
                    >
                      <label>{code.isoCode}</label>
                    </div>
                  );
                })}
              </div>
              {error.country_code && (
                <p className="text-danger fs-14 fw-500">{error.country_code}</p>
              )}
            </div>
          </div>
          <div className="col-md">
            <FormInput
              name="phone"
              placeholder="Phone number"
              value={emergencyContact.phone}
              required
              type="tel"
              onChange={(e) => handleEmergencyContactChange(e, "phone")}
            />
            {error.phone && (
              <p className="text-danger fs-14 fw-500">{error.phone}</p>
            )}
          </div>
        </div>
      </div>
      <div className="modal-footer d-flex align-items-center px-4">
        <button
          type="button"
          className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Save
        </button>
      </div>
    </UncutModal>
  );
};
