// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control {
  border-radius: 0.625rem;
  border: 2px solid #d5d6d9;
  background: #fff;
  font-size: 1rem;
  resize: none;
  height: 13.6875rem;
}

.form-control:focus {
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/CreateListingsModal/SpaceTitle/SpaceTitle.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".form-control {\n  border-radius: 0.625rem;\n  border: 2px solid #d5d6d9;\n  background: #fff;\n  font-size: 1rem;\n  resize: none;\n  height: 13.6875rem;\n}\n\n.form-control:focus {\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
