import React from "react";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron-sm.svg";
import "./Timeline.css";

export const Timeline = () => {
  return (
    <div className="d-flex align-items-center px-3 my-3 position-relative bottom-0">
      <hr className="flex-grow-1" />
      <button className="timeline-btn">
        <small className="text-muted">Today</small>
        <Chevron />
      </button>
      <hr className="flex-grow-1" />
    </div>
  );
};
