import React, { useState } from "react";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";
import { ReactComponent as ClosePrimary } from "../../../../assets/icons/close-primary.svg";
import { UncutModal } from "../../../ui/modal/modal";
import "./CancellationPolicy.css";

export const CancellationPolicy = ({ id, listingsId }) => {
  const [dropdown1, setDropdown1] = useState(false);

  const [dropdown2, setDropdown2] = useState(false);

  const [cancellationPolicy, setCancellationPolicy] = useState("");

  const [cancellationPolicy2, setCancellationPolicy2] = useState("");

  const cancellationPolicies = [
    "Flexible",
    "Moderate",
    "Strict",
    "Flexible or Non-refundable",
    "Moderate or Non-refundable",
    "Firm",
    "Strict or Non-refundable",
  ];

  const cancellationPolicies2 = ["Strict", "Flexible"];

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Edit Listing</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <div className="d-flex gap-4 align-items-center">
          <Chevron
            style={{ transform: "rotate(90deg)", cursor: "pointer" }}
            onClick={() => {
              document.getElementById(`close-${id}`).click();
            }}
            data-bs-toggle="modal"
            data-bs-target={`#${listingsId}`}
          />
          <h5 className="mb-0 fw-600">Cancellation Policy</h5>
        </div>
        <p className="mb-0 mt-2">
          Which cancellation options do you want to offer guests?
        </p>
        <div className="my-4">
          <h6 className="mb-0 fw-500">Standard cancellation policy</h6>
          <p className="mb-3">
            Choose the policy that will apply to stays under 28 days.
          </p>
          <div className="custom-form-select">
            <div
              className="form-select-btn"
              onClick={() => {
                setDropdown1(true);
                window.addEventListener("mousedown", (e) => {
                  if (!e.target.closest(".form-select-btn")) {
                    setDropdown1(false);
                  }
                });
              }}
            >
              <span className="fw-500">
                {cancellationPolicy ? cancellationPolicy : "Select one"}
              </span>
              <Chevron />
            </div>
            <div className={`form-select-content ${dropdown1 ? "show" : ""}`}>
              {cancellationPolicies.map((cancellationPolicy, index) => {
                return (
                  <div className="form-select-item" key={index}>
                    <input
                      type="radio"
                      name="property-type"
                      id={cancellationPolicy}
                      className="d-none"
                      value={cancellationPolicy}
                      onChange={(e) => {
                        setCancellationPolicy(e.target.value);
                      }}
                    />
                    <label htmlFor={cancellationPolicy}>
                      {cancellationPolicy}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="my-4">
          <h6 className="mb-0 fw-500">Long-term stay cancellation policy</h6>
          <p className="mb-3">
            Choose the policy that will apply to stays 28 nights or longer.
          </p>
          <div className="custom-form-select">
            <div
              className="form-select-btn"
              onClick={() => {
                setDropdown2(true);
                window.addEventListener("mousedown", (e) => {
                  if (!e.target.closest(".form-select-btn")) {
                    setDropdown2(false);
                  }
                });
              }}
            >
              <span className="fw-500">
                {cancellationPolicy2 ? cancellationPolicy2 : "Select one"}
              </span>
              <Chevron />
            </div>
            <div className={`form-select-content ${dropdown2 ? "show" : ""}`}>
              {cancellationPolicies2.map((cancellationPolicy2, index) => {
                return (
                  <div className="form-select-item" key={index}>
                    <input
                      type="radio"
                      name="property-type"
                      id={cancellationPolicy2}
                      className="d-none"
                      value={cancellationPolicy2}
                      onChange={(e) => {
                        setCancellationPolicy2(e.target.value);
                      }}
                    />
                    <label htmlFor={cancellationPolicy2}>
                      {cancellationPolicy2}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer d-flex align-items-center gap-4 px-5">
        <button
          type="button"
          className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Back
        </button>
        <button
          type="button"
          className="btn btn-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Save
        </button>
      </div>
    </UncutModal>
  );
};
