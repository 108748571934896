import React, { useState } from "react";
import { useSpinnerContext } from "../../../context/SpinnerContext";
import { toastService } from "../../../lib/toast.service";
import { resetPassword } from "../../../services/auth.service";
import {
  confirmPasswordValidation,
  passwordValidation,
} from "../../../utils/validation";
import { FormInput } from "../../ui/form-input/FormInput";
import "./ResetPasswordForm.css";

export const ResetPasswordForm = () => {
  const [form, setForm] = useState({
    password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({
    password: "",
    confirm_password: "",
  });

  const { setSpinner } = useSpinnerContext();

  const handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    handleErrors(e);
  };

  const handleErrors = (e) => {
    if (e.target.name === "password") {
      setErrors((prev) => ({
        ...prev,
        password: passwordValidation(e.target.value),
      }));
    }

    if (e.target.name === "confirm_password") {
      setErrors((prev) => ({
        ...prev,
        confirm_password: confirmPasswordValidation(e.target.value),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    const resp = await resetPassword(form);
    setSpinner(false);
    if (resp.status === 200 || resp.status === 201) {
      toastService.success(resp.message);
      setForm({
        password: "",
        confirm_password: "",
      });
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="mb-4">
        <FormInput
          name="password"
          type="password"
          placeholder="New Password"
          value={form.password}
          required={true}
          onChange={handleChange}
        />
        {errors.password && (
          <div className="text-danger fw-500 ms-3">
            <small className="ps-1">{errors.password}</small>
          </div>
        )}
      </div>
      <div className="mb-4">
        <FormInput
          name="confirm_password"
          type="password"
          placeholder="Confirm Password"
          value={form.confirm_password}
          required={true}
          onChange={handleChange}
        />
        {errors.confirm_password && (
          <div className="text-danger fw-500 ms-3">
            <small className="ps-1">{errors.confirm_password}</small>
          </div>
        )}
      </div>
      <div className="d-grid">
        <button
          className="btn btn-primary rounded fw-600 fs-20"
          type="submit"
          disabled={
            errors.password ||
            errors.confirm_password ||
            !form.password ||
            !form.confirm_password
          }
        >
          Reset Password
        </button>
      </div>
    </form>
  );
};
