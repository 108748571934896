import React, { useState } from "react";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";
import { ReactComponent as ClosePrimary } from "../../../../assets/icons/close-primary.svg";
import { ReactComponent as GeneralLocation } from "../../../../assets/icons/general-location.svg";
import { ReactComponent as SpecificLocation } from "../../../../assets/icons/specific-location.svg";
import { UncutModal } from "../../../ui/modal/modal";

export const Location = ({ id, listingsId }) => {
  const [location, setLocation] = useState("general");

  const [locationDetails, setLocationDetails] = useState({
    on_a_street: false,
    packing_on_premise: false,
    near_lake: false,
  });

  const list = [
    { name: "on_a_street", label: "On a Street" },
    { name: "packing_on_premise", label: "Packing on premise" },
    { name: "near_lake", label: "Near lake" },
  ];

  return (
    <UncutModal id={id} scrollable={true} right={true}>
      <div className="modal-header">
        <h5 className="modal-title fs-22 fw-600">Edit Listing</h5>
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close-${id}`}
        >
          <ClosePrimary />
        </button>
      </div>
      <div className="modal-body px-4">
        <div className="d-flex gap-4 align-items-center">
          <Chevron
            style={{ transform: "rotate(90deg)", cursor: "pointer" }}
            onClick={() => {
              document.getElementById(`close-${id}`).click();
            }}
            data-bs-toggle="modal"
            data-bs-target={`#${listingsId}`}
          />
          <h5 className="mb-0 fw-600">Location</h5>
        </div>
        <p className="mb-0 mt-2 mb-4">
          Choose how the location is displayed to guests before they book.
        </p>
        <div className="form">
          <div className="my-4 border rounded-2 d-flex justify-content-between p-3 gap-2">
            <input
              type="checkbox"
              id="general"
              name="general"
              className="d-none"
              onChange={() => setLocation("general")}
              checked={location === "general"}
            />
            <label htmlFor="general" className="form-checkbox-rounded"></label>
            <GeneralLocation className="ms-3" />
            <label
              htmlFor="general"
              className="form-label fw-500 mb-0 pointer w-75"
            >
              <h5 className="mb-0 fw-500 fs-18">General Location (default)</h5>
              <small className="text-muted">
                Show only the general area to anyone searching for a place to
                stay.
              </small>
            </label>
          </div>
          <div className="my-4 border rounded-2 d-flex justify-content-between p-3 gap-2">
            <input
              type="checkbox"
              id="specific"
              name="specific"
              className="d-none"
              onChange={() => setLocation("specific")}
              checked={location === "specific"}
            />
            <label htmlFor="specific" className="form-checkbox-rounded"></label>
            <SpecificLocation className="ms-3" />
            <div
              htmlFor="specific"
              className="form-label fw-500 mb-0 pointer w-75"
            >
              <h5 className="mb-0 fw-500 fs-18">Specific Location</h5>
              <small className="text-muted">
                Show a more specific location to guests searching for a place to
                stay.
              </small>
            </div>
          </div>
        </div>
        <div className="form">
          <h5 className="mb-0 fw-500">Location Details</h5>
          {list.map((item, index) => (
            <div
              className="my-4 border rounded-2 d-flex justify-content-between p-3 gap-2"
              key={index}
            >
              <label
                htmlFor={item.label}
                className="form-label fw-500 mb-0 pointer w-75"
              >
                {item.label}
              </label>
              <input
                type="checkbox"
                id={item.label}
                name={item.name}
                checked={locationDetails[item.name]}
                value={locationDetails[item.name]}
                className="d-none"
                onChange={() =>
                  setLocationDetails({
                    ...locationDetails,
                    [item.name]: !locationDetails[item.name],
                  })
                }
              />
              <label htmlFor={item.label} className="form-checkbox"></label>
            </div>
          ))}
        </div>
      </div>
      <div className="modal-footer d-flex align-items-center px-4">
        <button
          type="button"
          className="btn btn-outline-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Back
        </button>
        <button
          type="button"
          className="btn btn-primary fw-600 flex-grow-1 fs-20"
          data-bs-dismiss="modal"
        >
          Save
        </button>
      </div>
    </UncutModal>
  );
};
