import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as AmericanExpress } from "../../../../../assets/icons/american-express.svg";
import { ReactComponent as Ellipsis } from "../../../../../assets/icons/ellipsis.svg";
import { ReactComponent as MasterCard } from "../../../../../assets/icons/mastercard.svg";
import { ReactComponent as Visa } from "../../../../../assets/icons/visa.svg";
import { AddPaymentMethodModal } from "../../../../../components/modals/AddPaymentMethodModal/AddPaymentMethodModal";
import { useSpinnerContext } from "../../../../../context/SpinnerContext";
import { toastService } from "../../../../../lib/toast.service";
import {
  deletePaymentMethod,
  getPaymentMethods,
} from "../../../../../services/settings.service";

export const Payments = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);

  const { setSpinner } = useSpinnerContext();

  const fetchPaymentMethods = useCallback(async () => {
    try {
      setSpinner(true);
      const res = await getPaymentMethods();
      setPaymentMethods(res.result);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.log(error);
    }
  }, [setSpinner]);

  const removePaymentMethod = useCallback(
    async (id) => {
      try {
        setSpinner(true);
        const res = await deletePaymentMethod(id);
        setSpinner(false);
        if (res.status === 200 || res.status === 201) {
          setPaymentMethods((prev) => prev.filter((item) => item.id !== id));
          toastService.success(res.message);
          return;
        }
      } catch (error) {
        setSpinner(false);
        console.log(error);
      }
    },
    [setSpinner]
  );

  useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  return (
    <>
      <div className="p-4">
        <h5 className="fw-bold text-dark fs-20 mb-0 fw-600 mt-3">
          Payment Methods
        </h5>
        <p className="text-muted fs-14 mb-0 fw-500">
          Add and manage your payment methods
        </p>
        {paymentMethods.map((paymentMethod) => {
          return (
            <div
              className="d-flex align-items-center justify-content-between border rounded-4 p-3 mt-4"
              key={paymentMethod.id}
            >
              <div className="d-flex gap-4">
                {paymentMethod.brand === "Visa" ? (
                  <Visa />
                ) : paymentMethod.brand === "Mastercard" ? (
                  <MasterCard />
                ) : paymentMethod.brand === "American Express" ? (
                  <AmericanExpress />
                ) : null}
                <div>
                  <h4 className="fw-bold text-dark fs-16 mb-0 fw-500">
                    {paymentMethod.brand} ****{paymentMethod.card_number}
                  </h4>
                  <p className="text-muted fs-14 mb-0 fw-500">
                    Expiration: {paymentMethod.exp_month}/
                    {paymentMethod.exp_year}
                  </p>
                </div>
              </div>
              <div
                className="btn btn-link p-0 position-relative"
                onClick={(e) => {
                  let dropdownMenu =
                    e.target.parentElement.querySelector(".res-dropdown-menu");
                  dropdownMenu && (dropdownMenu.style.display = "block");

                  window.addEventListener("mousedown", (e) => {
                    if (!e.target.closest(".res-dropdown-menu")) {
                      dropdownMenu && (dropdownMenu.style.display = "none");
                    }
                  });
                }}
              >
                <Ellipsis width={25} height={25} />
                <div className="res-dropdown-menu listing p-0">
                  <button className="custom-dropdown-item">
                    <div className="d-flex align-items-center gap-3">
                      <span>Set Default</span>
                    </div>
                  </button>
                  <button
                    className="custom-dropdown-item"
                    onClick={async () => {
                      if (
                        !window.confirm(
                          "Are you sure you want to remove this payment method?"
                        )
                      )
                        return;
                      await removePaymentMethod(paymentMethod.id);
                    }}
                  >
                    <div className="d-flex align-items-center gap-3">
                      <span>Remove</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          );
        })}

        <button
          className="btn btn-primary mt-4 fw-600 py-2"
          data-bs-toggle="modal"
          data-bs-target="#add-payment-method-modal"
        >
          Add Payment Method
        </button>

        <a href="#!" className="text-primary fw-600 d-block mt-4">
          Manage Payments
        </a>
        <a href="#!" className="text-primary fw-600 d-block mt-4">
          Add gift card
        </a>
        <a href="#!" className="text-primary fw-600 d-block mt-4">
          Add coupons
        </a>
      </div>
      <AddPaymentMethodModal id="add-payment-method-modal" />
    </>
  );
};
